/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-16 10:03:11
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2022-01-05 15:08:35
 * @Description: 数据报量大屏-首屏
 */

<style lang='less' scoped>
.container {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    box-sizing: content-box;
    font-weight: 300;
    /deep/.dv-scroll-board {
        .rows {
            .row-item {
                font-size: 12px;
            }
        }
    }
    /deep/ .swing-container {
        .top-center {
            padding: 0 110px;
        }
    }
    .left-content {
        display: flex;
        height: 100%;
        padding-bottom: 24px;
        box-sizing: border-box;
        /deep/ .ceil {
            &:last-child {
                padding-right: 0;
            }
            &:first-child {
                padding-left: 0;
            }
        }
        .seller {
            margin-right: 0 !important;
            .list-header {
                .cell:nth-child(2) {
                    position: relative;
                    right: -2em;
                }
            }
        }
        .buyer {
            .list {
                /deep/ .header {
                    .header-item {
                        &:nth-child(2) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        .buyer,
        .seller {
            margin-right: 16px;
            /deep/ .subTitle {
                color: #fff;
            }
            .list {
                height: 312px;
                /deep/ .ceil {
                    &:last-child {
                        text-align: right;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                }
                /deep/ .header-item {
                    font-size: 12px;
                    color: #108ee9;
                    &:first-child {
                        padding-left: 26px;
                    }
                    &:nth-child(2) {
                        padding-right: 26px;
                    }
                    &:last-child {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
            }
            .bd {
                height: 312px;
            }
        }
    }
    .middle-content {
        padding: 25px 40px 0 40px;
        height: 100%;
        position: relative;
        .total-wrap {
            text-align: center;
            .title {
                margin-bottom: 15px;
                padding: 10px 0;
                background: url('../../../images/report-amount/wd-titbg.png');
                background-repeat: no-repeat;
                background-position: bottom;
                background-size: 260px auto;
                font-size: 20px;
                color: #fff;
                font-weight: bold;
            }
            .total {
                margin-bottom: 50px;
                display: flex;
                justify-content: center;
                .item {
                    width: 74px;
                    height: 78px;
                    background: url('../../../images/report-amount/num_big.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 74px 78px;
                    color: #f4a607;
                    line-height: 78px;
                    font-size: 42px;
                    font-weight: 600;
                }
                .comma {
                    font-size: 42px;
                    color: #f4a607;
                    line-height: 78px;
                }
            }
            .sub-total {
                display: flex;
                align-items: center;
                justify-content: center;
                .item {
                    width: 330px;
                    height: 100px;
                    box-sizing: border-box;
                    padding: 24px 0 24px 75px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    text-align: center;
                    color: #fff;
                    .value {
                        font-size: 28px;
                        font-weight: 600;
                        margin-bottom: 2px;
                    }
                    .label {
                        font-size: 14px;
                    }
                    + .item {
                        margin-left: 70px;
                    }
                    &.bg1 {
                        background-image: url('../../../images/report-amount/wd-bg1.png');
                    }
                    &.bg2 {
                        background-image: url('../../../images/report-amount/wd-bg2.png');
                    }
                    &.bg3 {
                        background-image: url('../../../images/report-amount/wd-bg3.png');
                    }
                }
            }
        }

        .deal-wrap {
            margin-top: 20px;
            .list {
                height: 280px;
                // /deep/ .header {
                //     margin-bottom: 10px;
                // }
                /deep/ .header-item {
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.85);
                    line-height: 40px;
                }
                /deep/ .dotIndex {
                    vertical-align: middle;
                }
                /deep/ .row-item {
                    border-bottom: 1px solid rgba(16, 142, 233, 0.25);
                }
            }
        }
    }
    .right-content {
        display: flex;
        height: 100%;
        padding-bottom: 24px;
        box-sizing: border-box;
        .order-amount {
            .list {
                height: 310px;
                /deep/ .dotIndex {
                    vertical-align: middle;
                }
                /deep/ .row-item {
                    border-bottom: 1px solid rgba(16, 142, 233, 0.25);
                    box-sizing: border-box;
                    &:last-child {
                        border-bottom-width: 0;
                    }
                }
            }
            .head-fix {
                margin-bottom: 0;
                padding-bottom: 0;
                // border-bottom: none;
                .list {
                    height: 340px;
                    /deep/ .header {
                        // margin-bottom: 10px;
                    }
                    /deep/ .header-item {
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.85);
                        line-height: 40px;
                    }
                }
            }
        }
    }
}
.list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 26px;
    .cell {
        font-size: 12px;
        color: #108ee9;
        letter-spacing: 0;
        text-align: right;
    }
}
.head-fix {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid rgba(16, 142, 233, 0.25);
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        .dotIndex {
            background-image: url("~images/report-amount/num_top.png");
            background-repeat: no-repeat;
            background-position: center;
            color: #fff;
            display: inline-block;
        }
        .dot-content {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
            .list-cell {
                font-size: 12px;
                color: #fff;
                letter-spacing: 0;
                line-height: 24px;
            }
        }
    }
}
</style>

<template>
    <div class="container">
        <swing-layout :container-style="{ backgroundColor: '#03071E', backgroundImage: `url(${bg})`,backgroundRepeat: 'round' }"
                      :flex-left="5.5"
                      :flex-right="5.5"
                      :flex-center="9"
                      :title="title">
            <div slot="left"
                 class="left-content">
                <border title="采购单位 TOP30"
                        class="buyer">
                    <div class="wrapper">
                        <div class="head-fix">
                            <dv-scroll-board :config="buyerDataFixList"
                                             class="list" />
                        </div>
                        <dv-scroll-board :config="buyerDataList"
                                         class="list bd" />
                    </div>
                </border>
                <border title="供应企业 TOP30"
                        class="seller">
                    <div class="wrapper">
                        <div class="head-fix">
                            <dv-scroll-board :config="sellerDataFixList"
                                             class="list" />
                        </div>
                        <dv-scroll-board :config="sellerDataList"
                                         class="list bd" />
                    </div>
                </border>
            </div>
            <div slot="middle"
                 class="middle-content">
                <div class="total-wrap">
                    <div class="title">
                        交易额(万元)
                    </div>
                    <div class="total">
                        <template v-for="(num, index) in totalMain">
                            <div v-if="num !== ','"
                                 :key="index"
                                 class="item">
                                {{ num || '0' }}
                            </div>
                            <div v-else
                                 :key="index"
                                 class="comma">
                                ,
                            </div>
                        </template>
                    </div>
                    <div class="sub-total">
                        <div class="item bg1">
                            <div class="value">
                                {{ parseInt(total.num2, 10) || 0 }}
                            </div>
                            <div class="label">
                                总订单数(笔)
                            </div>
                        </div>
                        <div class="item bg2">
                            <div class="value">
                                {{ parseInt(total.num3, 10) || 0 }}
                            </div>
                            <div class="label">
                                采购单位数(家)
                            </div>
                        </div>
                        <div class="item bg3">
                            <div class="value">
                                {{ parseInt(total.num4, 10) || 0 }}
                            </div>
                            <div class="label">
                                供应企业数(家)
                            </div>
                        </div>
                    </div>
                </div>

                <border title="交易播报"
                        class="deal-wrap">
                    <dv-scroll-board :config="dealData"
                                     class="list" />
                </border>
            </div>
            <div slot="right"
                 class="right-content">
                <border title="交易额排名"
                        class="order-amount">
                    <div class="wrapper">
                        <div class="head-fix">
                            <dv-scroll-board :config="orderAmountFixData"
                                             class="list" />
                        </div>
                        <dv-scroll-board :config="orderAmountData"
                                         class="list" />
                    </div>
                </border>
            </div>
        </swing-layout>
    </div>
</template>
<script>

import SwingLayout from '@/components/layout/swingLayout';
import border from '@/components/border';
import numTop from '@/images/report-amount/num_top.png';
import numDef from '@/images/report-amount/num_def.png';
import bg from '@/images/report-amount/trade-bg.jpg';

import { toThousands } from '@/utils/utils';

export default {
    components: {
        SwingLayout,
        border
    },
    filters: {
        toThousands(val) {
            if (!val) return '--';
            return toThousands(val);
        },
        hospNameFormmater(name) {
            if (name.length > 16) {
                name = `${name.slice(0, 15)}...`;
            }
            return name;
        },
        dataConvert(convert) {
            if (typeof convert === 'string') {
                convert = parseFloat(convert);
            }
            if (convert >= 10000) {
                convert /= 10000;
                convert = `${convert.toFixed(2)}`;
            } else {
                convert *= 0.0001;
            }
            if (convert) {
                return convert;
            }
            return '--';
        },
        timeConvert(val) {
            if (!val) {
                return '--';
            }
            const current = new Date(val);
            return `${current.getFullYear()}.${current.getMonth() + 1}.${current.getDate()}`;
        }
    },
    data() {
        return {
            title: '',
            bg,

            total: {},
            deal: [],

            buyerTOP30Data: [],
            sellerTOP30Data: [],
            orderAmount: []
        };
    },
    computed: {
        totalMain() {
            const len = 8 - (`${parseInt(this.total.num1, 10)}`).length;
            const num = toThousands(parseInt(this.total.num1, 10));
            if (len > 0) {
                console.log(len);
                return new Array(len).join('0') + num;
            }
            return num;
            
            // let val = typeof this.total.num1 === 'string' ? this.total.num1 : '000000000';
            // val = val.padStart(9, '0');
            // return val.split('');
        },
        dealData() {
            const data = [];
            this.deal.forEach((item, index) => {
                const temp = [];
                temp.push(this.numDefDom(index + 1));
                temp.push(item.num1);
                temp.push(item.num2);
                temp.push(item.num3);
                temp.push(toThousands(item.num4));
                temp.push(toThousands(item.num5));

                data.push(temp);
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 6,
                columnWidth: ['80', '380', '510', '450', '220', '220'],
                header: ['序号', '交易品种', '采购单位', '供应企业', '交易数量', '交易额(元)'],
                align: ['center', 'left', 'left', 'left', 'center', 'right'],
                headerBGC: 'rgba(16,142,233,0.15)',
                data
            };
        },
        sellerDataFixList() {
            const data = [];
            if (!Array.isArray(this.sellerTOP30Data)) {
                return {};
            }
            this.sellerTOP30Data.slice(0, 10).forEach((item, index) => {
                const temp = [];
                let name = item.num1;
                if (item.num1.length > 18) {
                    name = `${name.slice(0, 17)}...`;
                }
                temp.push(this.numTopDom(index + 1, name));
                temp.push(toThousands(item.num2));
                data.push(temp);
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 10,
                waitTime: 2000,
                columnWidth: [380, 135],
                header: ['企业名称', '供应额(万元)'],
                align: ['left', 'right'],
                headerBGC: 'rgba(0,0,0,0)',
                data
            };
        },
        sellerDataList() {
            const data = [];
            if (!Array.isArray(this.sellerTOP30Data)) {
                return {};
            }
            this.sellerTOP30Data.slice(10).forEach((item, index) => {
                const temp = [];
                let name = item.num1;
                if (item.num1.length > 18) {
                    name = `${name.slice(0, 17)}...`;
                }
                temp.push(this.numDefDom(index + 11, name));
                temp.push(toThousands(item.num2));
                data.push(temp);
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 10,
                align: ['left', 'right'],
                columnWidth: [380, 135],
                waitTime: 2000,
                data
            };
        },
        buyerDataFixList() {
            const data = [];
            this.buyerTOP30Data.slice(0, 10).forEach((item, index) => {
                const temp = [];
                let name = item.num1;
                if (item.num1.length > 18) {
                    name = `${name.slice(0, 17)}...`;
                }
                temp.push(this.numTopDom(index + 1, name));
                temp.push(toThousands(item.num2));
                data.push(temp);
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 10,
                align: ['left', 'right'],
                waitTime: 2000,
                data,
                columnWidth: [380, 135],
                header: ['机构名称', '采购额(万元)'],
                headerBGC: 'rgba(0,0,0,0)',
                headerHeight: 40
            };
        },
        buyerDataList() {
            const data = [];
            this.buyerTOP30Data.slice(10).forEach((item, index) => {
                const temp = [];
                let name = item.num1;
                if (item.num1.length > 18) {
                    name = `${name.slice(0, 17)}...`;
                }
                temp.push(this.numDefDom(index + 11, name));
                temp.push(toThousands(item.num2));
                data.push(temp);
            });

            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 10,
                columnWidth: [380, 135],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        orderAmountFixData() {
            const data = [];
            this.orderAmount.slice(0, 10).forEach((item, index) => {
                const temp = [];
                temp.push(this.numTopDom(index + 1));
                temp.push(item.num1);
                temp.push(item.num2);
                temp.push(toThousands(item.num3));
                data.push(temp);
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 10,
                data,
                align: ['center', 'left', 'left', 'right'],
                columnWidth: ['80', '480', '360', '200'],
                header: ['序号', '采购单位', '供应企业', '交易额(万元)'],
                headerBGC: 'rgba(16,142,233,0.15)',
                headerHeight: 40
            };
        },
        orderAmountData() {
            const data = [];
            this.orderAmount.slice(10).forEach((item, index) => {
                const temp = [];
                temp.push(this.numDefDom(index + 11));
                temp.push(item.num1);
                temp.push(item.num2);
                temp.push(toThousands(item.num3));
                data.push(temp);
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 10,
                columnWidth: ['80', '480', '360', '200'],
                align: ['center', 'left', 'left', 'right'],
                data
            };
        }
    },
    created() {
        this.setRem();
        window.onresize = this.setRem;
        // 这个大屏是32: 9的屏幕。所以宽度用两倍
        document.documentElement.style.width = '100%';
    },
    mounted() {
        this.fetchTitle();

        this.fetchDeal();
        this.fetchTotal();

        this.fetchSellerTOP30();
        this.fetchBuyerTOP30();
        this.fetchOrderAmount();

        // this.polling();
    },
    beforeDestroy() {
        clearTimeout(this.t);
    },
    methods: {
        polling() {
            this.t = setTimeout(() => {
                this.fetchTotal();
                this.fetchDeal();
                this.polling();
            }, 1000 * 10);
        },
        async fetchTitle() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/getTopTradeName'
            });
            if (response.code === 0) {
                this.title = response.msg;
            }
        },
        async fetchDeal() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenX'
            });
            if (response.code === 0) {
                this.deal = response.data;
            }
        },
        async fetchSellerTOP30() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenXII'
            });
            if (response.code === 0) {
                this.sellerTOP30Data = response.data;
            }
        },
        async fetchBuyerTOP30() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenXI'
            });
            if (response.code === 0) {
                this.buyerTOP30Data = response.data;
            }
        },
        async fetchOrderAmount() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenXIII'
            });
            if (response.code === 0) {
                this.orderAmount = response.data;
            }
        },

        async fetchTotal() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenIX'
            });
            if (response.code === 0) {
                this.total = response.data;
            }
        },

        numTopDom(index, content = '') {
            return (
                `<span class="dotIndex" style="background-image:url(${numTop});">${index}</span><span class="dot-content">${content}</span>`
            );
        },
        numDefDom(index, content = '') {
            return (
                `<span class="dotIndex" style="background-image:url(${numDef});">${index}</span><span class="dot-content">${content}</span>`
            );
        },
        setRem() {
            const { clientHeight } = document.documentElement;
            document.documentElement.style.fontSize = `${(16 * clientHeight / 756)}px`;
        },
        toThousands(val) {
            return toThousands(val);
        }
    }
};
</script>
