var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "swing-layout",
        {
          attrs: {
            "container-style": {
              backgroundColor: "#03071E",
              backgroundImage: "url(" + _vm.bg + ")",
              fontSize: "28px"
            },
            title: _vm.$route.meta.title || "厦门海西医药交易中心药械交易平台"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "left-content",
              attrs: { slot: "left" },
              slot: "left"
            },
            [
              _c(
                "border",
                { staticClass: "plat-scale", attrs: { title: "平台覆盖规模" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                22省的105市\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                覆盖地区\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                45476.9万\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                覆盖人口\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                12681家\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                生产企业\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                5293家\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                配送企业\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                11302家\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                医疗机构\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "bussiness-module",
                  attrs: { title: "业务模块" }
                },
                [
                  _c(
                    "el-row",
                    _vm._l(_vm.bussinessModule, function(item, index) {
                      return _c(
                        "el-col",
                        { key: index, attrs: { span: 12 } },
                        [
                          _c(
                            "data-cell",
                            { staticClass: "module", attrs: { type: "img" } },
                            [
                              _c("img", {
                                staticClass: "module-img",
                                attrs: { slot: "val", src: item.img },
                                slot: "val"
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "plat-scale-label",
                                  attrs: { slot: "label" },
                                  slot: "label"
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.label) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "bussiness-result",
                  attrs: { title: "业务成效" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "result-detail" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n                        平台助力各地医改，成效显著\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "content" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("data-cell", { attrs: { reverse: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    20% ~ 40%\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [
                                    _vm._v(
                                      "\n                                    平台节约采购成本\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("data-cell", { attrs: { reverse: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _c("span", { staticClass: "unit" }, [
                                      _vm._v("超")
                                    ]),
                                    _vm._v(" 270 "),
                                    _c("span", { staticClass: "unit" }, [
                                      _vm._v("亿")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [
                                    _vm._v(
                                      "\n                                    累计节约采购成本\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "border-dot left" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "border-dot right" })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "swiper",
                        {
                          staticClass: "result-list",
                          attrs: { options: _vm.resultSwiper }
                        },
                        _vm._l(_vm.serviceResult, function(item, index) {
                          return _c(
                            "swiper-slide",
                            { key: index, staticClass: "list-item" },
                            [
                              _c("div", [
                                _c("div", { staticClass: "item-title" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(item.title) +
                                      "\n                                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "item-content",
                                  domProps: { innerHTML: _vm._s(item.content) }
                                })
                              ])
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "middle-content",
              class: [_vm.guest ? "guest" : ""],
              attrs: { slot: "middle" },
              slot: "middle"
            },
            [
              _c(
                "el-row",
                { staticClass: "about", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("data-cell", { staticClass: "sevenDataAmount" }, [
                        _c(
                          "div",
                          {
                            staticClass: "about-val",
                            attrs: { slot: "val" },
                            slot: "val"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.stat.orderAmtWeek) +
                                "\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "seven-label",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                            近7日订单金额 (亿元)\n                        "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "total-amount-list" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "about-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.stat.orderAmt) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "seven-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    累计订单额 (亿元)\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "about-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.stat.setlAmt) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "seven-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    累计结算额 (亿元)\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "about-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.stat.orderCnt) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "seven-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    累计订单数 (笔)\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.guest && _vm.statArea.length !== 0
                ? _c(
                    "div",
                    { staticClass: "detail" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n                    累计订单额\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "swiper",
                        {
                          staticClass: "total-bar",
                          attrs: { options: _vm.resultSwiper }
                        },
                        _vm._l(_vm.mapAmtFormatter, function(statItem, index) {
                          return _c(
                            "swiper-slide",
                            { key: "stat-" + index },
                            [
                              _c(
                                "el-row",
                                _vm._l(statItem, function(item, i) {
                                  return _c(
                                    "el-col",
                                    {
                                      key: "statItem-" + i,
                                      attrs: { span: 24 / statItem.length }
                                    },
                                    [
                                      _c("div", { staticClass: "data-cell" }, [
                                        _c("span", { staticClass: "label" }, [
                                          _c("span", { staticClass: "dot" }),
                                          _vm._v(
                                            _vm._s(item.areaName) +
                                              "：\n                                    "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "value" }, [
                                          _vm._v(_vm._s(item.orderAmt) + "亿元")
                                        ])
                                      ])
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "right-content",
              attrs: { slot: "right" },
              slot: "right"
            },
            [
              _c(
                "border",
                { staticClass: "hx-hightLights", attrs: { title: "平台亮点" } },
                [
                  _c("ul", { staticClass: "hightLights-list" }, [
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        “三医联动”开路先锋\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        国家药械采购平台承建者\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        国家药品集采力行者\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        跨区域联采专业服务商\n                    "
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "data-analysis",
                  attrs: { title: "数据智能分析" }
                },
                [
                  _c(
                    "el-row",
                    _vm._l(_vm.dataAnalysis, function(item, index) {
                      return _c(
                        "el-col",
                        { key: index, attrs: { span: 6 } },
                        [
                          _c("data-cell", { attrs: { type: "img" } }, [
                            _c("div", {
                              staticClass: "img",
                              style: {
                                backgroundImage: "url(" + item.image + ")"
                              },
                              attrs: { slot: "val" },
                              slot: "val"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.label) +
                                    "\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "enp-sale",
                  attrs: {
                    title: "生产企业销售金额TOP20",
                    "sub-title": "单位：万元"
                  }
                },
                [_c("dv-scroll-board", { attrs: { config: _vm.prodEnpSale } })],
                1
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "enp-sale",
                  attrs: {
                    title: "医疗机构采购金额TOP20",
                    "sub-title": "单位：万元"
                  }
                },
                [_c("dv-scroll-board", { attrs: { config: _vm.medEnpSale } })],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }