var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "swing-layout",
        {
          attrs: {
            "container-style": {
              backgroundColor: "#03071E",
              backgroundImage: "url(" + _vm.bg + ")"
            },
            title: "厦门海西医药交易中心药械交易平台"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "left-content",
              attrs: { slot: "left" },
              slot: "left"
            },
            [
              _c(
                "border",
                { staticClass: "plat-scale", attrs: { title: "平台覆盖规模" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                22省的105市\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                覆盖地区\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                45476.9万\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                覆盖人口\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                12681家\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                生产企业\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                5293家\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                配送企业\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("data-cell", [
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                11302家\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                医疗机构\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "bussiness-module",
                  attrs: { title: "业务模块", type: "img" }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("data-cell", [
                            _c("img", {
                              attrs: {
                                slot: "val",
                                src: require("@/images/incre-ser.png")
                              },
                              slot: "val"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                尊享增值服务\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("data-cell", [
                            _c("img", {
                              attrs: {
                                slot: "val",
                                src: require("@/images/bank-bus.png")
                              },
                              slot: "val"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                银行业务服务\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("data-cell", [
                            _c("img", {
                              attrs: {
                                slot: "val",
                                src: require("@/images/ca-service.png")
                              },
                              slot: "val"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                CA服务\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("data-cell", [
                            _c("img", {
                              attrs: {
                                slot: "val",
                                src: require("@/images/data-share.png")
                              },
                              slot: "val"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                资料申报共享服务\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("data-cell", [
                            _c("img", {
                              attrs: {
                                slot: "val",
                                src: require("@/images/sup-service.png")
                              },
                              slot: "val"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "plat-scale-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                决策支持服务\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "scroll-board cooperate-bank",
                  attrs: {
                    title: "合作银行开户数TOP10",
                    "sub-title": "企业单位：户"
                  }
                },
                [_c("dv-scroll-board", { attrs: { config: _vm.backOpenAmt } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "middle-content",
              attrs: { slot: "middle" },
              slot: "middle"
            },
            [
              _c(
                "el-row",
                { staticClass: "about", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("data-cell", { staticClass: "sevenDataAmount" }, [
                        _c(
                          "div",
                          {
                            staticClass: "about-val",
                            attrs: { slot: "val" },
                            slot: "val"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.stat.orderAmtWeek) +
                                "\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "seven-label",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                            近7日订单金额 (亿元)\n                        "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "total-amount-list" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "about-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.stat.orderAmt) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "seven-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    累计订单额 (亿元)\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "about-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.stat.setlAmt) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "seven-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    累计结算额 (亿元)\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "about-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.stat.orderCnt) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "seven-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    累计订单数 (笔)\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.statArea.length !== 0
                ? _c(
                    "div",
                    { staticClass: "detail" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n                    累计订单额\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "swiper",
                        {
                          staticClass: "total-bar",
                          attrs: { options: _vm.resultSwiper }
                        },
                        _vm._l(_vm.mapAmtFormatter, function(statItem, index) {
                          return _c(
                            "swiper-slide",
                            { key: "stat-" + index },
                            [
                              _c(
                                "el-row",
                                _vm._l(statItem, function(item, i) {
                                  return _c(
                                    "el-col",
                                    {
                                      key: "statItem-" + i,
                                      attrs: { span: 24 / statItem.length }
                                    },
                                    [
                                      _c("div", { staticClass: "data-cell" }, [
                                        _c("span", { staticClass: "label" }, [
                                          _c("span", { staticClass: "dot" }),
                                          _vm._v(
                                            _vm._s(item.areaName) +
                                              "：\n                                    "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "value" }, [
                                          _vm._v(_vm._s(item.orderAmt) + "亿元")
                                        ])
                                      ])
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "right-content",
              attrs: { slot: "right" },
              slot: "right"
            },
            [
              _c(
                "border",
                { staticClass: "hx-hightLights", attrs: { title: "平台亮点" } },
                [
                  _c("ul", { staticClass: "hightLights-list" }, [
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        “三医联动”开路先锋\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        国家药械采购平台承建者\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        国家药品集采力行者\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        跨区域联采专业服务商\n                    "
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "data-analysis",
                  attrs: { title: "数据智能分析" }
                },
                [
                  _c(
                    "el-row",
                    _vm._l(_vm.dataAnalysis, function(item, index) {
                      return _c(
                        "el-col",
                        { key: index, attrs: { span: 6 } },
                        [
                          _c("data-cell", { attrs: { type: "img" } }, [
                            _c("div", {
                              staticClass: "img",
                              style: {
                                backgroundImage: "url(" + item.image + ")"
                              },
                              attrs: { slot: "val" },
                              slot: "val"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.label) +
                                    "\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "scroll-board prod-enp-sale",
                  attrs: {
                    title: "生产企业销售金额TOP20",
                    "sub-title": "单位：万元"
                  }
                },
                [_c("dv-scroll-board", { attrs: { config: _vm.prodEnpSale } })],
                1
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "scroll-board prod-enp-sale",
                  attrs: {
                    title: "医疗机构采购金额TOP20",
                    "sub-title": "单位：万元"
                  }
                },
                [_c("dv-scroll-board", { attrs: { config: _vm.medEnpSale } })],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }