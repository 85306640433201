/*
* @Author: housy
* @Date:   2019-03-18 11:31:28
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-02-24 17:51:52
*/

import { getUrlParame } from './utils';

const baseSize = 16;
const setRem = () => {
    const flag = getUrlParame('spc');
    const { clientHeight } = document.documentElement;
    const scale = flag === '1' ? 640 / 756 : clientHeight / 756;
    document.documentElement.style.fontSize = `${(baseSize * Math.min(scale, 2))}px`;
};

setRem();

window.onresize = () => {
    setRem();
};
