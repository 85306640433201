var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-touch",
    {
      staticClass: "view-container",
      on: { swipeup: _vm.swipeup, swipedown: _vm.swipedown }
    },
    [
      _c(
        "transition",
        { attrs: { name: _vm.transName } },
        [
          _vm.$route.meta.alive
            ? _c("keep-alive", [_c("router-view")], 1)
            : _c("router-view")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }