/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-25 14:03:24
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-07-05 10:49:41
 * @Description: 服务项目展示
 * @Route: 
 */
<style lang='less' scoped>
.wrapper {
    padding-top: 30px;
    width: 100%;
}
</style>

<template>
    <full-layout title="综合监管模型">
        <div class="wrapper">
            <digital-carousel v-model="activeIndex"
                              :carousel-item="carouselItem"
                              :auto-play="autoPlay"
                              :interval="5000" />
        </div>
    </full-layout>
</template>

<script>
import fullLayout from '@/components/layout/fullLayout';
import digitalCarousel from '@/components/digital-carousel/index';
import sup1 from '@/images/gov-hx-proj/jg01.jpg';
import sup2 from '@/images/gov-hx-proj/jg02.jpg';
import sup3 from '@/images/gov-hx-proj/jg03.jpg';
import sup4 from '@/images/gov-hx-proj/jg04.jpg';
import activeMixins from '@/components/common/activeMixins';

export default {
    components: {
        fullLayout,
        digitalCarousel
    },
    mixins: [activeMixins],
    data() {
        return {
            carouselItem: [
                {
                    img: sup4,
                    isActive: true
                },
                {
                    img: sup1,
                    isActive: false
                },
                {
                    img: sup2,
                    isActive: false
                },
                {
                    img: sup3,
                    isActive: false
                }
            ]
        };
    }
};
</script>
