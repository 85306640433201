/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-15 16:54:30
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-13 15:26:40
 * @Description: 公共边角
 */
 
<style lang='less' scoped>
@media screen and (device-width: 2560px) {
    .borderWrapper {
        border-radius: 0.5rem;
    }
}

@media screen and (device-width: 1366px) {
    .borderWrapper {
        border-radius: 1.25rem;
    }
}

@media screen and (device-width: 1920px) {
    .borderWrapper {
        border-radius: 1rem;
    }
}

.borderWrapper {
    display: inline-block;
    position: relative;
    width: 100%;
    box-shadow: rgb(11, 36, 104) 0 0 25px inset;
    // border-radius: 0.5rem;
    padding: 13px 16px;
    margin-bottom: 17px;
    box-sizing: border-box;
    & > .title {
        font-weight: 700;
        text-align: left;
        color: #108ee9;
        line-height: 19px;
        font-size: 14px;
        margin-bottom: 6px;
        .subTitle {
            font-size: 12px;
            font-weight: 500;
            text-align: left;
            float: right;
            color: #108ee9;
            line-height: 17px;
        }
    }
    .content {
        width: 100%;
        height: auto;
    }
    .left-top-border {
        left: 0;
        top: 0;
        background-image: url("~images/leftTop.svg");
        background-position: left top;
        &.green {
            background-image: url("~images/green-border/green-left-top.svg");
        }
    }

    .left-bottom-border {
        left: 0;
        bottom: 0;
        background-image: url("~images/leftBottom.svg");
        background-position: left bottom;
        &.green {
            background-image: url("~images/green-border/green-left-bottom.svg");
        }
    }

    .right-top-border {
        right: 0;
        top: 0;
        background-image: url("~images/rightTop.svg");
        background-position: right top;
        &.green {
            background-image: url("~images/green-border/green-right-top.svg");
        }
    }

    .right-bottom-border {
        right: 0;
        bottom: 0;
        background-image: url("~images/rightBottom.svg");
        background-position: right bottom;
        &.green {
            background-image: url("~images/green-border/green-right-bottom.svg");
        }
    }

    .bottom-line {
        right: 50%;
        bottom: 0;
        width: 35px;
        background-image: url("~images/bottom-line.svg");
        transform: translateX(50%);
        background-position: center bottom;
    }

    .border-div {
        display: inline-block;
        width: 25px;
        height: 25px;
        position: absolute;
        background-repeat: no-repeat;
    }
}
</style>

<template>
    <div class="borderWrapper">
        <div v-if="title !== ''"
             class="title">
            {{ title }}
            <span class="subTitle">
                {{ subTitle }}
            </span>
        </div>
        <div class="content">
            <slot />
        </div>
        <div class="left-top-border border-div"
             :class="green && 'green'" />
        <div class="left-bottom-border border-div"
             :class="green && 'green'" />
        <div class="right-bottom-border border-div"
             :class="green && 'green'" />
        <div class="right-top-border border-div"
             :class="green && 'green'" />
        <div class="bottom-line border-div" />
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        green: {
            type: Boolean,
            default: false
        }
    }
};
</script>
