/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-17 16:17:08
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-05-13 16:58:01
 * @Description: 国家局项目
 * @Route: /lab/country-proj
 */
<style lang='less' scoped>
.container {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    box-sizing: content-box;
    .left-content {
        .system-build {
            font-size: 12px;
            text-align: left;
            color: @--color-white;
            line-height: 20px;
            .system-list {
                margin-bottom: 8px;
                .list-item {
                    text-align: left;
                    margin-left: 13px;
                    padding-left: 5px;
                    margin-top: 8px;
                    list-style-image: url("~images/dot.svg");
                }
            }
        }
        .system-module {
            /deep/ .title {
                margin-bottom: 17px;
            }
            .item {
                font-size: 12px;
                text-align: center;
                color: @--color-white;
                line-height: 16px;
                padding: 5px 0;
                margin-bottom: 8px;
                &.primary {
                    background: rgba(16, 142, 233, 0.2);
                    border: 1px solid #108ee9;
                }
                &.success {
                    background: rgba(4, 239, 239, 0.2);
                    border: 1px solid #04efef;
                }
                &.half {
                    background: linear-gradient(270deg, rgba(4, 239, 239, 0.2) 50%, rgba(16, 142, 233, 0.2) 50%);
                    border: 1px solid;
                    border-image: linear-gradient(270deg, #0f9696 50%, #108ee9 50%) 1 1;
                }
            }
        }
        .plat-plan {
            padding-bottom: 10px;
            /deep/ .title {
                margin-bottom: 5px;
            }
            .plan-list {
                font-size: 12px;
                text-align: left;
                color: @--color-white;
                line-height: 20px;
                position: relative;
                margin-bottom: 16px;
                height: 101px;
                .item {
                    padding: 25px 0 0 11px;
                    position: relative;
                    .dot {
                        display: inline-block;
                        width: 11px;
                        height: 11px;
                        position: absolute;
                        left: -5px;
                        top: 29px;
                        background-position: center;
                        background-size: 11px;
                        // transform: translateY(-50%);
                        background-image: url("~images/dot-double.png");
                    }
                }
                .line {
                    // display: inline-block;
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 21px;
                    bottom: -8px;
                    width: 1px;
                    background-color: rgba(16, 142, 233, 1);
                }
                .arrow {
                    width: 6px;
                    height: 6px;
                    // display: inline-block;
                    display: none;
                    background-image: url("~images/arrow.png");
                    background-size: 6px;
                    background-position: center;
                    background-repeat: no-repeat;
                    position: absolute;
                    bottom: -10px;
                    left: -2.5px;
                }
            }
        }
    }
    .middle-content {
        padding-top: 13px;
        height: 100%;
        position: relative;
        .system-name {
            text-align: center;
            margin: 0 44px;
            display: inline-block;
            box-sizing: border-box;
            &.borderWrapper {
                width: 85.5%;
            }
            .title {
                font-size: 24px;
                font-weight: 700;
                text-align: center;
                color: @--color-white;
                line-height: 32px;
            }
        }
        .subTitle-list {
            margin: 0 52px 8px 62px;
            .subTitle {
                padding: 9px 0 11px 0;
                font-size: 18px;
                font-weight: 700;
                text-align: center;
                color: @--color-white;
                line-height: 24px;
                background-image: url("~images/cp-title-border.png");
                background-size: 190px 40px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .map-wrapper {
            width: 580px;
            height: 406px;
            margin: auto;
            margin-top: 16px;
        }
        .icon {
            // background: url("~images/icon_logo_chs.png") no-repeat center;
            width: 48px;
            height: 26px;
            background-size: 100%;
            background-position: center;
            position: absolute;
            top: 38%;
            left: 70%;
            z-index: 99;
        }
    }
    .right-content {
        /deep/.borderWrapper {
            margin-bottom: 0;
        }
        .plat-cascader {
            .dot {
                margin: 0 auto;
                width: 28px;
                height: 22px;
                font-size: 14px;
                font-weight: 700;
                text-align: left;
                color: @--color-white;
                line-height: 22px;
            }
        }
        .border-arrow {
            width: 100%;
            height: 16px;
            background-image: url("~images/border-arrow.png");
            background-position: center;
            background-size: 87px 16px;
            background-repeat: no-repeat;
            margin: 8px 0;
        }
        .province-hightLights {
            /deep/ .el-row {
                padding-bottom: 4.5px;
            }
        }
    }
    .footer-content {
        .wrapper {
            height: 160px;
            margin: 0 17px;
            width: 97.5%;
        }
        .timeline {
            background-image: url("~images/timeline.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            &.time10 {
                background-image: url("~images/timeline/timeline.png");
            }
            &.time11 {
                background-image: url("~images/timeline/cq_timeline_11.png");
            }
            &.time12 {
                background-image: url("~images/timeline/cq_timeline_12.png");
            }
        }
    }
}
</style>

<template>
    <div class="container">
        <swing-layout :container-style="{ backgroundColor: '#03071E', backgroundImage: `url(${bg})` }"
                      title="全国药品和医用耗材招采管理系统">
            <div slot="left"
                 class="left-content">
                <border title="平台建设目标"
                        class="system-build">
                    <ul class="system-list">
                        <li class="list-item">
                            为国家组织药械招采提供应用支持
                        </li>
                        <li class="list-item">
                            对32个省级医保局药械招采实施统一监督管理
                        </li>
                        <li class="list-item">
                            支撑国家医保局规范指导各地药械招采工作
                        </li>
                        <li class="list-item">
                            协力搭建全国统一医保信息平台
                        </li>
                    </ul>
                </border>
                <border title="系统功能模块"
                        type="img"
                        class="system-module">
                    <el-row :gutter="14">
                        <el-col v-for="(item, index) in systemModules"
                                :key="index"
                                :span="item.span">
                            <div class="item"
                                 :class="item.type">
                                {{ item.label }}
                            </div>
                        </el-col>
                    </el-row>
                </border>
                <border title="全国统一平台规划"
                        class="plat-plan">
                    <ul class="plan-list">
                        <div class="line" />
                        <div class="arrow" />
                        <li class="item">
                            <span class="dot" />
                            2020年10月，完成国家医保信息平台项目建设
                        </li>
                        <!-- <li class="item">
                            <span class="dot" />
                            2020年底，地方完成相关信息系统部署工作
                        </li> -->
                        <li class="item">
                            <span class="dot" />
                            2021年，实现医保信息系统的全面联通
                        </li>
                    </ul>
                </border>
            </div>
            <div slot="middle"
                 class="middle-content">
                <!-- <border class="system-name">
                    <div class="title">
                        全国统一药械招采平台
                    </div>
                </border> -->
                <div class="subTitle-list">
                    <el-row :gutter="25">
                        <el-col :span="12">
                            <div class="subTitle">
                                国家下发统一版本
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="subTitle">
                                助推各省份落地
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <dv-flyline-chart-enhanced :config="config"
                                           class="map-wrapper" />
                <div class="icon" />
            </div>
            <div slot="right"
                 class="right-content">
                <border title="局本级平台亮点"
                        class="plat-hightLights">
                    <el-row :gutter="8">
                        <el-col :span="8"
                                style="text-align: right;">
                            <box-shadow>顶层设计</box-shadow>
                        </el-col>
                        <el-col :span="8"
                                style="text-align: center;">
                            <box-shadow>基础平台</box-shadow>
                        </el-col>
                        <el-col :span="8"
                                style="text-align: left;">
                            <box-shadow>统一标准</box-shadow>
                        </el-col>
                    </el-row>
                    <el-row :gutter="16">
                        <el-col :span="12"
                                style="text-align: right;">
                            <box-shadow>数据汇集</box-shadow>
                        </el-col>
                        <el-col :span="12"
                                style="text-align: left;">
                            <box-shadow>综合监管</box-shadow>
                        </el-col>
                    </el-row>
                </border>
                <div class="border-arrow" />
                <border title="两级平台联动"
                        class="plat-cascader">
                    <box-shadow-bottom v-for="(item, index) in levelCascader"
                                       :key="index">
                        {{ item.label }}
                    </box-shadow-bottom>
                    <div class="dot">
                        .......
                    </div>
                </border>
                <div class="border-arrow" />
                <border title="省本级平台亮点"
                        class="province-hightLights">
                    <el-row :gutter="8">
                        <el-col :span="8"
                                style="text-align: right;">
                            <box-shadow green>
                                中台组装
                            </box-shadow>
                        </el-col>
                        <el-col :span="8"
                                style="text-align: center;">
                            <box-shadow green>
                                按需使用
                            </box-shadow>
                        </el-col>
                        <el-col :span="8"
                                style="text-align: left;">
                            <box-shadow green>
                                接口对接
                            </box-shadow>
                        </el-col>
                    </el-row>
                    <el-row :gutter="16">
                        <el-col :span="12"
                                style="text-align: right;">
                            <box-shadow green>
                                遵循统一标准
                            </box-shadow>
                        </el-col>
                        <el-col :span="12"
                                style="text-align: left;">
                            <box-shadow green>
                                个性化拓展
                            </box-shadow>
                        </el-col>
                    </el-row>
                </border>
            </div>
            <div slot="footer"
                 class="footer-content">
                <border class="wrapper">
                    <div class="timeline"
                         :class="['time'+ calTime]" />
                </border>
            </div>
        </swing-layout>
    </div>
</template>
<script>

import SwingLayout from '@/components/layout/swingLayout';
import border from '@/components/border';
import dot from '@/images/dot.svg';
import bg from '@/images/bg.jpg';
import boxShadow from './components/box-shadow';
import boxShadowBottom from './components/box-shadow-bottom';

const dotItem = `<span style="background-image:url(${dot});
                            width: 10px;
                            height: 10px;
                            display:inline-block;
                            margin-right: 12px;
                            background-size: 10px;
                "></span>`;


export default {
    components: {
        SwingLayout,
        border,
        boxShadow,
        boxShadowBottom
    },
    data() {
        return {
            bg,
            config: {
                line: {
                    width: 3,
                    duration: [25, 25]
                },
                points: [
                    {
                        name: '河北',
                        coordinate: [0.73, 0.35]
                    },
                    {
                        name: '青海',
                        coordinate: [0.36, 0.44]
                    },
                    {
                        name: '吉林',
                        coordinate: [0.85, 0.22]
                    },
                    {
                        name: '山东',
                        coordinate: [0.77, 0.44]
                    },
                    {
                        name: '广东',
                        coordinate: [0.69, 0.76]
                    },
                    {
                        name: '新疆',
                        coordinate: [0.18, 0.33]
                    },
                    {
                        name: '西藏',
                        coordinate: [0.23, 0.57]
                    },
                    {
                        name: '四川',
                        coordinate: [0.49, 0.62]
                    },
                    {
                        name: '云南',
                        coordinate: [0.46, 0.76]
                    },
                    {
                        name: '内蒙古',
                        coordinate: [0.64, 0.34]
                    },
                    {
                        name: '山西',
                        coordinate: [0.65, 0.45]
                    },
                    {
                        name: '陕西',
                        coordinate: [0.61, 0.49]
                    },
                    {
                        name: '宁夏',
                        coordinate: [0.55, 0.45]
                    },
                    {
                        name: '江苏',
                        coordinate: [0.80, 0.54]
                    },
                    {
                        name: '浙江',
                        coordinate: [0.82, 0.64]
                    },
                    {
                        name: '福建',
                        coordinate: [0.80, 0.71]
                    },
                    {
                        name: '广西',
                        coordinate: [0.61, 0.79]
                    },
                    {
                        name: '贵州',
                        coordinate: [0.57, 0.71]
                    },
                    {
                        name: '安徽',
                        coordinate: [0.76, 0.58]
                    },
                    {
                        name: '黑龙江',
                        coordinate: [0.87, 0.17]
                    }
                ],
                lines: [
                    {
                        source: '河北',
                        target: '青海'
                    },
                    {
                        source: '河北',
                        target: '吉林'
                    },
                    {
                        source: '河北',
                        target: '山东'
                    },
                    {
                        source: '河北',
                        target: '广东'
                    },
                    {
                        source: '河北',
                        target: '新疆'
                    },
                    {
                        source: '河北',
                        target: '西藏'
                    },
                    {
                        source: '河北',
                        target: '四川'
                    },
                    {
                        source: '河北',
                        target: '云南'
                    },
                    {
                        source: '河北',
                        target: '内蒙古'
                    },
                    {
                        source: '河北',
                        target: '山西'
                    },
                    {
                        source: '河北',
                        target: '陕西'
                    },
                    {
                        source: '河北',
                        target: '宁夏'
                    },
                    {
                        source: '河北',
                        target: '江苏'
                    },
                    {
                        source: '河北',
                        target: '浙江'
                    },
                    {
                        source: '河北',
                        target: '福建'
                    },
                    {
                        source: '河北',
                        target: '广西'
                    },
                    {
                        source: '河北',
                        target: '贵州'
                    },
                    {
                        source: '河北',
                        target: '安徽'
                    },
                    {
                        source: '河北',
                        target: '黑龙江'
                    }
                ],
                k: 0.5,
                bgImgSrc: 'https://bucket-font.oss-cn-beijing.aliyuncs.com/img/datascreen/gj_map.png'
            },
            prodEnpSale: {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 3,
                align: ['left'],
                hoverPause: true,
                waitTime: 200000,
                data: [
                    [`${dotItem}为国家医保局提供国家级药械招标采购应用支持`],
                    [`${dotItem}对全国32个省级医保部门的药械招标采购实施统一信息监测`],
                    [`${dotItem}支撑国家医保局规范指导全国各地药械招标采购工作`],
                    [`${dotItem}为国家医保局提供国家级药械招标采购应用支持`],
                    [`${dotItem}对全国32个省级医保部门的药械招标采购实施统一信息监测`],
                    [`${dotItem}支撑国家医保局规范指导全国各地药械招标采购工作`],
                    [`${dotItem}北京市海淀会友精细化工厂`],
                    [`${dotItem}北京市海淀会友精细化工厂`]
                ]
            },
            systemModules: [
                {
                    span: 8,
                    type: 'half',
                    label: '数据监管'
                },
                {
                    span: 8,
                    type: 'half',
                    label: '招标管理'
                },
                {
                    span: 8,
                    type: 'half',
                    label: '基本业务服务'
                },
                {
                    span: 12,
                    type: 'half',
                    label: '门户权限管理'
                },
                {
                    span: 12,
                    type: 'half',
                    label: '基础数据管理'
                },
                {
                    span: 12,
                    type: 'success',
                    label: '交易管理'
                },
                {
                    span: 12,
                    type: 'success',
                    label: '结算管理'
                }
            ],
            departPlatform: [
                {
                    span: 8,
                    label: '顶层设计'
                },
                {
                    span: 8,
                    label: '基础平台'
                },
                {
                    span: 8,
                    label: '统一标准'
                },
                {
                    span: 12,
                    label: '数据汇集',
                    style: {
                        textAlign: 'right'
                    }
                },
                {
                    span: 12,
                    label: '综合监管'
                }
            ],
            levelCascader: [
                {
                    label: '价格共享联动'
                },
                {
                    label: '全国统一标准目录编码'
                },
                {
                    label: '产品黑名单下发和执行'
                },
                {
                    label: '政策属性管理'
                },
                {
                    label: '多种招标/结算支付模式'
                }
            ],
            provincePlatform: [
                {
                    span: 8,
                    label: '中台组装'
                },
                {
                    span: 8,
                    label: '按需使用'
                },
                {
                    span: 8,
                    label: '接口对接'
                },
                {
                    span: 12,
                    label: '遵循统一标准',
                    style: {
                        textAlign: 'right'
                    }
                },
                {
                    span: 12,
                    label: '个性化拓展'
                }
            ]
        };
    },
    computed: {
        calTime() {
            const current = new Date().getMonth() + 1;
            if (current === 12) {
                return current;
            }
            return '';
        }
    }
};
</script>
