/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-24 10:42:21
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-09-24 17:21:05
 * @Description: Description
 */
<template>
    <border :green="green">
        <border-header v-if="title"
                       :green="green"
                       :title="title" />
        <slot />
    </border>
</template>

<script>
import border from '@/components/border';
import borderHeader from './border-header';

export default {
    components: {
        border,
        borderHeader
    },
    props: {
        green: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        }
    }
};
</script>
