import { render, staticRenderFns } from "./data-cell.vue?vue&type=template&id=65d6e622&scoped=true&"
import script from "./data-cell.vue?vue&type=script&lang=js&"
export * from "./data-cell.vue?vue&type=script&lang=js&"
import style0 from "./data-cell.vue?vue&type=style&index=0&id=65d6e622&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d6e622",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/daniel/work/jiangxi/fed-sd-visual-web-static/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65d6e622')) {
      api.createRecord('65d6e622', component.options)
    } else {
      api.reload('65d6e622', component.options)
    }
    module.hot.accept("./data-cell.vue?vue&type=template&id=65d6e622&scoped=true&", function () {
      api.rerender('65d6e622', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/bussiness/south-mec/home/components/data-cell.vue"
export default component.exports