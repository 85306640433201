/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-24 14:43:28
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-13 16:40:36
 * @Description: Description
 */
<style lang='less' scoped>
.solid-border {
    position: relative;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .left-border,
    .right-border {
        width: 10px;
        height: 60px;
        position: absolute;
        background-size: 10px 60px;
        background-position: center;
        background-repeat: no-repeat;
    }
    .left-border {
        left: -10px;
        top: 0;
        background-image: url("~images/sm-title-border/leftborder.png");
    }
    .right-border {
        right: -10px;
        top: 0;
        background-image: url("~images/sm-title-border/rightborder.png");
    }
}
</style>

<template>
    <div class="solid-border">
        <slot />
        <div class="left-border" />
        <div class="right-border" />
    </div>
</template>
<script>
export default {
    data() {
        return {

        };
    },
    computed: {
    
    },
    mounted() {
    
    },
    methods: {

    }
};
</script>
