/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-16 10:17:43
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-05-12 13:54:45
 * @Description: flex构成的双飞翼布局
 */
<style lang='less' scoped>
.layout-container {
    display: flex;
    flex-direction: column;
    background-size: 100%;
    font-size: 28px;
    .swing-container,
    .swing-footer {
        flex: 1;
    }
}

.swing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 0 16px 0 16px;
    overflow: hidden;
    position: relative;
    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .middle {
        position: relative;
        .arrowD {
            height: 10px;
            background-image: url("~images/arrowD.png");
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: top;
        }
    }
    .left,
    .right {
        position: relative;
        top: -34px;
    }
    .header {
        text-align: center;
        height: 66px;
        width: 200%;
        display: inline-block;
        .wrapper {
            display: inline-block;
            height: 99%;
        }
        .title {
            font-weight: 700;
            text-align: center;
            display: inline-block;
            color: @--color-white;
            line-height: 37px;
            text-shadow: 0 2px 45px 0 #0091ff;
        }
        .top-left,
        .top-right {
            width: 466px;
            height: 100%;
            float: left;
        }
        .top-left,
        .top-right,
        .top-center {
            background-size: 100% 90%;
            background-position: top center;
            background-repeat: no-repeat;
            border: 0;
        }
        .top-left {
            background-image: url('~images/swing-left.png');
        }
        .top-right {
            background-image: url('~images/swing-right.png');
        }
        .top-center {
            margin: 0 -2px;
            float: left;
            text-align: center;
            background-image: url('~images/swing-middle.png');
            height: 100%;
            line-height: 66px;
            min-width: 362px;
            vertical-align: top;
        }
    }
}
.swing-footer {
    position: relative;
    top: -34px;
}
</style>

<template>
    <div class="layout-container" 
         :style="containerStyle">
        <div class="swing-container">
            <div class="header">
                <div class="wrapper">
                    <div class="top-left" />
                    <div class="top-center title">
                        {{ title }}
                    </div>
                    <div class="top-right" />
                </div>
            </div>
            <div class="container">
                <div class="left"
                     :style="{ flex: flexLeft }">
                    <slot name="left" />
                </div>
                <div class="middle"
                     :style="{flex: flexCenter}">
                    <div class="arrowD" />
                    <slot name="middle" />
                </div>
                <div class="right"
                     :style="{flex: flexRight}">
                    <slot name="right" />
                </div>
            </div>
        </div>
        <div class="swing-footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        containerStyle: {
            type: Object,
            default: () => ({})
        },
        title: {
            type: String,
            default: '国家局项目'
        },
        flexLeft: {
            type: [Number, String],
            default: 1
        },
        flexRight: {
            type: [Number, String],
            default: 1
        },
        flexCenter: {
            type: [Number, String],
            default: 2
        }
    }
};
</script>
