var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "swing-layout",
        {
          attrs: {
            title: "药品耗材联合限价采购三明联盟",
            "container-style": {
              backgroundColor: "#03071E",
              backgroundImage: "url(" + _vm.bg + ")"
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "left-content",
              attrs: { slot: "left" },
              slot: "left"
            },
            [
              _c(
                "sm-border",
                { staticClass: "union-aim", attrs: { title: "联盟宗旨" } },
                [
                  _c("div", { staticClass: "content" }, [
                    _c("pre", [
                      _vm._v(
                        "    争取以量换价，降低药品耗材虚高价格，规范药品\n耗材的采购与使用行为，堵浪费，调结构，促进联盟成\n员地区“三医联动”医药卫生体制综合改革见成效。"
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "sm-border",
                {
                  staticClass: "sm-event",
                  attrs: { green: "", title: "三明联盟大事记" }
                },
                [
                  _c("div", { staticClass: "content" }, [
                    _c("ul", { staticClass: "plan-list" }, [
                      _c("div", { staticClass: "line" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "arrow" }),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(
                          "\n                                2015年起，国务院办公厅多次发文要求总结、完善和推广三明医改经验\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(
                          "\n                                2016年3月，三明联盟正式成立，公司成为联盟指定平台服务商\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(
                          "\n                                2016年7月，河北省6市28县入盟\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(
                          "\n                                2018年，公司助力贵州省铜仁市、内蒙古兴安盟上线平台\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(
                          "\n                                2019年，公司助力河北省邯郸市、新疆兵团第八师石河子市上线平台\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(
                          "\n                                2020年4月，公司助力联盟成功完成全国首次跨区域非一致性评价药品带量采购\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(
                          "\n                                2012年至2020年，三明市8年共相对节约药品耗材费用约124.08亿元\n                            "
                        )
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sm-border",
            {
              staticClass: "middle-content",
              attrs: { slot: "middle" },
              slot: "middle"
            },
            [
              _c("solid-border", [
                _c("div", { staticClass: "sm-title" }, [
                  _c("div", { staticClass: "logo" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "desc" }, [
                    _vm._v("\n                            平台共享"),
                    _c("span", { staticClass: "dot" }),
                    _vm._v("\n                            目录共享"),
                    _c("span", { staticClass: "dot" }),
                    _vm._v("\n                            价格共享"),
                    _c("span", { staticClass: "dot" }),
                    _vm._v(
                      "\n                            资源共享\n                            "
                    ),
                    _c("div", { staticClass: "left-top" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "right-bottom" })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sm-digital" }, [
                _c(
                  "div",
                  { staticClass: "digital-item" },
                  [
                    _c("data-cell", [
                      _c(
                        "div",
                        {
                          staticClass: "val",
                          attrs: { slot: "val" },
                          slot: "val"
                        },
                        [
                          _vm._v("\n                                16"),
                          _c("span", { staticClass: "unit" }, [_vm._v("省")]),
                          _vm._v("27"),
                          _c("span", { staticClass: "unit" }, [_vm._v("市")]),
                          _vm._v("4"),
                          _c("span", { staticClass: "unit" }, [_vm._v("县")])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "label",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [
                          _vm._v(
                            "\n                                覆盖地区\n                            "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "digital-item" },
                  [
                    _c("data-cell", [
                      _c(
                        "div",
                        {
                          staticClass: "val",
                          attrs: { slot: "val" },
                          slot: "val"
                        },
                        [
                          _vm._v("\n                                15116"),
                          _c("span", { staticClass: "unit" }, [_vm._v("万")])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "label",
                          attrs: { slot: "label" },
                          slot: "label"
                        },
                        [
                          _vm._v(
                            "\n                                覆盖人口\n                            "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "right-content",
              attrs: { slot: "right" },
              slot: "right"
            },
            [
              _c(
                "sm-border",
                {
                  staticClass: "union-condition",
                  attrs: { title: "入盟条件" }
                },
                [
                  _c("div", { staticClass: "content" }, [
                    _c("ul", { staticClass: "list" }, [
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "detail" }, [
                          _vm._v("本着“自愿、互惠互利”原则")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "detail" }, [
                          _vm._v(
                            "一般分为设区市以上单位（已在联盟中的县级成员保留）"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "detail" }, [
                          _vm._v(
                            "领导重视，有推动“三医联动”医药卫生体制改革的信心和决心，出台初步方案，明确初步措施，预见初步成效"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("span", { staticClass: "dot" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "detail" }, [
                          _vm._v(
                            "向所在省有关部门报备，接受所在省内主管部门的监管"
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "sm-border",
                { staticClass: "join-union", attrs: { title: "入盟方式" } },
                [
                  _c("div", { staticClass: "content" }, [
                    _c("pre", [
                      _vm._v(
                        "    三明联盟平台根据统一平台、共享目录、属地管\n理、统一标准、开放接口的思路进行设计与建设，目\n前支持两种对接方式： \n\n一、直接入驻三明联盟平台，联盟成员只需要开通属\n地管理后台系统即可开展联合采购业务。\n\n二、支持其他平台与三明联盟平台对接，实现目录与\n价格数据联动。\n                        "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sm-border",
            {
              staticClass: "footer-part",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.show
                ? _c(
                    "swiper",
                    {
                      staticClass: "areaList-wrapper",
                      attrs: { options: _vm.resultSwiper }
                    },
                    _vm._l(_vm.areaListFormatter, function(row, i) {
                      return _c(
                        "swiper-slide",
                        { key: "arealist-" + i, staticClass: "areaList" },
                        [
                          _vm._l(row, function(item, index) {
                            return [
                              _c("div", {
                                key: index,
                                staticClass: "pic-cell",
                                style: { backgroundImage: "url(" + item + ")" }
                              })
                            ]
                          })
                        ],
                        2
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }