/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-16 10:03:11
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-04-21 18:00:23
 * @Description: 政府版-首屏
 */

<style lang='less' scoped>
.container {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    box-sizing: content-box;
    font-weight: 300;
    /deep/.dv-scroll-board {
        .rows {
            .row-item {
                font-size: 12px;
            }
        }
    }
    .left-content {
        display: flex;
        .right-part {
            flex: 1 1 0;
            .plat-scale {
                .plat-scale-val {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    margin-bottom: 3px;
                }
                /deep/ .el-col {
                    border-right: 1px solid rgba(0, 121, 245, 0.65);
                    &:last-child {
                        border-right: 0;
                    }
                }
                /deep/ .el-row:first-child {
                    margin-bottom: 13px;
                }
                /deep/ .el-row:last-child {
                    margin-bottom: 11px;
                }
            }
            .bussiness-result {
                .result-detail {
                    width: 100%;
                    height: 112px;
                    margin-bottom: 12px;
                    background: rgba(0, 145, 255, 0.2);
                    position: relative;
                    .title {
                        text-align: center;
                        font-size: 14px;
                        font-weight: 700;
                        color: @--color-white;
                        line-height: 19px;
                        padding: 14px 0 14px 0;
                        background-image: url('~images/arrowD.png');
                        background-repeat: no-repeat;
                        background-position: center bottom;
                        background-size: 12px;
                    }
                    .content {
                        margin-top: 6px;
                        .label {
                            font-size: 12px;
                            text-align: left;
                            color: @--color-white;
                            line-height: 16px;
                        }
                        .val {
                            font-size: 20px;
                            font-weight: 700;
                            text-align: center;
                            color: #f7b500;
                            line-height: 24px;
                            margin-top: 9px;
                            .unit {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                    }
                    .border-dot {
                        width: 9px;
                        height: 4px;
                        display: inline-block;
                        background: url("~images/border-dot.png") no-repeat;
                        background-size: 100%;
                        background-position: center;
                        &.left {
                            position: absolute;
                            bottom: 3px;
                            left: 10px;
                        }
                        &.right {
                            position: absolute;
                            bottom: 3px;
                            right: 10px;
                        }
                    }
                }
                .result-list-wrapper {
                    @media screen and (min-height: 1080px) {
                        .result-list {
                            height: 187px;
                        }
                    }

                    @media screen and (height: 768px) {
                        .result-list {
                            height: 170px;
                        }
                    }
                    .result-list {
                        color: @--color-white;
                        text-align: left;
                        width: 100%;
                        .list-item {
                            // margin-bottom: 13px;
                            .item-title {
                                font-size: 14px;
                                line-height: 14px;
                                height: 16px;
                                font-weight: 700;
                                margin-bottom: 5px;
                                &::before {
                                    content: '';
                                    height: 16px;
                                    width: 3px;
                                    line-height: 16px;
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 7px;
                                    background-color: #108ee9;
                                }
                            }
                            .item-content {
                                font-size: 12px;
                                line-height: 16px;
                                margin-left: 14px;
                            }
                        }
                    }
                }
            }
            .bussiness-module {
                /deep/ .content {
                    margin-top: 16px;
                }
                .el-col {
                    margin-bottom: 15px;
                    &:nth-child(3) {
                        margin-bottom: 0;
                    }
                    &:nth-child(4) {
                        margin-bottom: 0;
                    }
                }
                .module-img {
                    display: inline-block;
                    width: 75px;
                    height: 60px;
                }
            }
        }
        .left-part {
            flex: 1 1 0;
            padding-right: 17px;
            .hx-hightLights {
                .hightLights-list {
                    .item {
                        padding: 5px 11px;
                        margin-bottom: 4px;
                        font-size: 14px;
                        text-align: left;
                        color: @--color-white;
                        line-height: 19px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &:nth-child(even) {
                            background: rgba(0, 145, 255, 0.2);
                        }
                        &:nth-child(odd) {
                            background: rgba(0, 145, 255, 0.4);
                        }
                        .dot {
                            width: 10px;
                            height: 10px;
                            display: inline-block;
                            margin-right: 4px;
                            background-image: url("~images/dot.svg");
                            background-position: center;
                            background-size: 10px;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
            .data-analysis {
                /deep/.content {
                    margin-top: 24px;
                    margin-bottom: 22px;
                }
                .img {
                    width: 37px;
                    height: 37px;
                    background-size: 37px;
                    margin-bottom: 8px;
                    display: inline-block;
                }
            }
            .enp-sale {
                /deep/ .content {
                    height: 205px;
                }
                /deep/ .ceil {
                    &:last-child {
                        padding-right: 0;
                        width: 25% !important;
                    }
                    &:first-child {
                        padding-left: 0;
                        width: 75% !important;
                    }
                }
            }
        }
    }
    .middle-content {
        padding: 25px 48px 0 48px;
        height: 100%;
        background: url("https://bucket-font.oss-cn-beijing.aliyuncs.com/img/datascreen/zf_map.png") no-repeat center / 684px 545px;
        background-position: 50% 66%;
        position: relative;
        &.guest {
            background-position: 50% 62%;
        }
        .about {
            .about-val {
                font-weight: 700;
                font-size: 21px;
                color: #f4a607;
                line-height: 24px;
                margin-bottom: 8px;
            }
            .sevenDataAmount,
            .total-amount-list {
                border: 1px solid rgba(0, 145, 255, 0.5);
                box-shadow: 0 0 21px 0 #0b2468 inset;
                text-align: center;
                padding: 21px 0 17px 0;
            }
            .total-amount-list {
                /deep/ .el-col {
                    border-left: 1px solid rgba(0, 145, 255, 0.5);
                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }
        .detail {
            position: absolute;
            bottom: 80px;
            padding-left: 53px;
            left: 0;
            right: 0;
            color: @--color-white;
            & > .title {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 14px;
            }
            .total-bar {
                height: 50px;
            }
            .swiper-slide-active {
                .data-cell {
                    font-weight: 700;
                }
            }
            .data-cell {
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 12px;
                text-align: left;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding-right: 20px;
                .dot {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    background: url("~images/dot2.png") no-repeat center / 14px 14px;
                    margin-right: 7px;
                    vertical-align: middle;
                }
            }
        }
    }
    .right-content {
        display: flex;
        /deep/ .dv-scroll-board {
            .rows {
                .ceil {
                    color: #4dd4ff;
                    padding-left: 0;
                    &:first-child {
                        text-overflow: inherit;
                    }
                }
            }
        }
        .left-part {
            flex: 1 1 0%;
            .hot-sale {
                /deep/ .content {
                    height: 250px;
                }
            }
            .order-amt {
                .order-amt-wrapper {
                    .title {
                        color: #f7b500;
                        font-size: 24px;
                        line-height: 28px;
                        font-weight: BoldMT;
                        text-align: right;
                        margin-bottom: 20px;
                        .unit {
                            font-weight: 600;
                            text-align: right;
                            line-height: 20px;
                            font-size: 14px;
                            margin-left: 4px;
                        }
                    }
                }
            }
        }
        .right-part {
            flex: 1 1 0;
            margin-left: 16px;
            .rank {
                /deep/ .content {
                    height: 250px;
                    .linearWrapper {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            .deal-report {
                .report-header {
                    color: #4dd4ff;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 17px;
                    padding: 13px 0 6px 0;
                    // border-bottom: 1px solid rgba(71, 197, 238, 0.4);
                    .header-time {
                        width: 100%;
                        display: inline-block;
                        text-align: right;
                        padding-right: 12px;
                        box-sizing: border-box;
                        vertical-align: middle;
                    }
                    .header-amt {
                        width: 100%;
                        display: inline-block;
                        text-align: right;
                        // padding-right: 12px;
                        box-sizing: border-box;
                        vertical-align: middle;
                    }
                }
                .report-list {
                    color: #fff;
                    height: 286px;
                    .list-item {
                        .content {
                            padding: 10px 0;
                            border-bottom: 1px solid rgba(71, 197, 238, 0.4);
                            .prod-name {
                                font-size: 12px;
                                height: 3em;
                                line-height: 1em;
                            }
                            .pur-amt {
                                color: #ffa826;
                                font-size: 14px;
                                text-align: right;
                                height: 3em;
                                line-height: 3em;
                            }
                            .pur-time {
                                font-size: 12px;
                                text-align: right;
                                height: 3em;
                                line-height: 3em;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div class="container">
        <swing-layout :container-style="{ backgroundColor: '#03071E', backgroundImage: `url(${bg})`,backgroundRepeat: 'round' }"
                      :flex-left="8"
                      :flex-right="7.69"
                      :flex-center="6"
                      :title="$route.meta.title || '厦门海西医药交易中心药械交易平台'">
            <div slot="left"
                 class="left-content">
                <div class="left-part">
                    <border title="数据智能分析"
                            class="data-analysis">
                        <el-row>
                            <el-col v-for="(item, index) in dataAnalysis"
                                    :key="index"
                                    :span="6">
                                <data-cell type="img">
                                    <div slot="val"
                                         class="img"
                                         :style="{backgroundImage: `url(${item.image})`}" />
                                    <div slot="label">
                                        {{ item.label }}
                                    </div>
                                </data-cell>
                            </el-col>
                        </el-row>
                    </border>
                    <border title="生产企业销售金额TOP20"
                            sub-title="单位：万元"
                            class="enp-sale">
                        <dv-scroll-board :config="prodEnpSale" />
                    </border>
                    <border title="医疗机构采购金额TOP20"
                            sub-title="单位：万元"
                            class="enp-sale">
                        <dv-scroll-board :config="medEnpSale" />
                    </border>
                </div>
                <div class="right-part">
                    <border title="平台覆盖规模"
                            class="plat-scale">
                        <el-row>
                            <el-col :span="12">
                                <data-cell>
                                    <div slot="val"
                                         class="plat-scale-val">
                                        22省的105市
                                    </div>
                                    <div slot="label"
                                         class="plat-scale-label">
                                        覆盖地区
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col :span="12">
                                <data-cell>
                                    <div slot="val"
                                         class="plat-scale-val">
                                        45476.9万
                                    </div>
                                    <div slot="label"
                                         class="plat-scale-label">
                                        覆盖人口
                                    </div>
                                </data-cell>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="plat-scale-val">
                                        <!-- {{ orgNum.prodNum }}家 -->
                                        12681家
                                    </div>
                                    <div slot="label"
                                         class="plat-scale-label">
                                        生产企业
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="plat-scale-val">
                                        <!-- {{ orgNum.delvNum }}家 -->
                                        5293家
                                    </div>
                                    <div slot="label"
                                         class="plat-scale-label">
                                        配送企业
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="plat-scale-val">
                                        <!-- {{ orgNum.insNum }}家 -->
                                        11302家
                                    </div>
                                    <div slot="label"
                                         class="plat-scale-label">
                                        医疗机构
                                    </div>
                                </data-cell>
                            </el-col>
                        </el-row>
                    </border>
                    <border title="业务模块"
                            class="bussiness-module">
                        <el-row :gutter="24">
                            <el-col v-for="(item, index) in bussinessModule"
                                    :key="index"
                                    :span="6">
                                <data-cell type="img"
                                           class="module">
                                    <img slot="val"
                                         class="module-img"
                                         :src="item.img">
                                    <div slot="label"
                                         class="plat-scale-label">
                                        {{ item.label }}
                                    </div>
                                </data-cell>
                            </el-col>
                        </el-row>
                    </border>
                    <border title="业务成效"
                            class="bussiness-result">
                        <div class="result-detail">
                            <div class="title">
                                平台助力各地医改，成效显著
                            </div>
                            <el-row class="content">
                                <el-col :span="12">
                                    <data-cell reverse>
                                        <div slot="val"
                                             class="val">
                                            20% ~ 40%
                                        </div>
                                        <div slot="label">
                                            平台节约采购成本
                                        </div>
                                    </data-cell>
                                </el-col>
                                <el-col :span="12">
                                    <data-cell reverse>
                                        <div slot="val"
                                             class="val">
                                            <span class="unit">超</span> 270 <span class="unit">亿</span>
                                        </div>
                                        <div slot="label">
                                            累计节约采购成本
                                        </div>
                                    </data-cell>
                                </el-col>
                            </el-row>
                            <span class="border-dot left" />
                            <span class="border-dot right" />
                        </div>
                        <div class="result-list-wrapper">
                            <swiper :options="resultSwiper"
                                    class="result-list">
                                <swiper-slide v-for="(item, index) in serviceResult"
                                              :key="index"
                                              class="list-item">
                                    <div>
                                        <div class="item-title">
                                            {{ item.title }}
                                        </div>
                                        <div class="item-content"
                                             v-html="item.content" />
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </border>
                </div>
            </div>
            <div slot="middle"
                 class="middle-content"
                 :class="[guest ? 'guest' : '']">
                <el-row class="about"
                        :gutter="20">
                    <el-col
                        :span="6">
                        <data-cell class="sevenDataAmount">
                            <div slot="val"
                                 class="about-val">
                                {{ stat.orderAmtWeek }}
                            </div>
                            <div slot="label"
                                 class="seven-label">
                                近7日订单金额 (亿元)
                            </div>
                        </data-cell>
                    </el-col>
                    <el-col :span="18">
                        <el-row class="total-amount-list">
                            <el-col
                                :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="about-val">
                                        {{ stat.orderAmt }}
                                    </div>
                                    <div slot="label"
                                         class="seven-label">
                                        累计订单额 (亿元)
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col
                                :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="about-val">
                                        {{ stat.setlAmt }}
                                    </div>
                                    <div slot="label"
                                         class="seven-label">
                                        累计结算额 (亿元)
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col
                                :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="about-val">
                                        {{ stat.orderCnt }}
                                    </div>
                                    <div slot="label"
                                         class="seven-label">
                                        累计订单数 (笔)
                                    </div>
                                </data-cell>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <div slot="right"
                 class="right-content">
                <div class="left-part">
                    <border title="商品热销榜"
                            class="hot-sale">
                        <dv-scroll-board :config="hotSale" />
                    </border>
                    <border title="订单成交额"
                            sub-title="平台订单总成交额"
                            class="order-amt">
                        <div class="order-amt-wrapper">
                            <div class="title">
                                {{ orderAmtByProvinceTopTotal.totalAmt |dataConvert|toThousands }}<span class="unit">万元</span>
                            </div>
                            <div class="content">
                                <ve-histogram :data="chartData"
                                              :height="(280/ 16) + 'rem'"
                                              :extend="chartExtend"
                                              :settings="chartSettings" />
                            </div>
                        </div>
                    </border>
                </div>
                <div class="right-part">
                    <el-row :gutter="16">
                        <el-col :span="12">
                            <border title="商家销售榜"
                                    class="hot-sale rank">
                                <dv-scroll-board :config="storeSaleRank" />
                            </border>
                        </el-col>
                        <el-col :span="12">
                            <border title="消费省份排名"
                                    class="hot-sale rank">
                                <dv-scroll-board :config="hotProvince" />
                            </border>
                        </el-col>
                    </el-row>
                    <border title="成交播报"
                            class="deal-report">
                        <div>
                            <el-row class="report-header"
                                    :gutter="15">
                                <el-col :span="11">
                                    成交订单
                                </el-col>
                                <el-col :span="6">
                                    <span class="header-amt">成交额（元）</span>
                                </el-col>
                                <el-col :span="6">
                                    <span class="header-time">成交时间</span>
                                </el-col>
                            </el-row>
                            <swiper :options="reportSwiper"
                                    class="report-list">
                                <swiper-slide v-for="(item, index) in screenOrderTopList"
                                              :key="index"
                                              class="list-item">
                                    <div>
                                        <el-row class="content"
                                                type="flex"
                                                align="middle"
                                                :gutter="15">
                                            <el-col :span="11"
                                                    class="prod-name">
                                                {{ item.orderInfo }}
                                            </el-col>
                                            <el-col :span="6"
                                                    class="pur-amt">
                                                {{ Number(item.totalAmt)|toThousands }}
                                            </el-col>
                                            <el-col :span="6"
                                                    class="pur-time">
                                                {{ item.gmtCreated|timeConvert }}
                                            </el-col>
                                        </el-row>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </border>
                </div>
            </div>
        </swing-layout>
    </div>
</template>
<script>

import SwingLayout from '@/components/layout/swingLayout';
import border from '@/components/border';
import priceAnalysis from '@/images/price.svg';
import warningExp from '@/images/warning.svg';
import settleSup from '@/images/settle.svg';
import marketAnalysis from '@/images/market.png';
import numTop from '@/images/num_top.png';
import numDef from '@/images/num_def.png';
import bg from '@/images/bg.jpg';
import module1 from '@/images/gov-module/zf_icon01.png';
import module2 from '@/images/gov-module/zf_icon02.png';
import module3 from '@/images/gov-module/zf_icon03.png';
import module4 from '@/images/gov-module/zf_icon04.png';
import VeHistogram from 'v-charts/lib/histogram.common';
import echarts from 'echarts';
import { toThousands } from '@/utils/utils';
import dataCell from './components/data-cell';

const numTopDom = (index, content = '') => (`<span class="dotIndex" style="background-image:url(${numTop});">${index}</span><span class="dot-content">${content}</span>`);
const numDefDom = (index, content = '') => (`<span class="dotIndex" style="background-image:url(${numDef});">${index}</span><span class="dot-content">${content}</span>`);
const linearWrapper = content => (`<div class="linearWrapper">${content}</div>`);
const linearWrapperOpacity = content => (`<div class="linearWrapper opacity">${content}</div>`);

export default {
    components: {
        SwingLayout,
        border,
        dataCell,
        VeHistogram
    },
    filters: {
        toThousands(val) {
            if (!val) return '--';
            return toThousands(val);
        },
        dataConvert(convert) {
            if (typeof convert === 'string') {
                convert = parseFloat(convert);
            }
            if (convert >= 10000) {
                convert /= 10000;
                convert = `${convert.toFixed(2)}`;
            } else {
                convert *= 0.0001;
            }
            if (convert) {
                return convert;
            }
            return '--';
        },
        timeConvert(val) {
            if (!val) {
                return '--';
            }
            const current = new Date(val);
            return `${current.getFullYear()}.${current.getMonth() + 1}.${current.getDate()}`;
        }
    },
    data() {
        this.chartSettings = {
            metrics: ['totalAmt'],
            dimension: ['province']
        };

        this.chartExtend = {
            title: {
                text: '单位（万元）',
                textStyle: {
                    color: '#fff'
                }
            },
            textStyle: {
                color: '#969ba4'
            },
            tooltip: {
                show: false
            },
            itemStyle: {
                borderRadius: [30]
            },
            legend: {
                show: false
            },
            yAxis: {
                // type: 'value',
                show: true,
                splitLine: {
                    show: false
                },        
                axisLine: {
                    show: false
                },
                axisLabel: {
                    fontSize: 16,
                    formatter: val => toThousands((val / 10000).toFixed(2))
                }
            },
            xAxis: {
                show: true,        
                axisLine: {
                    show: false
                },
                axisLabel: {
                    interval: 0,
                    color: '#fff',
                    fontSize: 16,
                    padding: [8, 0, 0, 0]
                }
            },
            series: {
                barWidth: 60,
                label: {
                    show: true,
                    position: 'top',       
                    color: '#fff',
                    fontSize: 16,
                    formatter: val => toThousands((val.data / 10000).toFixed(2))
                },
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                            { offset: 0, color: '#43D2D2' },
                            { offset: 0.5, color: '#41D3D3' },
                            { offset: 1, color: '#32C5FF' }
                        ]
                    )
                }
            }
        };
        return {
            bg,
            bussinessModule: [
                {
                    img: module1,
                    label: '药械集中采购'
                },
                {
                    img: module2,
                    label: '医保资金结余留用'
                },
                {
                    img: module3,
                    label: '结算支付管理'
                },
                {
                    img: module4,
                    label: '药械综合监管'
                }
            ],
            dataAnalysis: [
                {
                    image: priceAnalysis,
                    label: '价格监测'
                },
                {
                    image: warningExp,
                    label: '异常预警'
                },
                {
                    image: settleSup,
                    label: '结算监管'
                },
                {
                    image: marketAnalysis,
                    label: '市场分析'
                }
            ],
            resultSwiper: {
                loop: true,
                direction: 'vertical',
                autoplay: true,
                slidesPerView: 3
            },
            reportSwiper: {
                loop: true,
                direction: 'vertical',
                autoplay: true,
                slidesPerView: 5,
                autoHeight: true
            },
            guest: '',
            insAmtTops: [], // 医疗机构采购金额TOP20
            orgNum: {
                delvNum: 3263,
                insNum: 10160,
                prodNum: 7708
            },
            prodAmtTops: [], // 生产企业销售金额TOP20
            stat: {
                orderAmt: '162.0846',
                orderAmtWeek: '12.8470',
                orderCnt: '7,053,892',
                setlAmt: '104.4917'
            }, // 总计
            statArea: [
            ], // 地图区域金额
            serviceResult: [
                {
                    title: '福建省',
                    content: '2017年上线至今，累计节约药品费用<span style="color: #f7b500;">超100亿元</span>'
                },
                {
                    title: '河北省',
                    content: '2019年7月上线以来，累计节约药品费用<span style="color: #f7b500;">超53亿元</span>'
                },
                {
                    title: '三明市',
                    content: '2012-2020年，共相对节约药品耗材费用<span style="color: #f7b500;">约100亿元</span>'
                },
                {
                    title: '邯郸市',
                    content: '2019年7月上线以来，年节约药品费用<span style="color: #f7b500;">超3.7亿元</span>'
                },
                {
                    title: '铜仁市',
                    content: '2018年1月上线至2020年7月底，药价下降27.64%，经测算，节约药品费用<span style="color: #f7b500;">约7.17亿元</span>'
                },
                {
                    title: '兴安盟',
                    content: '2018年1月上线至2020年7月底，药价较2017年下降30%，经测算，节约药品费用<span style="color: #f7b500;">约6.55亿元</span>'
                },
                {
                    title: '第八师石河子市',
                    content: '2019年上线至2020年7月底，药价较2018年下降23.44%，预计年节约药品费用<span style="color: #f7b500;">1.19亿元</span>'
                },
                {
                    title: '三明联盟',
                    content: '2020年， 7个非一致性评价药品集中带量采购，预计年节约药品费用<span style="color: #f7b500;">超2.4亿元</span>'
                },
                {
                    title: '国家“4+7”集采',
                    content: '4+7执行以来，厦门一年节约<span style="color: #f7b500;">2.4亿元</span>，福建省一年节约<span style="color: #f7b500;">12.9亿元</span>，河北省一年节<span style="color: #f7b500;">约35.59亿元</span>'
                }
            ],
            consumerProvinceTopList: [], // 消费省份排名
            goodsSalesTopList: [], // 商品热销榜
            orderAmtByProvinceTopTotal: {},
            screenOrderTopList: [], // 成交播报
            storeSalesTopList: [] // 商家销售榜
        };
    },
    computed: {
        chartData() {
            const data = [];
            if (this.orderAmtByProvinceTopTotal && Array.isArray(this.orderAmtByProvinceTopTotal.list)) {
                this.orderAmtByProvinceTopTotal.list.forEach(item => {
                    data.push({
                        province: item.province,
                        totalAmt: item.totalAmt
                    });
                });
            }
            return {
                columns: ['totalAmt', 'province'],
                rows: data
            };
        },
        prodEnpSale() {
            const data = [];
            this.prodAmtTops.forEach((item, index) => {
                const temp = [];
                if (item.orgName && item.orderAmt) {
                    if (index < 1) { // 标识哪几个的下标需要用红框
                        temp.push(numTopDom(index + 1, item.orgName));
                    } else {
                        temp.push(numDefDom(index + 1, item.orgName));
                    }
                    temp.push(item.orderAmt);
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 7,
                columnWidth: [180, 95],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        medEnpSale() {
            const data = [];
            this.insAmtTops.forEach((item, index) => {
                const temp = [];
                if (item.orgName && item.orderAmt) {
                    if (index < 1) { // 标识哪几个的下标需要用红框
                        temp.push(numTopDom(index + 1, item.orgName));
                    } else {
                        temp.push(numDefDom(index + 1, item.orgName));
                    }
                    temp.push(item.orderAmt);
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 7,
                columnWidth: [180, 95],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        mapAmtFormatter() {
            const data = [];
            let temp = [];
            this.statArea.forEach((item, index) => {
                temp.push(item);
                if ((index + 1) % 3 === 0) {
                    data.push(temp);
                    temp = [];
                }
            });
            if (temp.length !== 0) {
                data.push(temp);
            }
            return data;
        },
        hotSale() { // 商品热销榜
            const data = [];
            this.goodsSalesTopList.forEach((item, index) => {
                const temp = [];
                if (item) {
                    if (index < 1) { // 标识哪几个的下标需要用红框
                        temp.push(numTopDom(index + 1));
                    } else {
                        temp.push(numDefDom(index + 1));
                    }
                    if (index < 3) {
                        temp.push(linearWrapper(item));
                    } else {
                        temp.push(linearWrapperOpacity(item));
                    }
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 10,
                columnWidth: [38],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        hotProvince() { // 消费省份排名
            const data = [];
            this.consumerProvinceTopList.forEach((item, index) => {
                const temp = [];
                if (item) {
                    if (index < 1) { // 标识哪几个的下标需要用红框
                        temp.push(numTopDom(index + 1));
                    } else {
                        temp.push(numDefDom(index + 1));
                    }
                    if (index < 3) {
                        temp.push(linearWrapper(item));
                    } else {
                        temp.push(linearWrapperOpacity(item));
                    }
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 10,
                columnWidth: [38],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        storeSaleRank() { // 商家销售榜
            const data = [];
            this.storeSalesTopList.forEach((item, index) => {
                const temp = [];
                if (index < 1) { // 标识哪几个的下标需要用红框
                    temp.push(numTopDom(index + 1));
                } else {
                    temp.push(numDefDom(index + 1));
                }
                if (index < 3) {
                    temp.push(linearWrapper(item));
                } else {
                    temp.push(linearWrapperOpacity(item));
                }
                data.push(temp);
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 10,
                columnWidth: [38],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        }
    },
    created() {
        this.setRem();
        window.onresize = this.setRem;
        // 这个大屏是32: 9的屏幕。所以宽度用两倍
        document.documentElement.style.width = '200%';
    },
    mounted() {
        this.fetchData();
        this.fetchMecData();
    },
    activated() {
        this.fetchData();
        this.fetchMecData();
    },
    methods: {
        setRem() {
            const { clientHeight } = document.documentElement;
            document.documentElement.style.fontSize = `${(16 * clientHeight / 756)}px`;
        },
        fetchData() {
            this.$fetch({
                url: '/dap/goodsAmtStat/getStatInfo'
            }).then(res => {
                if (res.code === 0) {
                    const { insAmtTops, orgNum, prodAmtTops, stat, statArea } = res.data;
                    this.insAmtTops = insAmtTops;
                    this.orgNum = orgNum;
                    this.prodAmtTops = prodAmtTops;
                    this.stat = stat;
                    this.statArea = statArea;
                }
            });
        },
        async fetchMecData() {
            const response = await this.$fetch({
                url: '/hxyx/bigScreen/top'
            });
            if (response.code === 0) {
                if (response.code === 0) {
                    Object.assign(this, response.data);
                    this.orderAmtByProvinceTopTotal = response.data.orderAmtByProvinceTopTotal;
                }
            }
        }
    }
};
</script>
