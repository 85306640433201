var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "borderWrapper" }, [
    _vm.title !== ""
      ? _c("div", { staticClass: "title" }, [
          _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
          _c("span", { staticClass: "subTitle" }, [
            _vm._v("\n            " + _vm._s(_vm.subTitle) + "\n        ")
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
    _vm._v(" "),
    _c("div", {
      staticClass: "left-top-border border-div",
      class: _vm.green && "green"
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "left-bottom-border border-div",
      class: _vm.green && "green"
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "right-bottom-border border-div",
      class: _vm.green && "green"
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "right-top-border border-div",
      class: _vm.green && "green"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "bottom-line border-div" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }