/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-10-30 13:50:56
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-11-03 10:56:50
 * @Description: 重庆实验室的页面容器
 */
<template>
    <v-touch class="view-container">
        <transition>
            <keep-alive v-if="$route.meta.alive">
                <router-view />
            </keep-alive>
            <router-view v-else />
        </transition>
    </v-touch>
</template>

<script>

export default {
    data() {
        return {
            // basepath: 'common', // 普通版根路径
            // totalPage: 5 // 政府端的总页面
        };
    }
};
</script>
