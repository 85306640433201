var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("full-layout", { attrs: { title: "服务项目展示" } }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("digital-carousel", {
          attrs: {
            "carousel-item": _vm.carouselItem,
            "auto-play": _vm.autoPlay,
            interval: 4000
          },
          model: {
            value: _vm.activeIndex,
            callback: function($$v) {
              _vm.activeIndex = $$v
            },
            expression: "activeIndex"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }