/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-03 12:56:28
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-16 16:27:25
 * @Description: 微前端方案
 */

import '@babel/polyfill';
import '@/utils/rem';
import Vue from 'vue';
import VueRouter from 'vue-router';
import storeData from '@/store';
import fetch from '@/utils/fetch';
import configs from '@/config';
import VueTouch from 'vue-touch';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { fullScreenContainer, scrollBoard, flylineChartEnhanced } from '@jiaminghi/data-view';
import 'swiper/css/swiper';
import { getUrlParame } from '@/utils/utils';
import '@/css/common';
// token处理
function tokenHandler() {
    const token = getUrlParame('token');
    if (!token && !localStorage.getItem('token')) {
        // window.open(configs.loginPath, '_self');
    } else if (token) {
        localStorage.setItem('token', token);
    }
}

// 对外版处理
function guestHandler() {
    const label = 'guest';
    const guest = getUrlParame(label);
    if (guest) {
        localStorage.setItem(label, true);
    } else {
        localStorage.removeItem(label);
    }
}


function init({ routes = {} }, callback) {
    Vue.config.productionTip = false;
    const env = process.env.NODE_ENV;
    Vue.config.silent = env !== 'development';
    const router = new VueRouter({
        mode: 'history',
        // mode:'hash',
        routes
    });
    Vue.use(VueRouter);
    Vue.use(scrollBoard);
    Vue.use(VueTouch);
    Vue.use(fullScreenContainer);
    Vue.use(VueAwesomeSwiper);
    Vue.use(flylineChartEnhanced);
    if (callback)callback(Vue, router);
    Vue.mixin({
        methods: {
            $fetch(options) {
                // 避免应用互相污染
                return this.$root.fetch(options);
            }
        }
    });
    Vue.prototype.$bkConfig = configs;
    const appOptions = {
        // eslint-disable-next-line
        render: h => <div id="app" class="global-wrapper">
            <router-view></router-view>
        </div>,
        methods: {
            fetch: config => fetch(config)
        },
        store: storeData,
        router
    };
     
    // let vueLifecycles = {}; // 微前端
     
    // if (env === 'development') {
    //     window.App = new Vue(appOptions).$mount('#app'); // 手动挂载
    // } else {
    //     setPublicPath(publicPath); // 注册应用
    //     store.commit('SAVE_ROUTE_DATA', routes); // 保存路由信息到全局
    //     vueLifecycles = singleSpaVue({
    //         Vue,
    //         appOptions
    //     });
    // }
    tokenHandler();
    guestHandler(); // 对外版处理
    window.App = new Vue(appOptions).$mount('#app'); // 手动挂载

 
    return {};
}
 
// 项目启动的钩子
export default init;
