var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data-wrapper" }, [
    _vm.type === "data"
      ? _c(
          "div",
          { staticClass: "data", class: [_vm.reverse && "layout-reverse"] },
          [
            _c("div", { staticClass: "val" }, [_vm._t("val")], 2),
            _vm._v(" "),
            _c("div", { staticClass: "label" }, [_vm._t("label")], 2)
          ]
        )
      : _vm.type === "img"
      ? _c("div", { staticClass: "pic" }, [
          _c("div", { staticClass: "val" }, [_vm._t("val")], 2),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [_vm._t("label")], 2)
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }