/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-25 15:40:29
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-07-16 10:42:28
 * @Description: 
 * @Route: Route
 */

<style lang='less' scoped>
.digital-carousel {
    width: 96%;
    margin: auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .carousel-wrapper {
        width: 100%;
        height: 570px;
        position: relative;
        .carousel-item {
            position: absolute;
            top: 0;
            left: 0;
            width: 962px;
            height: 551px;
            display: inline-block;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 0;
            transition: transform 0.4s ease-in-out;
            &.is-active {
                opacity: 1;
                z-index: 1;
                background-size: 95%;
                .border-div {
                    opacity: 1;
                }
            }
            .border-div {
                opacity: 0;
            }
            .imgWrapper {
                display: inline-block;
                width: 96%;
                height: 92%;
                margin: 15px 10px 0 10px;
            }
        }
    }
    .carousel-point {
        width: 100%;
        text-align: center;
        .point-item {
            width: 10px;
            height: 10px;
            display: inline-block;
            background: #0091ff;
            border-radius: 50%;
            margin-right: 15px;
            &.is-active {
                background-color: @--color-white;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .tips {
        position: absolute;
        right: 11.5%;
        bottom: 1%;
        color: @--color-white;
        font-size: 14px;
        line-height: 19px;
    }
}

.left-bottom,
.right-top,
.right-bottom,
.bottom-line,
.left-top {
    position: absolute;
    display: inline-block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
}

.left-top {
    left: 0;
    top: 0;
    background-image: url("~images/leftTop.svg");
    background-position: left top;
}

.left-bottom {
    left: 0;
    bottom: 0;
    background-image: url("~images/leftBottom.svg");
    background-position: left bottom;
}

.right-top {
    right: 0;
    top: 0;
    background-image: url("~images/rightTop.svg");
    background-position: right top;
}

.right-bottom {
    right: 0;
    bottom: 0;
    background-image: url("~images/rightBottom.svg");
    background-position: right bottom;
}

.bottom-line {
    right: 50%;
    bottom: 0;
    width: 35px;
    background-image: url("~images/bottom-line.svg");
    transform: translateX(50%);
    background-position: center bottom;
}
</style>

<template>
    <div class="digital-carousel">
        <div ref="carousel"
             class="carousel-wrapper">
            <div v-for="(item, index) in carouselItem"
                 :key="index"
                 ref="carousel-item"
                 class="carousel-item"
                 :class="{
                     'is-active': item.isActive
                 }">
                <div class="left-top border-div" />
                <div class="left-bottom border-div" />
                <div class="right-top border-div" />
                <div class="right-bottom border-div" />
                <div class="bottom-line border-div" />
                <img :src="item.img"
                     class="imgWrapper">
            </div>
        </div>
        <ul class="carousel-point">
            <li v-for="(item, index) in carouselItem"
                :key="index"
                :class="[item.isActive && 'is-active']"
                class="point-item"
                @click.stop="setAcitveIndex(index)" />
        </ul>
        <div class="tips">
            【数据仅供示意】
        </div>
    </div>
</template>

<script>

const SCALE = 0.59;
export default {
    props: {
        interval: {
            type: Number,
            default: 8000
        },
        carouselItem: {
            type: Array,
            default: () => []
        },
        autoPlay: {
            type: Boolean,
            default: true
        },
        value: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            timer: null,
            activeIndex: 0
        };
    },
    watch: {
        activeIndex: 'refreshCarousel',
        autoPlay: 'autoPlayToggle',
        value(index) {
            this.activeIndex = index;
        }
    },
    destroyed() {
        this.removeTimer();
    },
    mounted() {
        if (this.autoPlay && this.carouselItem.length !== 0) {
            this.refreshCarousel(0, 0);
            this.setTimer();
        }
    },
    activated() {
        if (this.carouselItem.length !== 0) {
            this.setTimer();
        }
    },
    deactivated() {
        this.removeTimer();
    },
    methods: {
        // 刷新聚焦点
        refreshCarousel(val, oldVal) {
            this.resetCarouselPoint(oldVal);
            if (val > this.carouselItem.length - 1) {
                this.activeIndex = 0;
                val = 0;
            }
            const carouselList = this.$refs['carousel-item'];
            for (let i = 0; i < carouselList.length; i += 1) {
                const item = carouselList[i];
                const style = this.calcItemTranslate(i, item);
                item.style.transform = style;
            }
            this.$set(this.carouselItem[val], 'isActive', true);
            this.$emit('input', this.activeIndex);
        },
        calcItemTranslate(index, item) {
            const stageClass = 'is-in-stage';
            const COUNT = 8.2; // wrapper分的分数
            const carouselWidth = this.$refs.carousel.offsetWidth;
            let translate = '';
            index = this.rankIndex(index);
            const inStage = this.isInStage(index);
            if (inStage) { // 两翼
                item.classList.add(stageClass);
                if (this.activeIndex === index) {
                    item.style.zIndex = 3;
                } else {
                    item.style.zIndex = 2;
                }
                translate = carouselWidth * ((5.6 - SCALE) * (index - this.activeIndex) + 1) / COUNT;
            } else if (index < this.activeIndex) {
                item.classList.remove(stageClass);
                item.style.zIndex = 1;
                translate = -(11 + SCALE) * carouselWidth / COUNT;
            } else {
                item.classList.remove(stageClass);
                item.style.zIndex = -1;
                translate = (9 + SCALE) * carouselWidth / COUNT + 12;
            }
            if (this.activeIndex !== index) {
                return `translateX(${translate}px) scale(${SCALE})`;
            }
            return `translateX(${translate}px) scale(1)`;
        },
        // 计算真实的排序位置
        rankIndex(index) {
            const len = this.carouselItem.length;
            if (this.activeIndex === 0 && index === len - 1) { // 从最后一页跳转至第一页
                return -1;
            } if (this.activeIndex === len - 1 && index === 0) { // 从第一页跳转至最后一页
                return len;
            } if (index < this.activeIndex - 1 && this.activeIndex - index >= len / 2) { // 在左侧且不在右侧的阶上
                return len + 1;
            } if (index > this.activeIndex + 1 && index - this.activeIndex >= len / 2) { 
                return -2;
            }
            return index;
        },
        setAcitveIndex(index) {
            this.activeIndex = index;
        },
        resetCarouselPoint(index) {
            if (this.carouselItem.length - 1 < index) return;
            this.$set(this.carouselItem[index], 'isActive', false);
        },
        setTimer() {
            if (this.timer !== null) {
                return;
            }
            this.timer = setInterval(() => {
                this.activeIndex += 1;
            }, this.interval);
        },
        removeTimer() {
            clearInterval(this.timer);
            this.timer = null;
        },
        isInStage(index) {
            return Math.round(Math.abs(index - this.activeIndex)) <= 1;
        },
        autoPlayToggle(val) {
            if (val) {
                this.setTimer();
            } else {
                this.removeTimer();
            }
        }
    }
};
</script>
