/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2021-07-05 10:36:18
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-07-13 10:28:34
 * @Description: 混合左右切换代码，只局限使用digitalCarousel组件
 */

import { throttle } from '@/utils/utils';

export default {
    data() {
        return {
            autoPlay: true,
            activeIndex: 0
        };
    },
    mounted() {
        // 添加防抖函数
        const time = 500;
        this.throttleListener = throttle(this.eventListener, time);
        document.addEventListener('keyup', this.throttleListener);
    },
    destroyed() {
        document.removeEventListener('keyup', this.throttleListener);
    },
    methods: {
        eventListener(e) {
            this.autoPlay = false;
            if ([37, 177].includes(e.keyCode)) {
                this.swipeLeft();
            } else if ([39, 176].includes(e.keyCode)) {
                this.swipeRight();
            } else {
                this.autoPlay = true;
            }
        },
        swipeLeft() {
            if (this.activeIndex === 0) {
                this.activeIndex = this.carouselItem.length - 1;
            } else {
                this.activeIndex -= 1;
            }
        },
        swipeRight() {
            this.activeIndex += 1;
            this.activeIndex = this.activeIndex % (this.carouselItem.length);
        }
    }
};
