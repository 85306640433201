var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "border-header" }, [
    _c("div", { staticClass: "title", class: _vm.green && "green" }, [
      _vm._v("\n        " + _vm._s(_vm.title) + "\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "arrow", class: _vm.green && "green" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }