/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-18 11:30:22
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-13 16:16:30
 * @Description: Description
 */

<style lang='less' scoped>
.box-shadow-bottom {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: @--color-white;
    line-height: 22px;
    position: relative;
    height: 26px;
    overflow: hidden;
    margin-bottom: 4px;
    .pane-bottom {
        width: 100%;
        background-image: url("~images/lc@2x.png");
        background-size: 100%;
        background-position: center;
        height: 14px;
        position: absolute;
        left: 0;
        right: 0;
        top: 12px;
    }
}
</style>

<template>
    <div class="box-shadow-bottom">
        <div class="pane-content">
            <slot />
        </div>
        <div class="pane-bottom" />
    </div>
</template>
<script>
export default {
    data() {
        return {

        };
    },
    computed: {
    
    },
    mounted() {
    
    },
    methods: {

    }
};
</script>
