/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-25 14:03:24
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-07-05 10:49:32
 * @Description: 服务项目展示
 * @Route: 
 */


<style lang='less' scoped>
.wrapper {
    padding-top: 30px;
    width: 100%;
}
</style>

<template>
    <full-layout title="服务项目展示">
        <div class="wrapper">
            <digital-carousel v-model="activeIndex"
                              :carousel-item="carouselItem"
                              :auto-play="autoPlay"
                              :interval="4000" />
        </div>
    </full-layout>
</template>

<script>
import fullLayout from '@/components/layout/fullLayout';
import digitalCarousel from '@/components/digital-carousel/index';
import proj1 from '@/images/gov-hx-proj/xm01.jpg';
import proj2 from '@/images/gov-hx-proj/xm02.png';
import proj3 from '@/images/gov-hx-proj/xm03.png';
import proj7 from '@/images/gov-hx-proj/xm07.png';
import proj8 from '@/images/gov-hx-proj/xm08.png';
import proj9 from '@/images/gov-hx-proj/xm09.png';
import proj10 from '@/images/gov-hx-proj/xm10.png';
import activeMixins from '@/components/common/activeMixins';

export default {
    components: {
        fullLayout,
        digitalCarousel
    },
    mixins: [activeMixins],
    data() {
        return {
            carouselItem: [
                {
                    img: proj1,
                    isActive: true
                },
                {
                    img: proj2,
                    isActive: false
                },
                {
                    img: proj3,
                    isActive: false
                },
                {
                    img: proj8,
                    isActive: false
                },
                {
                    img: proj7,
                    isActive: false
                },
                {
                    img: proj9,
                    isActive: false
                },
                {
                    img: proj10,
                    isActive: false
                }
            ]
        };
    }
};
</script>
