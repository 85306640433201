
/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 16:52:10
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-02-23 14:45:08
 * @Description: 工具函数
 * @Route: Route
 */

/*
 * @description 获取地址栏参数
 */
export const getUrlParame = paramName => {
    const urlParams = {};
    const url = window.location.href;
    const idx = url.indexOf('?');
    if (idx === -1) {
        return false;
    }
    let params = url.substring(url.indexOf('?') + 1);
    params = params.split('#');
    params = params[0].split('&');
    params.forEach(item => {
        const param = item.split('=');
        urlParams[param[0]] = decodeURIComponent(param[1]);
    });
    if (paramName) {
        return urlParams[paramName];
    }
    return urlParams;
};

/*
 * @description 获取地址栏hash参数
 */
export const getUrlHash = paramName => {
    const urlParams = {};
    const url = window.location.href;
    const idx = url.indexOf('#');
    if (idx === -1) {
        return false;
    }
    let params = url.substring(url.indexOf('#') + 1);
    params = params.split('&');
    params.forEach(item => {
        const param = item.split('=');
        urlParams[param[0]] = decodeURIComponent(param[1]);
    });
    if (paramName) {
        return urlParams[paramName];
    }
    return urlParams;
};

/*
 * 判断浏览器类型是否为IE
 */
export const isIE = () => {
    const u = window.userAgent || navigator.userAgent;
    return u.indexOf('MSIE') > 0 || u.indexOf('Trident') > 0;
};

/*
 * 判断对象是否为空
 */
export const isEmptyObj = obj => (
    !obj || Object.keys(obj).length === 0
);


/**
 * 日期格式化
 * (new Date(), 'yyyy-mm-dd hh:ii:ss.S')==> 2006-07-02 08:09:04.423
 * (new Date(), 'yyyy-mm-dd E HH:ii:ss') ==> 2009-03-10 二 20:09:04
 * (new Date(), 'yyyy-mm-dd EE hh:ii:ss') ==> 2009-03-10 周二 08:09:04
 * (new Date(), 'yyyy-mm-dd EEE hh:ii:ss') ==> 2009-03-10 星期二 08:09:04
 * (new Date(), 'yyyy-m-d h:m:s.S') ==> 2006-7-2 8:9:4.18
 * (new Date(), 'yyyy/mm/dd hh:ii:ss.S') ==> 2017/04/17 10:00:48.282
*/
export const formatDate = (value, fmt) => {
    if (!value) {
        return null;
    }
    // ie
    let date = value;
    if (String(date).match('-')) {
        date = date.replace('-', '/');
    }
    /* eslint-disable no-param-reassign */
    date = new Date(date);
    /* eslint no-console: 'off' */
    if (date.toString() === 'Invalid Date') {
        console.log('日期不合法');
    }
    const o = {
        'M+': date.getMonth() + 1, // 月份,
        'm+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours() % 24 === 0 ? 0 : date.getHours() % 24, // 小时
        'H+': date.getHours(), // 小时
        'i+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
    };
    const week = {
        0: '/u65e5',
        1: '/u4e00',
        2: '/u4e8c',
        3: '/u4e09',
        4: '/u56db',
        5: '/u4e94',
        6: '/u516d'
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (match, p1) => {
            if (p1.length > 1) {
                return (p1.length > 2 ? '/u661f/u671f' : '/u5468') + week[`${date.getDay()}`];
            }
            return week[`${date.getDay()}`];
        });
    }
    const keys = Object.keys(o);
    for (let k = 0, len = keys.length; k < len; k += 1) {
        const prop = keys[k];
        if (new RegExp(`(${prop})`).test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ?
                (o[prop]) : ((`00${o[prop]}`).substr((`${o[prop]}`).length)));
        }
    }
    /* eslint-disable consistent-return */
    return fmt;
};

/**
 * 判断是否为日期
 * @param {*} date 要判断是否为日期的值
 */
export const isDate = date => {
    if (date === null || date === undefined) return false;
    if (Number.isNaN(new Date(date).getTime())) return false;
    if (Array.isArray(date)) return false; // deal with `new Date([ new Date() ]) -> new Date()`
    return true;
};

/**
 * 获取指定时间前几个月或几年的时间戳
 * @param {timestamp} time 目标时间
 * @param {Object} obj 时间区间跨度
 * @param {String || Number} obj.range 时间区间
 * @param {String} obj.dimension 时间维度：年 || 月
 */
export const getHistoryRangeTimestamp = (time, obj = {}) => {
    if (!isDate(time)) return;
    const { range = 1, dimension = 'year' } = obj;
    const year = new Date(time).getFullYear();
    const month = new Date(time).getMonth() + 1;
    const afterMonth = month - 6;
    let yearR = year - range;
    let monthR = month;
    if (dimension === 'month') {
        yearR = afterMonth < 1 ? year - 1 : year;
        monthR = afterMonth < 1 ? month + 6 : month - 6;
    }
    return new Date(`${yearR}-${monthR}`).getTime();
};

/*
 * 下载文件
 */
export const downloadFile = url => {
    const iframeEle = document.createElement('iframe');
    iframeEle.src = url;
    iframeEle.style.display = 'none';
    document.body.appendChild(iframeEle);
};

/**
 * 文件流导出
 */
export const downloadBlob = (res, filename) => {
    const blob = new Blob([res], {
        type: 'application/vnd.ms-excel'
    });
    const oA = document.createElement('a');
    oA.href = URL.createObjectURL(blob);
    oA.download = filename;
    oA.click();
};

/*
 * 深度遍历获取目标
 * param {Array} source 源数据
 * param {String} target 目标值
 */
export const findTargetByDeepTraverse = (source, target, result = []) => {
    const localSource = JSON.parse(JSON.stringify(source));
    localSource.forEach(item => {
        if (item.name.indexOf(target) !== -1) {
            result.push(item);
        } else if (item.children && item.children.length) {
            findTargetByDeepTraverse(item.children, target, result);
        }
    });
    return result;
};

/*
 * 区分监管端项目内耗材和药品
 * param {String} path 页面路径
 */
export const findProjectType = path => {
    const drugReg = /\/drug\//;
    const conReg = /\/consumable\//;
    let urlStr = '';
    if (drugReg.test(path)) {
        urlStr = 'drug';
    }
    if (conReg.test(path)) {
        urlStr = 'consumable';
    }
    return urlStr;
};

/**
 * 倒计时
 * @param {String} target 目标时间
 */
export const countDown = target => {
    const oDate = new Date();// 获取日期对象
    const oldTime = oDate.getTime();// 现在距离1970年的毫秒数
    const newDate = new Date(target);
    const newTime = newDate.getTime();// 2019年距离1970年的毫秒数
    if (newTime >= oldTime) {
        let second = Math.floor((newTime - oldTime) / 1000);// 未来时间距离现在的秒数
        const day = Math.floor(second / 86400);// 整数部分代表的是天；一天有24*60*60=86400秒 ；
        second %= 86400;// 余数代表剩下的秒数；
        const hour = Math.floor(second / 3600);// 整数部分代表小时；
        second %= 3600; // 余数代表 剩下的秒数；
        const minute = Math.floor(second / 60);
        second %= 60;
        return {
            day,
            hour,
            minute,
            second
        };
    }
    return false;
};

/**
 * 对象深度拷贝
 * @param {Object} obj 拷贝对象
 */
export const deepCopy = obj => (function deepcopy(oldObj) {
    // 定义一个新的空对象
    let newObject = {};
    // 判断原对象是否存在
    if (oldObj) {
        // 判断原对象的类型
        if (oldObj.constructor === Object) {
            newObject = new oldObj.constructor();
        } else {
            newObject = new oldObj.constructor(oldObj.valueOf());
        }
        // 遍历克隆原对象的属性
        Object.keys(oldObj).forEach(key => {
            if (newObject[key] !== oldObj[key]) {
                if (typeof (oldObj[key]) === 'object') {
                    // 对象内部的子对象
                    newObject[key] = deepcopy(oldObj[key]);
                } else {
                    newObject[key] = oldObj[key];
                }
            }
        });
        // 克隆原对象的常用方法
        // newObject.toString = oldObj.toString;
        // newObject.valueOf = oldObj.valueOf;
        return newObject;
    }
}(obj));

/**
 * 对象深度合并
 * @param {Object} initObj 原始对象
 * @param {Object} targetObj 合并对象
 */
export const deepMergeObj = (initObj, targetObj) => {
    const resultObj = initObj;
    const keys = Object.keys(targetObj);
    keys.forEach(key => {
        resultObj[key] = initObj[key] && initObj[key].toString() === '[object Object]' ? deepMergeObj(initObj[key], targetObj[key]) : targetObj[key];
    });
    return resultObj;
};

export const dataConvert = convert => {
    if (typeof convert === 'string') {
        convert = parseFloat(convert);
    }
    if (convert >= 10000 && convert < 10000 * 10000) {
        convert /= 10000;
        convert = `${convert.toFixed(2)}万`;
    } else if (convert >= 10000 * 10000) {
        convert /= (10000 * 10000);
        convert = `${convert.toFixed(2)}亿`;
    }
    return convert;
};

/**
 * 统一处理数据字典
 * @param { Ojbect } res 后端返回数据
 */
export const handleSysDic = res => {
    const { data } = res;
    let list = [];
    if (Array.isArray(data)) {
        list = data.map(item => {
            const obj = {
                label: item.value,
                value: item.label
            };
            return obj;
        });
    }
    return list;
};

/**
 * 字符串转换位数字
 * @param { String } str
 */
export const string2num = str => (Number.isNaN(parseFloat(str)) === true ? 0 : parseFloat(str));

/**
 * 列表本页合计数据
 * @param { Array } records 后端返回数据
 * @param { Array } totalData 本页合计需要字段 eg:[{name:'',unit:''}]
*/
export const getPageTotal = (records, totalData) => {
    const totalItem = {};
    for (let t = 0; t < totalData.length; t += 1) {
        const { name, unit = '' } = totalData[t];
        totalItem[name] = 0;
        for (let i = 0; i < records.length; i += 1) {
            const recordItem = records[i];
            totalItem[name] += string2num(recordItem[name]);
        }
        totalItem[name] = totalItem[name].toFixed(4);
        totalItem[name] += unit;
    }
    return totalItem;
};
/**
 * 统一处理数据字典-交易结算
 * @param { Ojbect } res 后端返回数据
 */
export const handleSysDicTrade = res => {
    const { data } = res;
    let list = [];
    if (Array.isArray(data)) {
        list = data.map(item => {
            const obj = {
                label: item.desc,
                value: item.value
            };
            return obj;
        });
    }
    return list;
};

/**
 * 判断是否为二维数组
 * @param {} row 
 * @param {*} column 
 * @param {*} cellValue 
 */
export const isDimensArray = arr => {
    if (!Array.isArray(arr)) {
        return false;
    }
    return arr.every(item => Array.isArray(item));    
};

export function throttle(func, wait) {
    let previous = 0;
    return function inner(...args) {
        const now = Date.now();
        const context = this;
        if (now - previous > wait) {
            func.apply(context, args);
            previous = now;
        }
    };
}

/*
 * @description 每三位数字添加逗号
 */
export const toThousands = val => {
    let num = (val || 0).toString();
    let result = '';
    let decimal = '';
    if (num.indexOf('.') !== -1) {
        decimal = num.slice(num.indexOf('.') - num.length);
        num = num.slice(0, num.indexOf('.'));
    }
    while (num.length > 3) {
        result = `,${num.slice(-3)}${result}`;
        num = num.slice(0, num.length - 3);
    }
    if (num) {
        result = num + result;
    }
    return result + decimal;
};
