/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-16 11:09:55
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-16 16:36:49
 * @Description: 上下布局的数据单元
 */
<style lang='less' scoped>
.data-wrapper {
    display: inline-block;
    width: 100%;
    .data,
    .pic {
        display: flex;
        flex-direction: column;
        &.layout-reverse {
            flex-direction: column-reverse;
        }
        & > .val {
            text-align: center;
            text-overflow: ellipsis;
        }
        & > .label {
            text-align: center;
            font-size: 12px;
            color: @--color-white;
            line-height: 16px;
        }
    }
    .data {
        & > .val {
            color: #f7b500;
        }
    }
}
</style>

<template>
    <div class="data-wrapper">
        <div v-if="type === 'data'"
             :class="[reverse && 'layout-reverse']"
             class="data">
            <div class="val">
                <slot name="val" />
            </div>
            <div class="label">
                <slot name="label" />
            </div>
        </div>
        <div v-else-if="type === 'img'"
             class="pic">
            <div class="val">
                <slot name="val" />
            </div>
            <div class="label">
                <slot name="label" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'data'
        },
        reverse: {
            type: Boolean,
            default: false
        }
    }
};
</script>
