/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-25 10:23:23
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-05-12 12:53:46
 * @Description: Description
 * @Route: Route
 */
 
<style lang='less' scoped>
.content {
    padding-top: 32px;
    .first-row {
        margin: 0 33px 0 25px;
        .borderWrapper {
            height: 359px;
            padding-top: 24px;
        }
        .el-col {
            &:nth-child(1) {
                .img {
                    background-image: url("~images/leader-about/gh_pic01.png");
                }
            }
            &:nth-child(2) {
                .img {
                    background-image: url("~images/leader-about/gh_pic02.png");
                }
            }
            &:nth-child(3) {
                .img {
                    background-image: url("~images/leader-about/gh_pic03.png");
                }
            }
            &:nth-child(4) {
                .img {
                    background-image: url("~images/leader-about/gh_pic04.png");
                }
            }
        }
        .img {
            // background-image: url("~images/leader-about/gh_pic01.png");
            display: inline-block;
            width: 262px;
            height: 163px;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
        }
        .desc {
            font-size: 14px;
            text-align: left;
            color: @--color-white;
            line-height: 22px;
            margin-top: 14px;
        }
    }
    .second-row {
        margin: 18px 26px 0 21px;
        .detailWrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .desc {
                font-size: 14px;
                text-align: left;
                color: @--color-white;
                line-height: 22px;
                display: inline-block;
                width: 186px;
                height: 127px;
            }
            .img {
                width: 182px;
                height: 140px;
                display: inline-block;
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .el-col {
            &:nth-child(1) {
                .img {
                    background-image: url("~images/leader-about/gh_pic05.png");
                }
            }
            &:nth-child(2) {
                .img {
                    background-image: url("~images/leader-about/gh_pic06.png");
                }
            }
            &:nth-child(3) {
                .img {
                    background-image: url("~images/leader-about/gh_pic07.png");
                }
            }
        }
    }
}
</style>

<template>
    <full-layout title="领导关怀">
        <div class="content">
            <div class="first-row">
                <el-row :gutter="16">
                    <el-col :span="6">
                        <border>
                            <data-cell type="img">
                                <div slot="val"
                                     class="img" />
                                <div slot="label"
                                     class="desc">
                                    2016年10月，时任全国政协副主席、九三学社中央主席韩启德主持召开由九三学社中央办公厅主办的“医保管理体制改革”座谈会。我司作为唯一受邀企业参会并汇报进展，运营模式和服务价值得到主席高度肯定。
                                </div>
                            </data-cell>
                        </border>
                    </el-col>
                    <el-col :span="6">
                        <border>
                            <data-cell type="img">
                                <div slot="val"
                                     class="img" />
                                <div slot="label"
                                     class="desc">
                                    2017年8月，时任国务院副总理刘延东赴闽考察医改工作，观看了我司承建的“福建省药械联合限价阳光采购平台”、“三明联盟药械联合限价采购平台”的系统演示，对平台和医改成效予以肯定。
                                </div>
                            </data-cell>
                        </border>
                    </el-col>
                    <el-col :span="6">
                        <border>
                            <data-cell type="img">
                                <div slot="val"
                                     class="img" />
                                <div slot="label"
                                     class="desc">
                                    2018年6月，国家医疗保障局局长胡静林选择三明作为首访地，深入调研后表示，福建省和三明市的改革实践有力促进了医疗、医保、医药“三医联动”，为深化医药卫生体制改革、为各地推进医保工作提供了有益借鉴。
                                </div>
                            </data-cell>
                        </border>
                    </el-col>
                    <el-col :span="6">
                        <border>
                            <data-cell type="img">
                                <div slot="val"
                                     class="img" />
                                <div slot="label"
                                     class="desc">
                                    2018年10月和2019年8月，中共中央政治局委员、国务院副总理孙春兰两度赴福建调研，期间观看了医药平台演示。她充分肯定三明医改的示范作用和福建医改的显著成效，强调要深入总结推广三明医改经验。 
                                </div>
                            </data-cell>
                        </border>
                    </el-col>
                </el-row>
            </div>
            <div class="second-row">
                <el-row :gutter="16">
                    <el-col :span="8">
                        <border>
                            <div class="detailWrapper">
                                <div class="desc">
                                    2019年11月15日，国务院医改领导小组印发国医改发〔2019〕2号文，明确充分发挥福建省和三明市医改经验的示范、突破、带动作用。
                                </div>
                                <div class="img" />
                            </div>
                        </border>
                    </el-col>
                    <el-col :span="8">
                        <border>
                            <div class="detailWrapper">
                                <div class="desc">
                                    2019年11月29日，国务院医改领导小组印发国医改发〔2019〕3号文，要求建设全国统一开放的药品公共采购市场，统一编码、标准和功能规范。
                                </div>
                                <div class="img" />
                            </div>
                        </border>
                    </el-col>
                    <el-col :span="8">
                        <border>
                            <div class="detailWrapper">
                                <div class="desc">
                                    2020年3月5日，中共中央国务院正式发布《关于深化医疗保障制度改革的意见》，强调要提高“三医联动”改革的协同性，全面实行药品和医用耗材集中采购。
                                </div>
                                <div class="img" />
                            </div>
                        </border>
                    </el-col>
                </el-row>
            </div>
        </div>
    </full-layout>
</template>

<script>
import fullLayout from '@/components/layout/fullLayout';
import border from '@/components/border';
import dataCell from '@/components/data-cell';

export default {
    components: {
        fullLayout,
        border,
        dataCell
    },
    data() {
        return {

        };
    }
};
</script>
