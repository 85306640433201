var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-screen" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "wrapper" }, [
            _c("div", { staticClass: "top-left" }),
            _vm._v(" "),
            _c("div", { staticClass: "top-center title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.title) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "top-right" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "arrowD" })
        ]),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }