/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-16 10:03:11
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-04-21 18:10:27
 * @Description: 企业端-首屏
 */

<style lang='less' scoped>
.container {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    box-sizing: content-box;
    .left-content {
        .plat-scale {
            .plat-scale-val {
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                margin-bottom: 3px;
            }
            /deep/ .el-col {
                border-right: 1px solid rgba(0, 121, 245, 0.65);
                &:last-child {
                    border-right: 0;
                }
            }
            /deep/ .el-row:first-child {
                margin-bottom: 27px;
            }
            /deep/ .el-row:last-child {
                margin-bottom: 20px;
            }
        }
        .bussiness-module {
            /deep/ .title {
                margin-bottom: 0;
            }
            /deep/.data-wrapper {
                img {
                    width: 75px;
                    height: 65px;
                    margin-bottom: 6px;
                    margin-top: 21px;
                }
            }
            /deep/ .el-row {
                .el-col:nth-child(4) {
                    position: relative;
                    right: -20px;
                }
                .el-col:nth-child(5) {
                    position: relative;
                    left: -25px;
                }
            }
        }
        .cooperate-bank {
            /deep/ .content {
                height: 150px;
                width: 291px;
            }
        }
    }
    .middle-content {
        padding: 25px 48px 0 48px;
        height: 100%;
        background: url("https://bucket-font.oss-cn-beijing.aliyuncs.com/img/datascreen/zf_map.png") no-repeat center / 605px 495px;
        background-position: 50% 50%;
        position: relative;
        .about {
            .about-val {
                font-weight: 700;
                font-size: 21px;
                color: #f4a607;
                line-height: 24px;
                margin-bottom: 8px;
            }
            .sevenDataAmount,
            .total-amount-list {
                border: 1px solid rgba(0, 145, 255, 0.5);
                box-shadow: 0 0 21px 0 #0b2468 inset;
                text-align: center;
                padding: 21px 0 17px 0;
            }
            .sevenDataAmount {
                padding-top: 28px;
            }
            .total-amount-list {
                /deep/ .el-col {
                    border-left: 1px solid rgba(0, 145, 255, 0.5);
                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }
        .detail {
            position: absolute;
            bottom: 80px;
            // padding-left: 53px;
            left: 24px;
            right: 16px;
            color: @--color-white;
            & > .title {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 14px;
            }
            .total-bar {
                height: 50px;
            }
            .swiper-slide-active {
                .data-cell {
                    font-weight: 700;
                }
            }
            .data-cell {
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 12px;
                text-align: left;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding-right: 20px;
                .dot {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    background: url("~images/dot2.png") no-repeat center / 14px 14px;
                    margin-right: 7px;
                    vertical-align: middle;
                }
            }
        }
    }
    .right-content {
        .hx-hightLights {
            .hightLights-list {
                .item {
                    padding: 5px 11px;
                    margin-bottom: 4px;
                    font-size: 14px;
                    text-align: left;
                    color: @--color-white;
                    line-height: 19px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:nth-child(even) {
                        background: rgba(0, 145, 255, 0.2);
                    }
                    &:nth-child(odd) {
                        background: rgba(0, 145, 255, 0.4);
                    }
                    .dot {
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        margin-right: 4px;
                        background-image: url("~images/dot.svg");
                        background-position: center;
                        background-size: 10px;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
        .data-analysis {
            .img {
                width: 37px;
                height: 37px;
                background-size: 37px;
                margin-bottom: 8px;
                display: inline-block;
            }
        }
        .prod-enp-sale {
            /deep/ .content {
                height: 127px;
                width: 291px;
            }
        }
    }
    .scroll-board {
        /deep/ .ceil {
            &:last-child {
                padding-right: 0;
                width: 26% !important;
            }
            &:first-child {
                padding-left: 0;
                width: 75% !important;
            }
        }
    }
}
</style>

<template>
    <div class="container">
        <swing-layout :container-style="{ backgroundColor: '#03071E', backgroundImage: `url(${bg})` }"
                      title="厦门海西医药交易中心药械交易平台">
            <div slot="left"
                 class="left-content">
                <border title="平台覆盖规模"
                        class="plat-scale">
                    <el-row>
                        <el-col :span="12">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    22省的105市
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    覆盖地区
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="12">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    45476.9万
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    覆盖人口
                                </div>
                            </data-cell>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    <!-- {{ orgNum.prodNum }}家 -->
                                    12681家
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    生产企业
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="8">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    <!-- {{ orgNum.delvNum }}家 -->
                                    5293家
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    配送企业
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="8">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    <!-- {{ orgNum.insNum }}家 -->
                                    11302家
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    医疗机构
                                </div>
                            </data-cell>
                        </el-col>
                    </el-row>
                </border>
                <border title="业务模块"
                        type="img"
                        class="bussiness-module">
                    <el-row>
                        <el-col :span="8">
                            <data-cell>
                                <img slot="val"
                                     src="@/images/incre-ser.png">
                                <div slot="label"
                                     class="plat-scale-label">
                                    尊享增值服务
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="8">
                            <data-cell>
                                <img slot="val"
                                     src="@/images/bank-bus.png">
                                <div slot="label"
                                     class="plat-scale-label">
                                    银行业务服务
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="8">
                            <data-cell>
                                <img slot="val"
                                     src="@/images/ca-service.png">
                                <div slot="label"
                                     class="plat-scale-label">
                                    CA服务
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="12">
                            <data-cell>
                                <img slot="val"
                                     src="@/images/data-share.png">
                                <div slot="label"
                                     class="plat-scale-label">
                                    资料申报共享服务
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="12">
                            <data-cell>
                                <img slot="val"
                                     src="@/images/sup-service.png">
                                <div slot="label"
                                     class="plat-scale-label">
                                    决策支持服务
                                </div>
                            </data-cell>
                        </el-col>
                    </el-row>
                </border>
                <border title="合作银行开户数TOP10"
                        sub-title="企业单位：户"
                        class="scroll-board cooperate-bank">
                    <dv-scroll-board :config="backOpenAmt" />
                </border>
            </div>
            <div slot="middle"
                 class="middle-content">
                <el-row class="about"
                        :gutter="20">
                    <el-col
                        :span="6">
                        <data-cell class="sevenDataAmount">
                            <div slot="val"
                                 class="about-val">
                                {{ stat.orderAmtWeek }}
                            </div>
                            <div slot="label"
                                 class="seven-label">
                                近7日订单金额 (亿元)
                            </div>
                        </data-cell>
                    </el-col>
                    <el-col :span="18">
                        <el-row class="total-amount-list">
                            <el-col
                                :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="about-val">
                                        {{ stat.orderAmt }}
                                    </div>
                                    <div slot="label"
                                         class="seven-label">
                                        累计订单额 (亿元)
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col
                                :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="about-val">
                                        {{ stat.setlAmt }}
                                    </div>
                                    <div slot="label"
                                         class="seven-label">
                                        累计结算额 (亿元)
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col
                                :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="about-val">
                                        {{ stat.orderCnt }}
                                    </div>
                                    <div slot="label"
                                         class="seven-label">
                                        累计订单数 (笔)
                                    </div>
                                </data-cell>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <div v-if="statArea.length !==0"
                     class="detail">
                    <div class="title">
                        累计订单额
                    </div>
                    <swiper :options="resultSwiper"
                            class="total-bar">
                        <swiper-slide v-for="(statItem, index) in mapAmtFormatter"
                                      :key="'stat-' + index">
                            <el-row>
                                <el-col v-for="(item, i) in statItem"
                                        :key="'statItem-' + i"
                                        :span="24 / statItem.length">
                                    <div class="data-cell">
                                        <span class="label">
                                            <span class="dot" />{{ item.areaName }}：
                                        </span>
                                        <span class="value">{{ item.orderAmt }}亿元</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div slot="right"
                 class="right-content">
                <border title="平台亮点"
                        class="hx-hightLights">
                    <ul class="hightLights-list">
                        <li class="item">
                            <span class="dot" />
                            “三医联动”开路先锋
                        </li>
                        <li class="item">
                            <span class="dot" />
                            国家药械采购平台承建者
                        </li>
                        <li class="item">
                            <span class="dot" />
                            国家药品集采力行者
                        </li>
                        <li class="item">
                            <span class="dot" />
                            跨区域联采专业服务商
                        </li>
                    </ul>
                </border>
                <border title="数据智能分析"
                        class="data-analysis">
                    <el-row>
                        <el-col v-for="(item, index) in dataAnalysis"
                                :key="index"
                                :span="6">
                            <data-cell type="img">
                                <div slot="val"
                                     class="img"
                                     :style="{backgroundImage: `url(${item.image})`}" />
                                <div slot="label">
                                    {{ item.label }}
                                </div>
                            </data-cell>
                        </el-col>
                    </el-row>
                </border>
                <border title="生产企业销售金额TOP20"
                        sub-title="单位：万元"
                        class="scroll-board prod-enp-sale">
                    <dv-scroll-board :config="prodEnpSale" />
                </border>
                <border title="医疗机构采购金额TOP20"
                        sub-title="单位：万元"
                        class="scroll-board prod-enp-sale">
                    <dv-scroll-board :config="medEnpSale" />
                </border>
            </div>
        </swing-layout>
    </div>
</template>
<script>

import SwingLayout from '@/components/layout/swingLayout';
import border from '@/components/border';
import priceAnalysis from '@/images/price.svg';
import warningExp from '@/images/warning.svg';
import settleSup from '@/images/settle.svg';
import marketAnalysis from '@/images/market.png';
import numTop from '@/images/num_top.png';
import numDef from '@/images/num_def.png';
import bg from '@/images/bg.jpg';
import dataCell from './components/data-cell';

const numTopDom = index => (`<span class="dotIndex" style="background-image:url(${numTop});">${index}</span>`);
const numDefDom = index => (`<span class="dotIndex" style="background-image:url(${numDef});">${index}</span>`);


export default {
    components: {
        SwingLayout,
        border,
        dataCell
    },
    data() {
        return {
            bg,
            stat: {
                orderAmt: '',
                orderAmtWeek: '',
                orderCnt: '',
                setlAmt: ''
            }, // 总计
            prodAmtTops: [],
            insAmtTops: [],
            bankAmtTops: [
                {
                    orgName: '中国建设银行',
                    orderAmt: '217'
                },
                {
                    orgName: '中国工商银行',
                    orderAmt: '95'
                },
                {
                    orgName: '中国农业银行',
                    orderAmt: '72'
                },
                {
                    orgName: '中国银行',
                    orderAmt: '66'
                },
                {
                    orgName: '兴业银行',
                    orderAmt: '57'
                },
                {
                    orgName: '交通银行',
                    orderAmt: '39'
                },
                {
                    orgName: '农村商业银行',
                    orderAmt: '17'
                },
                {
                    orgName: '中国民生银行',
                    orderAmt: '17'
                },
                {
                    orgName: '招商银行',
                    orderAmt: '17'
                },
                {
                    orgName: '河北银行',
                    orderAmt: '12'
                }
            ],
            dataAnalysis: [
                {
                    image: priceAnalysis,
                    label: '价格监测'
                },
                {
                    image: warningExp,
                    label: '异常预警'
                },
                {
                    image: settleSup,
                    label: '结算监管'
                },
                {
                    image: marketAnalysis,
                    label: '市场分析'
                }
            ],
            resultSwiper: {
                loop: true,
                direction: 'vertical',
                autoplay: true,
                slidesPerView: 2
            },
            statArea: [], // 地图区域金额
            orgNum: {}
        };
    },
    computed: {
        mapAmtFormatter() {
            const data = [];
            let temp = [];
            this.statArea.forEach((item, index) => {
                temp.push(item);
                if ((index + 1) % 3 === 0) {
                    data.push(temp);
                    temp = [];
                }
            });
            if (temp.length !== 0) {
                data.push(temp);
            }
            return data;
        },
        prodEnpSale() {
            const data = [];
            this.prodAmtTops.forEach((item, index) => {
                const temp = [];
                if (item.orgName && item.orderAmt) {
                    if (index < 3) {
                        temp.push(numTopDom(index + 1) + item.orgName);
                    } else {
                        temp.push(numDefDom(index + 1) + item.orgName);
                    }
                    temp.push(item.orderAmt);
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 4,
                columnWidth: [180, 95],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        medEnpSale() {
            const data = [];
            this.insAmtTops.forEach((item, index) => {
                const temp = [];
                if (item.orgName && item.orderAmt) {
                    if (index < 3) {
                        temp.push(numTopDom(index + 1) + item.orgName);
                    } else {
                        temp.push(numDefDom(index + 1) + item.orgName);
                    }
                    temp.push(item.orderAmt);
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 4,
                columnWidth: [180, 95],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        backOpenAmt() {
            const data = [];
            this.bankAmtTops.forEach((item, index) => {
                const temp = [];
                if (item.orgName && item.orderAmt) {
                    if (index < 3) {
                        temp.push(numTopDom(index + 1) + item.orgName);
                    } else {
                        temp.push(numDefDom(index + 1) + item.orgName);
                    }
                    temp.push(Number(item.orderAmt) * 3);
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 5,
                // columnWidth: [, 80],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        }
    },
    mounted() {
        this.fetchData();
    },
    activated() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$fetch({
                url: '/dap/goodsAmtStat/getStatInfo'
            }).then(res => {
                if (res.code === 0) {
                    const { insAmtTops, orgNum, prodAmtTops, stat, statArea } = res.data;
                    this.insAmtTops = insAmtTops;
                    this.orgNum = orgNum;
                    this.prodAmtTops = prodAmtTops;
                    this.stat = stat;
                    this.statArea = statArea;
                }
            });
        }
    }
};
</script>
