export default {
    baseUrl: 'https://api.bqex.com',
    // wedataOrigin: 'http://192.168.94.22:9000',
    // 服务集合
    // baseOss: {
    //     '/base': 'http://hxbase.heisea.cn/'
    // },
    // 上传
    uploadPath: 'http://hxbase.heisea.cn/upload'
};
