/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-24 10:12:47
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-07-16 17:21:08
 * @Description: Description
 * @Route: Route
 */
<style lang='less' scoped>
pre {
    font-family: "Microsoft YaHei", "微软雅黑", Arial, sans-serif, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB";
}
.container {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    box-sizing: content-box;
    .left-content {
        .union-aim {
            .content {
                width: 93%;
                font-size: 12px;
                text-align: left;
                color: @--color-white;
                line-height: 18px;
                padding-top: 10px;
                padding-bottom: 13px;
            }
        }
        .sm-event {
            .content {
                width: 95%;
                margin: auto;
            }
            .plan-list {
                font-size: 12px;
                text-align: left;
                color: @--color-white;
                line-height: 18px;
                position: relative;
                margin-top: 8px;
                margin-bottom: 10px;
                .item {
                    padding: 6px 0 0 11px;
                    position: relative;
                    .dot {
                        display: inline-block;
                        width: 11px;
                        height: 11px;
                        position: absolute;
                        left: -5px;
                        top: 9px;
                        background-position: center;
                        background-size: 11px;
                        border-radius: 50%;
                        // transform: translateY(-50%);
                        background-image: url("~images/dot-double2.png");
                    }
                }
                .line {
                    display: inline-block;
                    position: absolute;
                    left: 0.4px;
                    top: 18px;
                    bottom: 0;
                    width: 1px;
                    background-color: rgba(4, 239, 239, 0.65);
                }
                .arrow {
                    width: 6px;
                    height: 6px;
                    display: inline-block;
                    background-image: url("~images/arrow-green.png");
                    background-size: 6px;
                    background-position: center;
                    background-repeat: no-repeat;
                    position: absolute;
                    bottom: -2px;
                    left: -2.5px;
                }
            }
        }
    }
    .middle-content {
        margin: 11px 16px 18px 16px;
        padding-top: 30px;
        height: 86%;
        width: 95%;
        background: url("~images/sm-map.png") no-repeat center;
        background-size: 100% 80%;
        background-position: center bottom;
        position: relative;
        /deep/ .solid-border {
            width: 93%;
            margin: 0 10px;
            border-top: 1px solid #108ee9;
            border-bottom: 1px solid #108ee9;
        }
        .sm-title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            .logo {
                background-image: url("~images/sm-union.png");
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                display: inline-block;
                width: 79px;
                height: 25px;
                margin-right: 37px;
            }
            .desc {
                font-size: 16px;
                font-weight: 700;
                text-align: left;
                color: @--color-white;
                line-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .left-top,
                .right-bottom {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background-image: url("~images/smlefttop.png");
                    position: absolute;
                }
                .left-top {
                    left: -14px;
                    top: -4px;
                }
                .right-bottom {
                    right: -14px;
                    bottom: -4px;
                    transform: rotate(-180deg);
                }
            }
            .dot {
                display: inline-block;
                width: 5px;
                height: 5px;
                margin: 0 7px 0 10px;
                line-height: 18px;
                vertical-align: middle;
                border-radius: 50%;
                background-color: @--color-white;
            }
        }
        .sm-digital {
            display: inline-block;
            width: 190px;
            margin-top: 50px;
            .digital-item {
                padding: 13px 0 10px 0;
                width: 100%;
                background-image: url("~images/border-wrapper.png");
                background-repeat: no-repeat;
                background-size: 90% 100%;
                background-position: center;
                margin-bottom: 80px;
                .val {
                    font-size: 20px;
                    font-weight: 700;
                    text-align: center;
                    color: #f7b500;
                    line-height: 24px;
                    .unit {
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
                .label {
                    font-size: 12px;
                    text-align: center;
                    color: @--color-white;
                    line-height: 16px;
                }
            }
        }
    }
    .right-content {
        .join-union {
            margin-bottom: 18px;
            .content {
                width: 93%;
                font-size: 12px;
                text-align: left;
                color: @--color-white;
                line-height: 18px;
                padding-top: 19px;
            }
        }
        .union-condition {
            .content {
                width: 100%;
                margin: auto;
                font-size: 12px;
                text-align: left;
                color: @--color-white;
                line-height: 18px;
            }
            .list {
                font-size: 12px;
                text-align: left;
                color: @--color-white;
                line-height: 16px;
                .item {
                    padding: 8px 0 4px 0;
                    .dot {
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        margin-right: 4px;
                        margin-top: 3px;
                        background-image: url("~images/dot-double.png");
                        background-size: 10px;
                        background-position: center;
                        vertical-align: top;
                        line-height: 16px;
                    }
                    .detail {
                        width: 90%;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .footer-part {
        margin: 0 14px 18px 14px;
        padding: 15px;
        padding-bottom: 0;
        box-sizing: border-box;
        width: 98%;
        height: 165px;
        overflow: hidden;
        // position: relative;
        &.borderWrapper {
            border-radius: 0.5rem;
        }
        /deep/ .content {
            // position: absolute;
            // width: 100%;
            // height: 100%;
            // left: 0;
            // top: 0;
            // bottom: 0;
            // right: 0;
        }
        .areaList-wrapper {
            height: 146px;
        }
        .areaList {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            .pic-cell {
                display: inline-block;
                margin-right: 16px;
                margin-bottom: 13px;
                width: 128px;
                height: 60px;
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>

<template>
    <div class="container">
        <swing-layout title="药品耗材联合限价采购三明联盟"
                      :container-style="{ backgroundColor: '#03071E', backgroundImage: `url(${bg})` }">
            <div slot="left"
                 class="left-content">
                <sm-border class="union-aim"
                           title="联盟宗旨">
                    <div class="content">
                        <pre>    争取以量换价，降低药品耗材虚高价格，规范药品
耗材的采购与使用行为，堵浪费，调结构，促进联盟成
员地区“三医联动”医药卫生体制综合改革见成效。</pre>
                    </div>
                </sm-border>
                <sm-border green
                           title="三明联盟大事记"
                           class="sm-event">
                    <div class="content">
                        <ul class="plan-list">
                            <div class="line" />
                            <div class="arrow" />
                            <li class="item">
                                <span class="dot" />
                                2015年起，国务院办公厅多次发文要求总结、完善和推广三明医改经验
                            </li>
                            <li class="item">
                                <span class="dot" />
                                2016年3月，三明联盟正式成立，公司成为联盟指定平台服务商
                            </li>
                            <li class="item">
                                <span class="dot" />
                                2016年7月，河北省6市28县入盟
                            </li>
                            <li class="item">
                                <span class="dot" />
                                2018年，公司助力贵州省铜仁市、内蒙古兴安盟上线平台
                            </li>
                            <li class="item">
                                <span class="dot" />
                                2019年，公司助力河北省邯郸市、新疆兵团第八师石河子市上线平台
                            </li>
                            <li class="item">
                                <span class="dot" />
                                2020年4月，公司助力联盟成功完成全国首次跨区域非一致性评价药品带量采购
                            </li>
                            <li class="item">
                                <span class="dot" />
                                2012年至2020年，三明市8年共相对节约药品耗材费用约124.08亿元
                            </li>
                        </ul>
                    </div>           
                </sm-border>
            </div>
            <sm-border slot="middle"
                       class="middle-content">
                <solid-border>
                    <div class="sm-title">
                        <div class="logo" />
                        <div class="desc">
                            平台共享<span class="dot" />
                            目录共享<span class="dot" />
                            价格共享<span class="dot" />
                            资源共享
                            <div class="left-top" />
                            <div class="right-bottom" />
                        </div>
                    </div>
                </solid-border>
                <div class="sm-digital">
                    <div class="digital-item">
                        <data-cell>
                            <div slot="val"
                                 class="val">
                                16<span class="unit">省</span>27<span class="unit">市</span>4<span class="unit">县</span>
                            </div>
                            <div slot="label"
                                 class="label">
                                覆盖地区
                            </div>
                        </data-cell>
                    </div>
                    <div class="digital-item">
                        <data-cell>
                            <div slot="val"
                                 class="val">
                                15116<span class="unit">万</span>
                            </div>
                            <div slot="label"
                                 class="label">
                                覆盖人口
                            </div>
                        </data-cell>
                    </div>
                </div>
            </sm-border>
            <div slot="right"
                 class="right-content">
                <sm-border class="union-condition"
                           title="入盟条件">
                    <div class="content">
                        <ul class="list">
                            <li class="item">
                                <span class="dot" />
                                <span class="detail">本着“自愿、互惠互利”原则</span>
                            </li>
                            <li class="item">
                                <span class="dot" />
                                <span class="detail">一般分为设区市以上单位（已在联盟中的县级成员保留）</span>
                            </li>
                            <li class="item">
                                <span class="dot" />
                                <span class="detail">领导重视，有推动“三医联动”医药卫生体制改革的信心和决心，出台初步方案，明确初步措施，预见初步成效</span>
                            </li>
                            <li class="item">
                                <span class="dot" />
                                <span class="detail">向所在省有关部门报备，接受所在省内主管部门的监管</span>
                            </li>
                        </ul>
                    </div>
                </sm-border>
                <sm-border class="join-union"
                           title="入盟方式">
                    <div class="content">
                        <pre>
    三明联盟平台根据统一平台、共享目录、属地管
理、统一标准、开放接口的思路进行设计与建设，目
前支持两种对接方式： 

一、直接入驻三明联盟平台，联盟成员只需要开通属
地管理后台系统即可开展联合采购业务。

二、支持其他平台与三明联盟平台对接，实现目录与
价格数据联动。
                        </pre>
                    </div>           
                </sm-border>
            </div>
            <sm-border slot="footer"
                       class="footer-part">
                <swiper v-if="show"
                        :options="resultSwiper"
                        class="areaList-wrapper">
                    <swiper-slide v-for="(row,i) in areaListFormatter"
                                  :key="'arealist-' + i"
                                  class="areaList">
                        <template v-for="(item, index) in row">
                            <div :key="index"
                                 class="pic-cell"
                                 :style="{backgroundImage: `url(${item})`}" />
                        </template>
                    </swiper-slide>
                </swiper>
            </sm-border>
        </swing-layout>
    </div>
</template>
<script>

import SwingLayout from '@/components/layout/swingLayout';
import bg from '@/images/bg.jpg';
import dataCell from '@/components/data-cell';
import smBorder from './components/sm-border';
import areaList from './components/areaList';
import solidBorder from './components/solid-border';

export default {
    components: {
        SwingLayout,
        smBorder,
        solidBorder,
        dataCell
    },
    data() {
        return {
            bg,
            areaList,
            resultSwiper: {
                loop: true,
                direction: 'horizontal',
                autoplay: true,
                slidesPerView: 9
            },
            show: false
        };
    },
    computed: {
        areaListFormatter() {
            const formatter = [];
            let temp = [];
            this.areaList.forEach((item, index) => {
                if (index !== 0 && index % 2 === 0) {
                    formatter.push(temp);
                    temp = [];
                }
                temp.push(item);
            });
            if (temp.length !== 0) {
                formatter.push(temp);
            }
            return formatter;
        }
    },
    activated() {
        this.show = true;
    },
    deactivated() {
        setTimeout(() => {
            this.show = false;
        }, 800);
    },
    mounted() {
    
    },
    methods: {

    }
};
</script>
