/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-24 13:54:54
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-12 14:19:22
 * @Description: 地区列表
 */
import smPic from '@/images/sm-pic/sm.png'; // 三明
import czPic from '@/images/sm-pic/cangzhou.png'; // 沧州
import chzPic from '@/images/sm-pic/changzhi.png'; // 长治
import eedzPic from '@/images/sm-pic/eerduisi.png'; // 鄂尔多斯
import pyPic from '@/images/sm-pic/puyang.png'; // 濮阳
import gyPic from '@/images/sm-pic/guiyang.png'; // 贵阳
import hdPic from '@/images/sm-pic/handan.png'; // 邯郸
import hsPic from '@/images/sm-pic/hengshui.png'; // 衡水
import shPic from '@/images/sm-pic/shihezi.png'; // 石河子
import tyPic from '@/images/sm-pic/taiyuan.png'; // 太原
import tsPic from '@/images/sm-pic/tangshan.png'; // 唐山
import trPic from '@/images/sm-pic/tongren.png'; // 铜仁
import whPic from '@/images/sm-pic/wuhai.png'; // 乌海
import xgPic from '@/images/sm-pic/xingan.png'; // 兴安盟
import xtPic from '@/images/sm-pic/xingtai.png'; // 邢台
import ycPic from '@/images/sm-pic/yuncheng.png'; // 运城
import yxPic from '@/images/sm-pic/yuxi.png'; // 玉溪
import zjkPic from '@/images/sm-pic/zhangjiakou.png'; // 张家口
import ningboPic from '@/images/sm-pic/ningbo.png'; // 宁波
import zhuhaiPic from '@/images/sm-pic/zhuhai.png'; // 珠海
import qingyangPic from '@/images/sm-pic/qingyang.png'; // 庆阳
import luohePic from '@/images/sm-pic/luohe.png'; // 漯河市
import xiangxiPic from '@/images/sm-pic/xiangxi.png'; // 湘西
import zhaotongPic from '@/images/sm-pic/zhaotong.png'; // 昭通
import jixiPic from '@/images/sm-pic/jixi.png'; // 昭通
import hhhtPic from '@/images/sm-pic/hhht.png'; // 昭通

export default [
    smPic,
    czPic,
    chzPic,
    eedzPic,
    pyPic,
    gyPic,
    hdPic,
    hsPic,
    shPic,
    tyPic,
    tsPic,
    trPic,
    whPic,
    xgPic,
    xtPic,
    ycPic,
    yxPic,
    zjkPic,
    ningboPic,
    zhuhaiPic,
    qingyangPic,
    luohePic,
    xiangxiPic,
    zhaotongPic,
    jixiPic,
    hhhtPic
];
