/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-16 16:19:35
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-12 10:57:15
 * @Description: 微前端入口
 */


import Info from './base';
import routes from '../router';

const VueLifecycles = Info({
    publicPath: 'dusp-led',
    routes
});

// 项目启动的钩子
export const bootstrap = [
    VueLifecycles.bootstrap
];
// 项目启动后的钩子
export const mount = [
    VueLifecycles.mount
];
// 项目卸载的钩子
export const unmount = [
    VueLifecycles.unmount
];
