var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "swing-layout",
        {
          attrs: {
            "container-style": {
              backgroundColor: "#03071E",
              backgroundImage: "url(" + _vm.bg + ")"
            },
            title: "全国药品和医用耗材招采管理系统"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "left-content",
              attrs: { slot: "left" },
              slot: "left"
            },
            [
              _c(
                "border",
                {
                  staticClass: "system-build",
                  attrs: { title: "平台建设目标" }
                },
                [
                  _c("ul", { staticClass: "system-list" }, [
                    _c("li", { staticClass: "list-item" }, [
                      _vm._v(
                        "\n                        为国家组织药械招采提供应用支持\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-item" }, [
                      _vm._v(
                        "\n                        对32个省级医保局药械招采实施统一监督管理\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-item" }, [
                      _vm._v(
                        "\n                        支撑国家医保局规范指导各地药械招采工作\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-item" }, [
                      _vm._v(
                        "\n                        协力搭建全国统一医保信息平台\n                    "
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "system-module",
                  attrs: { title: "系统功能模块", type: "img" }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 14 } },
                    _vm._l(_vm.systemModules, function(item, index) {
                      return _c(
                        "el-col",
                        { key: index, attrs: { span: item.span } },
                        [
                          _c("div", { staticClass: "item", class: item.type }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.label) +
                                "\n                        "
                            )
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "plat-plan",
                  attrs: { title: "全国统一平台规划" }
                },
                [
                  _c("ul", { staticClass: "plan-list" }, [
                    _c("div", { staticClass: "line" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "arrow" }),
                    _vm._v(" "),
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        2020年10月，完成国家医保信息平台项目建设\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "item" }, [
                      _c("span", { staticClass: "dot" }),
                      _vm._v(
                        "\n                        2021年，实现医保信息系统的全面联通\n                    "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "middle-content",
              attrs: { slot: "middle" },
              slot: "middle"
            },
            [
              _c(
                "div",
                { staticClass: "subTitle-list" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 25 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "subTitle" }, [
                          _vm._v(
                            "\n                            国家下发统一版本\n                        "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "subTitle" }, [
                          _vm._v(
                            "\n                            助推各省份落地\n                        "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("dv-flyline-chart-enhanced", {
                staticClass: "map-wrapper",
                attrs: { config: _vm.config }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "icon" })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "right-content",
              attrs: { slot: "right" },
              slot: "right"
            },
            [
              _c(
                "border",
                {
                  staticClass: "plat-hightLights",
                  attrs: { title: "局本级平台亮点" }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 8 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 8 }
                        },
                        [_c("box-shadow", [_vm._v("顶层设计")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 8 }
                        },
                        [_c("box-shadow", [_vm._v("基础平台")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 8 }
                        },
                        [_c("box-shadow", [_vm._v("统一标准")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 12 }
                        },
                        [_c("box-shadow", [_vm._v("数据汇集")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 12 }
                        },
                        [_c("box-shadow", [_vm._v("综合监管")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "border-arrow" }),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "plat-cascader",
                  attrs: { title: "两级平台联动" }
                },
                [
                  _vm._l(_vm.levelCascader, function(item, index) {
                    return _c("box-shadow-bottom", { key: index }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.label) +
                          "\n                "
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "dot" }, [
                    _vm._v("\n                    .......\n                ")
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "border-arrow" }),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "province-hightLights",
                  attrs: { title: "省本级平台亮点" }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 8 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 8 }
                        },
                        [
                          _c("box-shadow", { attrs: { green: "" } }, [
                            _vm._v(
                              "\n                            中台组装\n                        "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 8 }
                        },
                        [
                          _c("box-shadow", { attrs: { green: "" } }, [
                            _vm._v(
                              "\n                            按需使用\n                        "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 8 }
                        },
                        [
                          _c("box-shadow", { attrs: { green: "" } }, [
                            _vm._v(
                              "\n                            接口对接\n                        "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 12 }
                        },
                        [
                          _c("box-shadow", { attrs: { green: "" } }, [
                            _vm._v(
                              "\n                            遵循统一标准\n                        "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 12 }
                        },
                        [
                          _c("box-shadow", { attrs: { green: "" } }, [
                            _vm._v(
                              "\n                            个性化拓展\n                        "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer-content",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("border", { staticClass: "wrapper" }, [
                _c("div", {
                  staticClass: "timeline",
                  class: ["time" + _vm.calTime]
                })
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }