var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "swing-layout",
        {
          attrs: {
            "container-style": {
              backgroundColor: "#03071E",
              backgroundImage: "url(" + _vm.bg + ")",
              backgroundRepeat: "round"
            },
            "flex-left": 7,
            "flex-right": 7,
            "flex-center": 6,
            title: _vm.title
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "left-content",
              attrs: { slot: "left" },
              slot: "left"
            },
            [
              _c(
                "div",
                { staticClass: "left-part" },
                [
                  _c(
                    "border",
                    { staticClass: "hosp", attrs: { title: "医疗机构" } },
                    [
                      _c(
                        "div",
                        { staticClass: "wrapper" },
                        [
                          _c("div", { staticClass: "head" }, [
                            _c("div", { staticClass: "digital" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("toThousands")(_vm.hospList.num1)
                                  ) +
                                  "\n                                "
                              ),
                              _c("span", { staticClass: "unit" }, [
                                _vm._v("家")
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                "\n                                报量医院\n                            "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "list-header" }, [
                            _c("span", { staticClass: "cell" }, [
                              _vm._v("机构名称")
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "cell" }, [
                              _vm._v("采购金额(万元)")
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "head-fix" },
                            _vm._l(_vm.hospList.nums.slice(0, 10), function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "row" },
                                [
                                  _c("span", { staticClass: "dotIndex" }, [
                                    _vm._v(_vm._s(index + 1))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "dot-content" }, [
                                    _c("div", { staticClass: "list-cell" }, [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm._f("hospNameFormmater")(
                                              item.num1
                                            )
                                          ) +
                                          "\n                                    "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "list-cell" }, [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm._f("toThousands")(item.num2)
                                          ) +
                                          "\n                                    "
                                      )
                                    ])
                                  ])
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("dv-scroll-board", {
                            staticClass: "list",
                            attrs: { config: _vm.hospPurchaseList }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right-part" },
                [
                  _c(
                    "div",
                    { staticClass: "top-content" },
                    [
                      _c(
                        "border",
                        {
                          staticClass: "med-drink",
                          attrs: { title: "中药饮片(单位：kg)" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "wrapper" },
                            [
                              _c(
                                "div",
                                { staticClass: "head-fix" },
                                [
                                  _c("dv-scroll-board", {
                                    staticClass: "list",
                                    attrs: { config: _vm.mecDrinkDataFixList }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("dv-scroll-board", {
                                staticClass: "list",
                                attrs: { config: _vm.mecDrinkDataList }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "border",
                        {
                          staticClass: "med",
                          attrs: { title: "中成药(单位：片/粒/支/袋)" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "wrapper" },
                            [
                              _c(
                                "div",
                                { staticClass: "head-fix" },
                                [
                                  _c("dv-scroll-board", {
                                    staticClass: "list",
                                    attrs: { config: _vm.medDataFixListConfig }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("dv-scroll-board", {
                                staticClass: "list",
                                attrs: { config: _vm.medDataListConfig }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "border",
                    {
                      staticClass: "med-grain",
                      attrs: { title: "中药配方颗粒" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "wrapper" },
                        [
                          _c(
                            "div",
                            { staticClass: "head-fix" },
                            [
                              _c("dv-scroll-board", {
                                staticClass: "list",
                                attrs: { config: _vm.medGrainFixListConfig }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("dv-scroll-board", {
                            staticClass: "list",
                            attrs: { config: _vm.medGrainListConfig }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "middle-content",
              attrs: { slot: "middle" },
              slot: "middle"
            },
            [
              _c("div", { staticClass: "about" }, [
                _c("div", { staticClass: "total-amount-list" }, [
                  _c(
                    "div",
                    { staticClass: "el-col" },
                    [
                      _c("data-cell", { staticClass: "sevenDataAmount" }, [
                        _c(
                          "div",
                          {
                            staticClass: "about-val",
                            attrs: { slot: "val" },
                            slot: "val"
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("toThousands")(_vm.headData.num1)
                                ) +
                                "\n                            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "seven-label",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                                中药(材)报量总金额(万元)\n                            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "el-col" },
                    [
                      _c("data-cell", [
                        _c(
                          "div",
                          {
                            staticClass: "about-val",
                            attrs: { slot: "val" },
                            slot: "val"
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("toThousands")(_vm.headData.num2)
                                ) +
                                "\n                            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "seven-label",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                                中药饮片报量总金额(万元)\n                            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "el-col" },
                    [
                      _c("data-cell", [
                        _c(
                          "div",
                          {
                            staticClass: "about-val",
                            attrs: { slot: "val" },
                            slot: "val"
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("toThousands")(_vm.headData.num4)
                                ) +
                                "\n                            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "seven-label",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                                配方颗粒报量总金额(万元)\n                            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "el-col" },
                    [
                      _c("data-cell", [
                        _c(
                          "div",
                          {
                            staticClass: "about-val",
                            attrs: { slot: "val" },
                            slot: "val"
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("toThousands")(_vm.headData.num3)
                                ) +
                                "\n                            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "seven-label",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                                中成药报量总金额(万元)\n                            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "main" },
                [
                  _c("dv-flyline-chart-enhanced", {
                    staticClass: "mapWrapper",
                    attrs: { config: _vm.mapConfig, dev: true }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(_vm.amount, function(item) {
                      return _c(
                        "border",
                        { key: item.label, staticClass: "clear-border" },
                        [
                          _c("data-cell", { staticClass: "amount-item" }, [
                            _c(
                              "div",
                              {
                                staticClass: "stat-val",
                                attrs: { slot: "val" },
                                slot: "val"
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm._f("toThousands")(item.value))
                                ),
                                _c("span", { staticClass: "unit" }, [
                                  _vm._v(_vm._s(item.unit))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "stat-label",
                                attrs: { slot: "label" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.label) +
                                    "\n                            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "right-content",
              attrs: { slot: "right" },
              slot: "right"
            },
            [
              _c(
                "border",
                {
                  staticClass: "purchase-amount",
                  attrs: { title: "各省报量金额排名" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper" },
                    [
                      _c(
                        "div",
                        { staticClass: "head-fix" },
                        [
                          _c("dv-scroll-board", {
                            staticClass: "list",
                            attrs: { config: _vm.purAmountFixConfig }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("dv-scroll-board", {
                        staticClass: "list",
                        attrs: { config: _vm.purAmountConfig }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "border",
                {
                  staticClass: "purchase-amount-top",
                  attrs: { title: "山东各地市报量金额排名" }
                },
                [
                  _c("div", { staticClass: "wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "box" },
                      [
                        _c(
                          "div",
                          { staticClass: "head-fix" },
                          [
                            _c("dv-scroll-board", {
                              staticClass: "list",
                              attrs: { config: _vm.purAmountRankFixConfig1 }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("dv-scroll-board", {
                          staticClass: "list",
                          attrs: { config: _vm.purAmountRankConfig1 }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "box" }, [
                      _c(
                        "div",
                        { staticClass: "head-fix body-fix" },
                        [
                          _c("dv-scroll-board", {
                            staticClass: "list",
                            attrs: { config: _vm.purAmountRankConfig2 }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "box" }, [
                      _c(
                        "div",
                        { staticClass: "head-fix body-fix" },
                        [
                          _c("dv-scroll-board", {
                            staticClass: "list",
                            attrs: { config: _vm.purAmountRankConfig3 }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }