/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 17:24:20
 * @Last Modified by: Danielz
 * @Last Modified time: 2023-11-05 13:19:48
 * @Description: 路由集成页
 * @Route: Route
 */

import govView from '@/components/gov-view';
import enpView from '@/components/enp-view';
import labView from '@/components/lab-view'; // 重庆实验室 和 天津展厅的窗口
import tempView from '@/components/temp-view';

import DefaultLayout from '@/components/layout/default';
import govHome from '@/pages/government/home/index';
import smUnion from '@/pages/government/sm-union/index'; // 三明联盟
import busHome from '@/pages/bussiness/home/index';
// import digitalAssets from '@/pages/bussiness/digital-assets/index'; // 数据资产
// import digitalApply from '@/pages/bussiness/digital-apply/index'; // 数据资产

// 公共页面
import leaderAbout from '@/pages/common-page/leader-about/index'; // 领导关怀
import govHxProj from '@/pages/government/hx-proj/index'; // 海西项目
import enpHxProj from '@/pages/bussiness/hx-proj/index'; // 海西项目
import supProj from '@/pages/common-page/hx-sup-proj/index'; // 海西监管项目
import countryProj from '@/pages/common-page/country-proj/index'; // 国家局项目

// 重庆实验室版
import labCountryProj from '@/pages/lab/country-proj/index'; // 国家局项目
// 北方药械-天津大屏-宽屏版/
import southMec from '@/pages/bussiness/south-mec/home/index'; // 北方药械
// 报量大屏
import reportAmount from '@/pages/bussiness/report-amount/index';
import reportAmountTrade from '@/pages/bussiness/report-amount/trade';

// 报量大屏
import reportAmountjx from '@/pages/bussiness/report-amount-jx/index';
import reportAmountTradejx from '@/pages/bussiness/report-amount-jx/trade';

// 临时版
import tempHome from '@/pages/temporary/home/index';

const routes = [
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {
                path: 'gov',
                component: govView,
                redirect: 'gov/0',
                children: [
                    {
                        path: 'home',
                        alias: '0',
                        component: govHome,
                        meta: {
                            alive: true
                        }
                    },
                    {
                        path: 'country-proj',
                        component: countryProj,
                        alias: '1',
                        meta: {
                            title: '国家局项目',
                            alive: true
                        }
                    },
                    {
                        path: 'sm-union',
                        alias: '2',
                        component: smUnion,
                        meta: {
                            title: '三明联盟',
                            alive: true
                        }
                    },
                    {
                        path: 'hx-sup-proj',
                        alias: '3',
                        component: supProj,
                        meta: {
                            title: '综合监管模型',
                            alive: true
                        }
                    },
                    {
                        path: 'hx-proj',
                        alias: '4',
                        component: govHxProj,
                        meta: {
                            title: '服务项目展示',
                            alive: false
                        }
                    },
                    {
                        path: 'leader-about',
                        alias: '5',
                        component: leaderAbout,
                        meta: {
                            title: '领导关怀',
                            alive: true
                        }
                    }
                ]
            },
            {
                path: 'enp',
                redirect: 'enp/0',
                component: enpView,
                children: [
                    {
                        path: 'home',
                        alias: '0',
                        component: busHome,
                        meta: {
                            title: '首屏'
                        }
                    },
                    {
                        path: 'country-proj',
                        alias: '1',
                        component: countryProj,
                        meta: {
                            title: '国家局项目'
                        }
                    },
                    {
                        path: 'hx-sup-proj',
                        alias: '2',
                        component: supProj,
                        meta: {
                            title: '综合监管模型',
                            alive: true
                        }
                    },
                    {
                        path: 'hx-proj',
                        alias: '3',
                        component: enpHxProj,
                        meta: {
                            title: '服务项目展示',
                            alive: true
                        }
                    },
                    {
                        path: 'leader-about',
                        alias: '4',
                        component: leaderAbout,
                        meta: {
                            title: '领导关怀',
                            alive: true
                        }
                    }
                ]
            },
            {
                path: 'lab',
                component: labView,
                children: [
                    {
                        path: 'country-proj',
                        component: labCountryProj,
                        meta: {
                            title: '国家局项目'
                        }
                    }
                ]
            },
            {
                path: 'tj',
                component: labView,
                children: [
                    {
                        path: 'home',
                        component: govHome,
                        meta: {
                            title: '北方药械联合体指挥中心（含厦门医药交易中心）'
                        }
                    }
                ]
            },
            {
                path: 'south-mec',
                component: labView,
                children: [
                    {
                        path: 'home',
                        component: southMec,
                        meta: {
                            title: '北方药械联合体指挥中心（含厦门医药交易中心）'
                        }
                    }
                ]
            },
            {
                path: 'temp',
                component: tempView,
                children: [
                    {
                        path: 'home',
                        alias: '0',
                        component: tempHome,
                        meta: {
                            // title: '北方药械联合体指挥中心（含厦门医药交易中心）'
                        }
                    },
                    {
                        path: 'country-proj',
                        alias: '1',
                        component: countryProj,
                        meta: {
                            title: '国家局项目'
                        }
                    },
                    {
                        path: 'hx-sup-proj',
                        alias: '2',
                        component: supProj,
                        meta: {
                            title: '综合监管模型',
                            alive: true
                        }
                    },
                    {
                        path: 'hx-proj',
                        alias: '3',
                        component: enpHxProj,
                        meta: {
                            title: '服务项目展示',
                            alive: true
                        }
                    },
                    {
                        path: 'leader-about',
                        alias: '4',
                        component: leaderAbout,
                        meta: {
                            title: '领导关怀',
                            alive: true
                        }
                    }
                ]
            },
            {
                path: 'report-amount',
                component: labView,
                children: [
                    {
                        path: 'home',
                        component: reportAmount,
                        meta: {
                            title: '山东互联网中药（材）交易中心'
                        }
                    },
                    {
                        path: 'trade',
                        component: reportAmountTrade,
                        meta: {
                            title: '山东互联网中药（材）交易中心'
                        }
                    }
                ]
            },
            {
                path: 'report-amount-jx',
                component: labView,
                children: [
                    {
                        path: 'home',
                        component: reportAmountjx,
                        meta: {
                            title: '传统医药交易中心'
                        }
                    },
                    {
                        path: 'trade',
                        component: reportAmountTradejx,
                        meta: {
                            title: '传统医药交易中心'
                        }
                    }
                ]
            }
        ]
    }
];

export default routes;
