var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "swing-layout",
        {
          attrs: {
            "container-style": {
              backgroundColor: "#03071E",
              backgroundImage: "url(" + _vm.bg + ")",
              backgroundRepeat: "round"
            },
            "flex-left": 8,
            "flex-right": 7.69,
            "flex-center": 6,
            title: _vm.$route.meta.title || "厦门海西医药交易中心药械交易平台"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "left-content",
              attrs: { slot: "left" },
              slot: "left"
            },
            [
              _c(
                "div",
                { staticClass: "left-part" },
                [
                  _c(
                    "border",
                    {
                      staticClass: "data-analysis",
                      attrs: { title: "数据智能分析" }
                    },
                    [
                      _c(
                        "el-row",
                        _vm._l(_vm.dataAnalysis, function(item, index) {
                          return _c(
                            "el-col",
                            { key: index, attrs: { span: 6 } },
                            [
                              _c("data-cell", { attrs: { type: "img" } }, [
                                _c("div", {
                                  staticClass: "img",
                                  style: {
                                    backgroundImage: "url(" + item.image + ")"
                                  },
                                  attrs: { slot: "val" },
                                  slot: "val"
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(item.label) +
                                        "\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "border",
                    {
                      staticClass: "enp-sale",
                      attrs: {
                        title: "生产企业销售金额TOP20",
                        "sub-title": "单位：万元"
                      }
                    },
                    [
                      _c("dv-scroll-board", {
                        attrs: { config: _vm.prodEnpSale }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "border",
                    {
                      staticClass: "enp-sale",
                      attrs: {
                        title: "医疗机构采购金额TOP20",
                        "sub-title": "单位：万元"
                      }
                    },
                    [
                      _c("dv-scroll-board", {
                        attrs: { config: _vm.medEnpSale }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right-part" },
                [
                  _c(
                    "border",
                    {
                      staticClass: "plat-scale",
                      attrs: { title: "平台覆盖规模" }
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    22省的105市\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    覆盖地区\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    45476.9万\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    覆盖人口\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    12681家\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    生产企业\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    5293家\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    配送企业\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    11302家\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "plat-scale-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    医疗机构\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "border",
                    {
                      staticClass: "bussiness-module",
                      attrs: { title: "业务模块" }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        _vm._l(_vm.bussinessModule, function(item, index) {
                          return _c(
                            "el-col",
                            { key: index, attrs: { span: 6 } },
                            [
                              _c(
                                "data-cell",
                                {
                                  staticClass: "module",
                                  attrs: { type: "img" }
                                },
                                [
                                  _c("img", {
                                    staticClass: "module-img",
                                    attrs: { slot: "val", src: item.img },
                                    slot: "val"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "plat-scale-label",
                                      attrs: { slot: "label" },
                                      slot: "label"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.label) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "border",
                    {
                      staticClass: "bussiness-result",
                      attrs: { title: "业务成效" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "result-detail" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n                            平台助力各地医改，成效显著\n                        "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { staticClass: "content" },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("data-cell", { attrs: { reverse: "" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "val",
                                        attrs: { slot: "val" },
                                        slot: "val"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        20% ~ 40%\n                                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        平台节约采购成本\n                                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("data-cell", { attrs: { reverse: "" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "val",
                                        attrs: { slot: "val" },
                                        slot: "val"
                                      },
                                      [
                                        _c("span", { staticClass: "unit" }, [
                                          _vm._v("超")
                                        ]),
                                        _vm._v(" 270 "),
                                        _c("span", { staticClass: "unit" }, [
                                          _vm._v("亿")
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        累计节约采购成本\n                                    "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "border-dot left" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "border-dot right" })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "result-list-wrapper" },
                        [
                          _c(
                            "swiper",
                            {
                              staticClass: "result-list",
                              attrs: { options: _vm.resultSwiper }
                            },
                            _vm._l(_vm.serviceResult, function(item, index) {
                              return _c(
                                "swiper-slide",
                                { key: index, staticClass: "list-item" },
                                [
                                  _c("div", [
                                    _c("div", { staticClass: "item-title" }, [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(item.title) +
                                          "\n                                    "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "item-content",
                                      domProps: {
                                        innerHTML: _vm._s(item.content)
                                      }
                                    })
                                  ])
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "middle-content",
              class: [_vm.guest ? "guest" : ""],
              attrs: { slot: "middle" },
              slot: "middle"
            },
            [
              _c(
                "el-row",
                { staticClass: "about", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("data-cell", { staticClass: "sevenDataAmount" }, [
                        _c(
                          "div",
                          {
                            staticClass: "about-val",
                            attrs: { slot: "val" },
                            slot: "val"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.stat.orderAmtWeek) +
                                "\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "seven-label",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                            近7日订单金额 (亿元)\n                        "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "total-amount-list" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "about-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.stat.orderAmt) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "seven-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    累计订单额 (亿元)\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "about-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.stat.setlAmt) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "seven-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    累计结算额 (亿元)\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c("data-cell", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "about-val",
                                    attrs: { slot: "val" },
                                    slot: "val"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.stat.orderCnt) +
                                        "\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "seven-label",
                                    attrs: { slot: "label" },
                                    slot: "label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    累计订单数 (笔)\n                                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "right-content",
              attrs: { slot: "right" },
              slot: "right"
            },
            [
              _c(
                "div",
                { staticClass: "left-part" },
                [
                  _c(
                    "border",
                    { staticClass: "hot-sale", attrs: { title: "商品热销榜" } },
                    [_c("dv-scroll-board", { attrs: { config: _vm.hotSale } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "border",
                    {
                      staticClass: "order-amt",
                      attrs: {
                        title: "订单成交额",
                        "sub-title": "平台订单总成交额"
                      }
                    },
                    [
                      _c("div", { staticClass: "order-amt-wrapper" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("toThousands")(
                                  _vm._f("dataConvert")(
                                    _vm.orderAmtByProvinceTopTotal.totalAmt
                                  )
                                )
                              )
                          ),
                          _c("span", { staticClass: "unit" }, [_vm._v("万元")])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "content" },
                          [
                            _c("ve-histogram", {
                              attrs: {
                                data: _vm.chartData,
                                height: 280 / 16 + "rem",
                                extend: _vm.chartExtend,
                                settings: _vm.chartSettings
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right-part" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "border",
                            {
                              staticClass: "hot-sale rank",
                              attrs: { title: "商家销售榜" }
                            },
                            [
                              _c("dv-scroll-board", {
                                attrs: { config: _vm.storeSaleRank }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "border",
                            {
                              staticClass: "hot-sale rank",
                              attrs: { title: "消费省份排名" }
                            },
                            [
                              _c("dv-scroll-board", {
                                attrs: { config: _vm.hotProvince }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "border",
                    {
                      staticClass: "deal-report",
                      attrs: { title: "成交播报" }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "report-header",
                              attrs: { gutter: 15 }
                            },
                            [
                              _c("el-col", { attrs: { span: 11 } }, [
                                _vm._v(
                                  "\n                                成交订单\n                            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("span", { staticClass: "header-amt" }, [
                                  _vm._v("成交额（元）")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c("span", { staticClass: "header-time" }, [
                                  _vm._v("成交时间")
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "swiper",
                            {
                              staticClass: "report-list",
                              attrs: { options: _vm.reportSwiper }
                            },
                            _vm._l(_vm.screenOrderTopList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "swiper-slide",
                                { key: index, staticClass: "list-item" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticClass: "content",
                                          attrs: {
                                            type: "flex",
                                            align: "middle",
                                            gutter: 15
                                          }
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "prod-name",
                                              attrs: { span: 11 }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(item.orderInfo) +
                                                  "\n                                        "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "pur-amt",
                                              attrs: { span: 6 }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm._f("toThousands")(
                                                      Number(item.totalAmt)
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "pur-time",
                                              attrs: { span: 6 }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm._f("timeConvert")(
                                                      item.gmtCreated
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }