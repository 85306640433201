var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "swing-layout",
        {
          attrs: {
            "container-style": {
              backgroundColor: "#03071E",
              backgroundImage: "url(" + _vm.bg + ")",
              backgroundRepeat: "round"
            },
            "flex-left": 5.5,
            "flex-right": 5.5,
            "flex-center": 9,
            title: _vm.title
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "left-content",
              attrs: { slot: "left" },
              slot: "left"
            },
            [
              _c(
                "border",
                { staticClass: "buyer", attrs: { title: "采购单位 TOP30" } },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper" },
                    [
                      _c(
                        "div",
                        { staticClass: "head-fix" },
                        [
                          _c("dv-scroll-board", {
                            staticClass: "list",
                            attrs: { config: _vm.buyerDataFixList }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("dv-scroll-board", {
                        staticClass: "list bd",
                        attrs: { config: _vm.buyerDataList }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "border",
                { staticClass: "seller", attrs: { title: "供应企业 TOP30" } },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper" },
                    [
                      _c(
                        "div",
                        { staticClass: "head-fix" },
                        [
                          _c("dv-scroll-board", {
                            staticClass: "list",
                            attrs: { config: _vm.sellerDataFixList }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("dv-scroll-board", {
                        staticClass: "list bd",
                        attrs: { config: _vm.sellerDataList }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "middle-content",
              attrs: { slot: "middle" },
              slot: "middle"
            },
            [
              _c("div", { staticClass: "total-wrap" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("\n                    交易额(万元)\n                ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "total" },
                  [
                    _vm._l(_vm.totalMain, function(num, index) {
                      return [
                        num !== ","
                          ? _c("div", { key: index, staticClass: "item" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(num || "0") +
                                  "\n                        "
                              )
                            ])
                          : _c("div", { key: index, staticClass: "comma" }, [
                              _vm._v(
                                "\n                            ,\n                        "
                              )
                            ])
                      ]
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "sub-total" }, [
                  _c("div", { staticClass: "item bg1" }, [
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(parseInt(_vm.total.num2, 10) || 0) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        "\n                            总订单数(笔)\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item bg2" }, [
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(parseInt(_vm.total.num3, 10) || 0) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        "\n                            采购单位数(家)\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item bg3" }, [
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(parseInt(_vm.total.num4, 10) || 0) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        "\n                            供应企业数(家)\n                        "
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "border",
                { staticClass: "deal-wrap", attrs: { title: "交易播报" } },
                [
                  _c("dv-scroll-board", {
                    staticClass: "list",
                    attrs: { config: _vm.dealData }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "right-content",
              attrs: { slot: "right" },
              slot: "right"
            },
            [
              _c(
                "border",
                { staticClass: "order-amount", attrs: { title: "交易额排名" } },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper" },
                    [
                      _c(
                        "div",
                        { staticClass: "head-fix" },
                        [
                          _c("dv-scroll-board", {
                            staticClass: "list",
                            attrs: { config: _vm.orderAmountFixData }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("dv-scroll-board", {
                        staticClass: "list",
                        attrs: { config: _vm.orderAmountData }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }