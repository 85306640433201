/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-16 10:03:11
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-07-08 08:51:23
 * @Description: 政府版-首屏
 */

<style lang='less' scoped>
.container {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    box-sizing: content-box;
    .left-content {
        .plat-scale {
            .plat-scale-val {
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                margin-bottom: 3px;
            }
            /deep/ .el-col {
                border-right: 1px solid rgba(0, 121, 245, 0.65);
                &:last-child {
                    border-right: 0;
                }
            }
            /deep/ .el-row:first-child {
                margin-bottom: 13px;
            }
            /deep/ .el-row:last-child {
                margin-bottom: 11px;
            }
        }
        .bussiness-result {
            .result-detail {
                width: 100%;
                height: 112px;
                margin-bottom: 12px;
                // background-image: linear-gradient(180deg, rgba(0, 190, 253, 0) 0%, rgba(0, 137, 251, 0.15) 100%);
                background: rgba(0, 145, 255, 0.2);
                position: relative;
                .title {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 700;
                    color: @--color-white;
                    line-height: 19px;
                    padding: 14px 0 14px 0;
                    background-image: url('~images/arrowD.png');
                    background-repeat: no-repeat;
                    background-position: center bottom;
                    background-size: 12px;
                }
                .content {
                    margin-top: 6px;
                    .label {
                        font-size: 12px;
                        text-align: left;
                        color: @--color-white;
                        line-height: 16px;
                    }
                    .val {
                        font-size: 20px;
                        font-weight: 700;
                        text-align: center;
                        color: #f7b500;
                        line-height: 24px;
                        margin-top: 9px;
                        .unit {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
                .border-dot {
                    width: 9px;
                    height: 4px;
                    display: inline-block;
                    background: url("~images/border-dot.png") no-repeat;
                    background-size: 100%;
                    background-position: center;
                    &.left {
                        position: absolute;
                        bottom: 3px;
                        left: 10px;
                    }
                    &.right {
                        position: absolute;
                        bottom: 3px;
                        right: 10px;
                    }
                }
            }
            .result-list {
                color: @--color-white;
                text-align: left;
                height: 118px;
                width: 100%;
                .list-item {
                    // margin-bottom: 13px;
                    .item-title {
                        font-size: 14px;
                        line-height: 14px;
                        height: 16px;
                        font-weight: 700;
                        margin-bottom: 5px;
                        &::before {
                            content: '';
                            height: 16px;
                            width: 3px;
                            line-height: 16px;
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 7px;
                            background-color: #108ee9;
                        }
                    }
                    .item-content {
                        font-size: 12px;
                        line-height: 16px;
                        margin-left: 14px;
                    }
                }
            }
        }
        .bussiness-module {
            .el-col {
                margin-bottom: 15px;
                &:nth-child(3) {
                    margin-bottom: 0;
                }
                &:nth-child(4) {
                    margin-bottom: 0;
                }
            }
            .module-img {
                display: inline-block;
                width: 75px;
                height: 60px;
            }
        }
    }
    .middle-content {
        padding: 25px 48px 0 48px;
        height: 100%;
        background: url("https://bucket-font.oss-cn-beijing.aliyuncs.com/img/datascreen/zf_map.png") no-repeat center / 605px 495px;
        background-position: 50% 50%;
        position: relative;
        &.guest {
            background-position: 50% 62%;
        }
        .about {
            .about-val {
                font-weight: 700;
                font-size: 21px;
                color: #f4a607;
                line-height: 24px;
                margin-bottom: 8px;
            }
            .sevenDataAmount,
            .total-amount-list {
                border: 1px solid rgba(0, 145, 255, 0.5);
                box-shadow: 0 0 21px 0 #0b2468 inset;
                text-align: center;
                padding: 21px 0 17px 0;
            }
            .total-amount-list {
                /deep/ .el-col {
                    border-left: 1px solid rgba(0, 145, 255, 0.5);
                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }
        .detail {
            position: absolute;
            bottom: 80px;
            padding-left: 53px;
            left: 0;
            right: 0;
            color: @--color-white;
            & > .title {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 14px;
            }
            .total-bar {
                height: 50px;
            }
            .swiper-slide-active {
                .data-cell {
                    font-weight: 700;
                }
            }
            .data-cell {
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 12px;
                text-align: left;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding-right: 20px;
                .dot {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    background: url("~images/dot2.png") no-repeat center / 14px 14px;
                    margin-right: 7px;
                    vertical-align: middle;
                }
            }
        }
    }
    .right-content {
        .hx-hightLights {
            .hightLights-list {
                .item {
                    padding: 5px 11px;
                    margin-bottom: 4px;
                    font-size: 14px;
                    text-align: left;
                    color: @--color-white;
                    line-height: 19px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:nth-child(even) {
                        background: rgba(0, 145, 255, 0.2);
                    }
                    &:nth-child(odd) {
                        background: rgba(0, 145, 255, 0.4);
                    }
                    .dot {
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        margin-right: 4px;
                        background-image: url("~images/dot.svg");
                        background-position: center;
                        background-size: 10px;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
        .data-analysis {
            .img {
                width: 37px;
                height: 37px;
                background-size: 37px;
                margin-bottom: 8px;
                display: inline-block;
            }
        }
        .enp-sale {
            /deep/ .content {
                height: 127px;
                width: 290px;
            }
            /deep/ .ceil {
                &:last-child {
                    padding-right: 0;
                    // text-align: right;
                    width: 25% !important;
                }
                &:first-child {
                    padding-left: 0;
                    width: 75% !important;
                    // text-align: left;
                }
            }
        }
    }
}
</style>

<template>
    <div class="container">
        <swing-layout :container-style="{ backgroundColor: '#03071E', backgroundImage: `url(${bg})`, fontSize: '28px'}"
                      :title="$route.meta.title || '众药帮 -- 全国药械交易平台'">
            <div slot="left"
                 class="left-content">
                <border title="平台覆盖规模"
                        class="plat-scale">
                    <el-row>
                        <el-col :span="12">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    22省的105市
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    覆盖地区
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="12">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    45476.9万
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    覆盖人口
                                </div>
                            </data-cell>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    <!-- {{ orgNum.prodNum }}家 -->
                                    12681家
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    生产企业
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="8">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    <!-- {{ orgNum.delvNum }}家 -->
                                    5293家
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    配送企业
                                </div>
                            </data-cell>
                        </el-col>
                        <el-col :span="8">
                            <data-cell>
                                <div slot="val"
                                     class="plat-scale-val">
                                    <!-- {{ orgNum.insNum }}家 -->
                                    11302家
                                </div>
                                <div slot="label"
                                     class="plat-scale-label">
                                    医疗机构
                                </div>
                            </data-cell>
                        </el-col>
                    </el-row>
                </border>
                <border title="业务模块"
                        class="bussiness-module">
                    <el-row>
                        <el-col v-for="(item, index) in bussinessModule"
                                :key="index"
                                :span="12">
                            <data-cell type="img"
                                       class="module">
                                <img slot="val"
                                     class="module-img"
                                     :src="item.img">
                                <div slot="label"
                                     class="plat-scale-label">
                                    {{ item.label }}
                                </div>
                            </data-cell>
                        </el-col>
                    </el-row>
                </border>
                <border title="业务成效"
                        class="bussiness-result">
                    <div class="result-detail">
                        <div class="title">
                            平台助力各地医改，成效显著
                        </div>
                        <el-row class="content">
                            <el-col :span="12">
                                <data-cell reverse>
                                    <div slot="val"
                                         class="val">
                                        20% ~ 40%
                                    </div>
                                    <div slot="label">
                                        平台节约采购成本
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col :span="12">
                                <data-cell reverse>
                                    <div slot="val"
                                         class="val">
                                        <span class="unit">超</span> 270 <span class="unit">亿</span>
                                    </div>
                                    <div slot="label">
                                        累计节约采购成本
                                    </div>
                                </data-cell>
                            </el-col>
                        </el-row>
                        <span class="border-dot left" />
                        <span class="border-dot right" />
                    </div>
                    <div>
                        <swiper :options="resultSwiper"
                                class="result-list">
                            <swiper-slide v-for="(item, index) in serviceResult"
                                          :key="index"
                                          class="list-item">
                                <div>
                                    <div class="item-title">
                                        {{ item.title }}
                                    </div>
                                    <div class="item-content"
                                         v-html="item.content" />
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </border>
            </div>
            <div slot="middle"
                 class="middle-content"
                 :class="[guest ? 'guest' : '']">
                <el-row class="about"
                        :gutter="20">
                    <el-col
                        :span="6">
                        <data-cell class="sevenDataAmount">
                            <div slot="val"
                                 class="about-val">
                                {{ stat.orderAmtWeek }}
                            </div>
                            <div slot="label"
                                 class="seven-label">
                                近7日订单金额 (亿元)
                            </div>
                        </data-cell>
                    </el-col>
                    <el-col :span="18">
                        <el-row class="total-amount-list">
                            <el-col
                                :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="about-val">
                                        {{ stat.orderAmt }}
                                    </div>
                                    <div slot="label"
                                         class="seven-label">
                                        累计订单额 (亿元)
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col
                                :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="about-val">
                                        {{ stat.setlAmt }}
                                    </div>
                                    <div slot="label"
                                         class="seven-label">
                                        累计结算额 (亿元)
                                    </div>
                                </data-cell>
                            </el-col>
                            <el-col
                                :span="8">
                                <data-cell>
                                    <div slot="val"
                                         class="about-val">
                                        {{ stat.orderCnt }}
                                    </div>
                                    <div slot="label"
                                         class="seven-label">
                                        累计订单数 (笔)
                                    </div>
                                </data-cell>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <div v-if="!guest && statArea.length !==0"
                     class="detail">
                    <div class="title">
                        累计订单额
                    </div>
                    <swiper :options="resultSwiper"
                            class="total-bar">
                        <swiper-slide v-for="(statItem, index) in mapAmtFormatter"
                                      :key="'stat-' + index">
                            <el-row>
                                <el-col v-for="(item, i) in statItem"
                                        :key="'statItem-' + i"
                                        :span="24 / statItem.length">
                                    <div class="data-cell">
                                        <span class="label">
                                            <span class="dot" />{{ item.areaName }}：
                                        </span>
                                        <span class="value">{{ item.orderAmt }}亿元</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div slot="right"
                 class="right-content">
                <border title="平台亮点"
                        class="hx-hightLights">
                    <ul class="hightLights-list">
                        <li class="item">
                            <span class="dot" />
                            “三医联动”开路先锋
                        </li>
                        <li class="item">
                            <span class="dot" />
                            国家药械采购平台承建者
                        </li>
                        <li class="item">
                            <span class="dot" />
                            国家药品集采力行者
                        </li>
                        <li class="item">
                            <span class="dot" />
                            跨区域联采专业服务商
                        </li>
                    </ul>
                </border>
                <border title="数据智能分析"
                        class="data-analysis">
                    <el-row>
                        <el-col v-for="(item, index) in dataAnalysis"
                                :key="index"
                                :span="6">
                            <data-cell type="img">
                                <div slot="val"
                                     class="img"
                                     :style="{backgroundImage: `url(${item.image})`}" />
                                <div slot="label">
                                    {{ item.label }}
                                </div>
                            </data-cell>
                        </el-col>
                    </el-row>
                </border>
                <border title="生产企业销售金额TOP20"
                        sub-title="单位：万元"
                        class="enp-sale">
                    <dv-scroll-board :config="prodEnpSale" />
                </border>
                <border title="医疗机构采购金额TOP20"
                        sub-title="单位：万元"
                        class="enp-sale">
                    <dv-scroll-board :config="medEnpSale" />
                </border>
            </div>
        </swing-layout>
    </div>
</template>
<script>

import SwingLayout from '@/components/layout/swingLayout';
import border from '@/components/border';
import priceAnalysis from '@/images/price.svg';
import warningExp from '@/images/warning.svg';
import settleSup from '@/images/settle.svg';
import marketAnalysis from '@/images/market.png';
import numTop from '@/images/num_top.png';
import numDef from '@/images/num_def.png';
import bg from '@/images/bg.jpg';
import module1 from '@/images/gov-module/zf_icon01.png';
import module2 from '@/images/gov-module/zf_icon02.png';
import module3 from '@/images/gov-module/zf_icon03.png';
import module4 from '@/images/gov-module/zf_icon04.png';
import dataCell from './components/data-cell';

const numTopDom = index => (`<span class="dotIndex" style="background-image:url(${numTop});">${index}</span>`);
const numDefDom = index => (`<span class="dotIndex" style="background-image:url(${numDef});">${index}</span>`);


export default {
    components: {
        SwingLayout,
        border,
        dataCell
    },
    data() {
        return {
            bg,
            bussinessModule: [
                {
                    img: module1,
                    label: '药械集中采购'
                },
                {
                    img: module2,
                    label: '医保资金结余留用'
                },
                {
                    img: module3,
                    label: '结算支付管理'
                },
                {
                    img: module4,
                    label: '药械综合监管'
                }
            ],
            dataAnalysis: [
                {
                    image: priceAnalysis,
                    label: '价格监测'
                },
                {
                    image: warningExp,
                    label: '异常预警'
                },
                {
                    image: settleSup,
                    label: '结算监管'
                },
                {
                    image: marketAnalysis,
                    label: '市场分析'
                }
            ],
            resultSwiper: {
                loop: true,
                direction: 'vertical',
                autoplay: true,
                slidesPerView: 2
            },
            guest: '',
            insAmtTops: [], // 医疗机构采购金额TOP20
            orgNum: {
                delvNum: 3263,
                insNum: 10160,
                prodNum: 7708
            },
            prodAmtTops: [], // 生产企业销售金额TOP20
            stat: {
                orderAmt: '162.0846',
                orderAmtWeek: '12.8470',
                orderCnt: '7,053,892',
                setlAmt: '104.4917'
            }, // 总计
            statArea: [
            ], // 地图区域金额
            serviceResult: [
                {
                    title: '福建省',
                    content: '2017年上线至今，累计节约药品费用<span style="color: #f7b500;">超100亿元（不包括三明）</span>'
                },
                {
                    title: '河北省',
                    content: '2019年7月上线以来，累计节约药品费用<span style="color: #f7b500;">超53亿元</span>'
                },
                {
                    title: '三明市',
                    content: '2012-2020年，共相对节约药品耗材费用<span style="color: #f7b500;">约100亿元</span>'
                },
                {
                    title: '邯郸市',
                    content: '2019年7月上线以来，年节约药品费用<span style="color: #f7b500;">超3.7亿元</span>'
                },
                {
                    title: '铜仁市',
                    content: '2018年1月上线至2020年7月底，药价下降27.64%，经测算，节约药品费用<span style="color: #f7b500;">约7.17亿元</span>'
                },
                {
                    title: '兴安盟',
                    content: '2018年1月上线至2020年7月底，药价较2017年下降30%，经测算，节约药品费用<span style="color: #f7b500;">约6.55亿元</span>'
                },
                {
                    title: '第八师石河子市',
                    content: '2019年上线至2020年7月底，药价较2018年下降23.44%，预计年节约药品费用<span style="color: #f7b500;">1.19亿元</span>'
                },
                {
                    title: '三明联盟',
                    content: '2020年， 7个非一致性评价药品集中带量采购，预计年节约药品费用<span style="color: #f7b500;">超2.4亿元</span>'
                },
                {
                    title: '国家“4+7”集采',
                    content: '4+7执行以来，厦门一年节约<span style="color: #f7b500;">2.4亿元</span>，福建省一年节约<span style="color: #f7b500;">12.29亿元</span>，河北省一年节<span style="color: #f7b500;">约35.59亿元</span>'
                }
            ]
        };
    },
    computed: {
        prodEnpSale() {
            const data = [];
            this.prodAmtTops.forEach((item, index) => {
                const temp = [];
                if (item.orgName && item.orderAmt) {
                    if (index < 3) {
                        temp.push(numTopDom(index + 1) + item.orgName);
                    } else {
                        temp.push(numDefDom(index + 1) + item.orgName);
                    }
                    temp.push(item.orderAmt);
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 4,
                columnWidth: [180, 95],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        medEnpSale() {
            const data = [];
            this.insAmtTops.forEach((item, index) => {
                const temp = [];
                if (item.orgName && item.orderAmt) {
                    if (index < 3) {
                        temp.push(numTopDom(index + 1) + item.orgName);
                    } else {
                        temp.push(numDefDom(index + 1) + item.orgName);
                    }
                    temp.push(item.orderAmt);
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 4,
                columnWidth: [180, 95],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        mapAmtFormatter() {
            const data = [];
            let temp = [];
            this.statArea.forEach((item, index) => {
                temp.push(item);
                if ((index + 1) % 3 === 0) {
                    data.push(temp);
                    temp = [];
                }
            });
            if (temp.length !== 0) {
                data.push(temp);
            }
            return data;
        }
    },
    created() {
        this.guest = true;
    },
    mounted() {
        this.fetchData();
    },
    activated() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$fetch({
                url: '/dap/goodsAmtStat/getStatInfo'
            }).then(res => {
                if (res.code === 0) {
                    const { insAmtTops, orgNum, prodAmtTops, stat, statArea } = res.data;
                    this.insAmtTops = insAmtTops;
                    this.orgNum = orgNum;
                    this.prodAmtTops = prodAmtTops;
                    this.stat = stat;
                    this.statArea = statArea;
                }
            });
        }
    }
};
</script>
