import { render, staticRenderFns } from "./gov-view.vue?vue&type=template&id=925e42d8&"
import script from "./gov-view.vue?vue&type=script&lang=js&"
export * from "./gov-view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/daniel/work/jiangxi/fed-sd-visual-web-static/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('925e42d8')) {
      api.createRecord('925e42d8', component.options)
    } else {
      api.reload('925e42d8', component.options)
    }
    module.hot.accept("./gov-view.vue?vue&type=template&id=925e42d8&", function () {
      api.rerender('925e42d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/gov-view.vue"
export default component.exports