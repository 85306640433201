var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "digital-carousel" }, [
    _c(
      "div",
      { ref: "carousel", staticClass: "carousel-wrapper" },
      _vm._l(_vm.carouselItem, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            ref: "carousel-item",
            refInFor: true,
            staticClass: "carousel-item",
            class: {
              "is-active": item.isActive
            }
          },
          [
            _c("div", { staticClass: "left-top border-div" }),
            _vm._v(" "),
            _c("div", { staticClass: "left-bottom border-div" }),
            _vm._v(" "),
            _c("div", { staticClass: "right-top border-div" }),
            _vm._v(" "),
            _c("div", { staticClass: "right-bottom border-div" }),
            _vm._v(" "),
            _c("div", { staticClass: "bottom-line border-div" }),
            _vm._v(" "),
            _c("img", { staticClass: "imgWrapper", attrs: { src: item.img } })
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "carousel-point" },
      _vm._l(_vm.carouselItem, function(item, index) {
        return _c("li", {
          key: index,
          staticClass: "point-item",
          class: [item.isActive && "is-active"],
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.setAcitveIndex(index)
            }
          }
        })
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tips" }, [
      _vm._v("\n        【数据仅供示意】\n    ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }