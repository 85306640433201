/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-16 10:03:11
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-01-06 09:39:47
 * @Description: 数据报量大屏-首屏
 */

<style lang='less' scoped>
.container {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    box-sizing: content-box;
    font-weight: 300;
    /deep/.dv-scroll-board {
        .rows {
            .row-item {
                font-size: 12px;
            }
        }
    }
    /deep/ .swing-container {
        .top-center {
            padding: 0 110px;
        }
    }
    .left-content {
        display: flex;
        height: 100%;
        /deep/ .ceil {
            &:last-child {
                padding-right: 0;
            }
            &:first-child {
                padding-left: 0;
            }
        }
        .right-part {
            flex: 2 1 0;
            .top-content {
                display: flex;
                flex-direction: row;
            }
            .med {
                .list-header {
                    .cell:nth-child(2) {
                        position: relative;
                        right: -2em;
                    }
                }
                .list {
                    /deep/ .header {
                        .header-item {
                            &:nth-child(2) {
                                padding-right: 13px;
                            }
                            &:nth-child(3) {
                                padding-left: 0;
                            }
                        }
                    }
                    /deep/ .rows {
                        .row-item {
                            .ceil {
                                &:nth-child(2) {
                                    padding-right: 13px;
                                }
                            }
                        }
                    }
                }
            }
            .med-drink {
                margin-right: 16px;
                .list {
                    /deep/ .header {
                        .header-item {
                            &:nth-child(2) {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
            .med-drink,
            .med {
                /deep/ .subTitle {
                    color: #fff;
                }
                .list {
                    height: 160px;
                    /deep/ .ceil {
                        &:last-child {
                            text-align: right;
                        }
                        &:first-child {
                            padding-left: 0;
                            padding-right: 8px;
                        }
                        &:nth-child(2) {
                            padding-right: 12px;
                            padding-left: 0;
                        }
                    }
                    /deep/ .header-item {
                        font-size: 12px;
                        color: #108ee9;
                        &:first-child {
                            padding-left: 26px;
                        }
                        &:nth-child(2) {
                            padding-right: 12px;
                        }
                        &:last-child {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }
                }
            }
            .med-grain {
                width: 100%;
                margin-top: 10px;
                .list {
                    height: 86px;
                }
                .head-fix {
                    .list {
                        height: 116px;
                        /deep/.header {
                            margin-bottom: 6px;
                        }
                        /deep/ .header-item {
                            font-size: 12px;
                            color: #108ee9;
                            &:first-child {
                                padding-left: 26px;
                            }
                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .left-part {
            flex: 1 1 0;
            padding-right: 17px;
            .hosp {
                .wrapper {
                    .head-fix {
                        margin-bottom: 3px;
                        padding-bottom: 0;
                    }
                    .list {
                        height: 218px;
                    }
                    .head {
                        margin-bottom: 18px;
                        height: 66px;
                        text-align: center;
                        background-image: linear-gradient(179deg, rgba(77, 212, 255, 0) 0%, rgba(16, 142, 233, 0.25) 100%);
                        .digital {
                            font-size: 20px;
                            color: #f7b500;
                            text-align: center;
                            line-height: 22px;
                            font-weight: bold;
                            .unit {
                                font-size: 12px;
                                color: #f4a607;
                                letter-spacing: 0;
                            }
                        }
                        .label {
                            font-size: 12px;
                            color: #4dd4ff;
                            text-align: center;
                            line-height: 34px;
                        }
                    }
                }
                /deep/ .ceil {
                    &:last-child {
                        padding-right: 0;
                        width: 25% !important;
                    }
                    &:first-child {
                        padding-left: 0;
                        width: 75% !important;
                    }
                }
            }
        }
    }
    .middle-content {
        padding: 25px 40px 0 40px;
        height: 100%;
        position: relative;
        .mapWrapper {
            width: 620px;
            height: 476px;
            margin-top: 44px;
            display: inline-block;
            position: relative;
            left: -40px;
        }
        .about {
            .about-val {
                font-weight: 700;
                font-size: 21px;
                color: #f4a607;
                line-height: 24px;
                margin-bottom: 8px;
            }
            .hosp-count {
                margin-left: 15px;
            }
            .total-amount-list {
                border: 1px solid rgba(0, 145, 255, 0.5);
                box-shadow: 0 0 21px 0 #0b2468 inset;
                text-align: center;
                /deep/ .el-col {
                    // border-left: 1px solid rgba(0, 145, 255, 0.5);
                    padding: 21px 0 17px 0;
                    width:23%;
                    display: inline-block;
                    box-sizing: border-box;
                    position: relative;
                    &:first-child {
                        border-left: 0;
                        background-color: rgba(0, 145, 255, 0.15);
                        &::after {
                            display: none;
                        }
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    &::after {
                        content: " ";
                        height: 55px;
                        width: 0;
                        border-left: 1px solid rgba(0, 145, 255, 0.5);
                        position: absolute;
                        right: 0;
                        top: 20px;
                    }
                }
            }
        }
        .main {
            position: relative;
            .list {
                display: inline-flex;
                flex-direction: column;
                position: absolute;
                right: -40px;
                top: 130px;
                .clear-border {
                    // width: 120px;
                    background-color: rgba(16, 142, 233, 0.1);
                }
                .amount-item {
                    display: inline-block;
                    position: relative;
                    top: -5px;
                    .stat-val {
                        font-weight: Bold;
                        font-size: 20px;
                        color: #f4a607;
                        letter-spacing: 0;
                    }
                    .unit {
                        font-size: 12px;
                        color: #f4a607;
                        letter-spacing: 0;
                    }
                    .stat-label {
                        font-weight: Bold;
                        font-size: 14px;
                        color: #d8d8d8;
                        letter-spacing: 0;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .right-content {
        display: flex;
        flex-direction: column;
        .wrapper {
            .box {
                width: 330px;
                vertical-align: top;
                + .box {
                    margin-left: 16px;
                }
            }
        }
        .purchase-amount-top {
            margin-top: 15px;
            .wrapper {
                display: flex;
            }
        }
        .purchase-amount,
        .purchase-amount-top {
            .list {
                height: 120px;
                /deep/ .dotIndex {
                    vertical-align: middle;
                }
                /deep/ .row-item {
                    border-bottom: 1px solid rgba(16, 142, 233, 0.25);
                }
            }
            .head-fix {
                margin-bottom: 0;
                .list {
                    height: 150px;
                    /deep/ .header {
                        margin-bottom: 10px;
                    }
                    /deep/ .header-item {
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.85);
                        line-height: 40px;
                    }
                }
            }
            .body-fix {
                padding-bottom: 0;
                border-bottom: none;
                .list {
                    height: 270px;
                }
            }
        }
    }
    // /deep/ .row-item {
    //     .ceil {
    //         text-overflow: clip;
    //     }
    // }
}
.list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 26px;
    .cell {
        font-size: 12px;
        color: #108ee9;
        letter-spacing: 0;
        text-align: right;
    }
}
.head-fix {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid rgba(16, 142, 233, 0.25);
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        .dotIndex {
            background-image: url("~images/report-amount/num_top.png");
            background-repeat: no-repeat;
            background-position: center;
            color: #fff;
            display: inline-block;
        }
        .dot-content {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
            .list-cell {
                font-size: 12px;
                color: #fff;
                letter-spacing: 0;
                line-height: 24px;
            }
        }
    }
}
.dateStr {
    font-size: 12px;
    color: #108ee9;
}
</style>

<template>
    <div class="container">
        <swing-layout :container-style="{ backgroundColor: '#03071E', backgroundImage: `url(${bg})`,backgroundRepeat: 'round' }"
                      :flex-left="7"
                      :flex-right="7"
                      :flex-center="6"
                      :title="title">
            <div slot="left"
                 class="left-content">
                <!-- <div class="dateStr">
                    <span class="val">{{ year }}</span>年
                    <span class="val">{{ month }}</span>月
                    <span class="val">{{ date }}</span>日
                </div> -->
                <div class="left-part">
                    <border title="医疗机构"
                            class="hosp">
                        <div class="wrapper">
                            <div class="head">
                                <div class="digital">
                                    {{ hospList.num1 | toThousands }}
                                    <span class="unit">家</span>
                                </div>
                                <div class="label">
                                    报量医院
                                </div>
                            </div>
                            <div class="list-header">
                                <span class="cell">机构名称</span>
                                <span class="cell">采购金额(万元)</span>
                            </div>
                            <div class="head-fix">
                                <div v-for="(item,index) in hospList.nums.slice(0, 10)"
                                     :key="index"
                                     class="row">
                                    <span class="dotIndex">{{ index + 1 }}</span>
                                    <div class="dot-content">
                                        <div class="list-cell">
                                            {{ item.num1 | hospNameFormmater }}
                                        </div>
                                        <div class="list-cell">
                                            {{ item.num2 |toThousands }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <dv-scroll-board :config="hospPurchaseList"
                                             class="list" />
                        </div>
                    </border>
                </div>
                <div class="right-part">
                    <div class="top-content">
                        <border title="中药饮片(单位：kg)"
                                class="med-drink">
                            <div class="wrapper">
                                <div class="head-fix">
                                    <dv-scroll-board :config="mecDrinkDataFixList"
                                                     class="list" />
                                </div>
                                <dv-scroll-board :config="mecDrinkDataList"
                                                 class="list" />
                            </div>
                        </border>
                        <border title="中成药(单位：片/粒/支/袋)"
                                class="med">
                            <div class="wrapper">
                                <div class="head-fix">
                                    <dv-scroll-board :config="medDataFixListConfig"
                                                     class="list" />
                                </div>
                                <dv-scroll-board :config="medDataListConfig"
                                                 class="list" />
                            </div>
                        </border>
                    </div>
                    <border title="中药配方颗粒"
                            class="med-grain">
                        <div class="wrapper">
                            <div class="head-fix">
                                <dv-scroll-board class="list"
                                                 :config="medGrainFixListConfig" />
                            </div>
                            <dv-scroll-board class="list"
                                             :config="medGrainListConfig" />
                        </div>
                    </border>
                </div>
            </div>
            <div slot="middle"
                 class="middle-content">
                <div class="about">
                    <div class="total-amount-list">
                        <div class="el-col">
                            <data-cell class="sevenDataAmount">
                                <div slot="val"
                                     class="about-val">
                                    {{ headData.num1 | toThousands }}
                                </div>
                                <div slot="label"
                                     class="seven-label">
                                    中药(材)报量总金额(万元)
                                </div>
                            </data-cell>
                        </div>
                        <div class="el-col">
                            <data-cell>
                                <div slot="val"
                                     class="about-val">
                                    {{ headData.num2 | toThousands }}
                                </div>
                                <div slot="label"
                                     class="seven-label">
                                    中药饮片报量总金额(万元)
                                </div>
                            </data-cell>
                        </div>
                        <div class="el-col">
                            <data-cell>
                                <div slot="val"
                                     class="about-val">
                                    {{ headData.num4 | toThousands }}
                                </div>
                                <div slot="label"
                                     class="seven-label">
                                    配方颗粒报量总金额(万元)
                                </div>
                            </data-cell>
                        </div>
                        <div class="el-col">
                            <data-cell>
                                <div slot="val"
                                     class="about-val">
                                    {{ headData.num3 | toThousands }}
                                </div>
                                <div slot="label"
                                     class="seven-label">
                                    中成药报量总金额(万元)
                                </div>
                            </data-cell>
                        </div>
                    </div>
                </div>
                <div class="main">
                    <dv-flyline-chart-enhanced
                        :config="mapConfig"
                        :dev="true"
                        class="mapWrapper" />
                    <div class="list">
                        <border
                            v-for="item in amount"
                            :key="item.label"
                            class="clear-border">
                            <data-cell class="amount-item">
                                <div slot="val"
                                     class="stat-val">
                                    {{ item.value | toThousands
                                    }}<span class="unit">{{ item.unit }}</span>
                                </div>
                                <div slot="label"
                                     class="stat-label">
                                    {{ item.label }}
                                </div>
                            </data-cell>
                        </border>
                    </div>
                </div>
            </div>
            <div slot="right"
                 class="right-content">
                <border title="各省报量金额排名"
                        class="purchase-amount">
                    <div class="wrapper">
                        <div class="head-fix">
                            <dv-scroll-board :config="purAmountFixConfig"
                                             class="list" />
                        </div>
                        <dv-scroll-board :config="purAmountConfig"
                                         class="list" />
                    </div>
                </border>
                <border title="山东各地市报量金额排名"
                        class="purchase-amount-top">
                    <div class="wrapper">
                        <div class="box">
                            <div class="head-fix">
                                <dv-scroll-board :config="purAmountRankFixConfig1"
                                                 class="list" />
                            </div>
                            <dv-scroll-board :config="purAmountRankConfig1"
                                             class="list" />
                        </div>
                        <div class="box">
                            <!-- <div class="head-fix">
                                <dv-scroll-board :config="purAmountRankFixConfig2"
                                                class="list" />
                            </div> -->
                            <div class="head-fix body-fix">
                                <dv-scroll-board :config="purAmountRankConfig2"
                                                 class="list" />
                            </div>
                        </div>
                        <div class="box">
                            <!-- <div class="head-fix">
                                <dv-scroll-board :config="purAmountRankFixConfig"
                                                class="list" />
                            </div> -->
                            <div class="head-fix body-fix">
                                <dv-scroll-board :config="purAmountRankConfig3"
                                                 class="list" />
                            </div>
                        </div>
                    </div>
                </border>
            </div>
        </swing-layout>
    </div>
</template>
<script>

import SwingLayout from '@/components/layout/swingLayout';
import border from '@/components/border';
import numTop from '@/images/report-amount/num_top.png';
import numDef from '@/images/report-amount/num_def.png';
import bg from '@/images/report-amount/bg.jpg';
import map from '@/images/report-amount/map.png';
import { toThousands } from '@/utils/utils';
import dataCell from './components/data-cell';

export default {
    components: {
        SwingLayout,
        border,
        dataCell
    },
    filters: {
        toThousands(val) {
            if (!val) return '--';
            return toThousands(val);
        },
        hospNameFormmater(name) {
            if (name.length > 16) {
                name = `${name.slice(0, 15)}...`;
            }
            return name;
        },
        dataConvert(convert) {
            if (typeof convert === 'string') {
                convert = parseFloat(convert);
            }
            if (convert >= 10000) {
                convert /= 10000;
                convert = `${convert.toFixed(2)}`;
            } else {
                convert *= 0.0001;
            }
            if (convert) {
                return convert;
            }
            return '--';
        },
        timeConvert(val) {
            if (!val) {
                return '--';
            }
            const current = new Date(val);
            return `${current.getFullYear()}.${current.getMonth() + 1}.${current.getDate()}`;
        }
    },
    data() {
        return {
            year: '',
            month: '',
            date: '',
            title: '',
            bg,
            mapConfig: {
                // centerPoint: [0.48, 0.35],
                points: [
                    {
                        name: '新疆',
                        coordinate: [0.27, 0.23]
                    },
                    {
                        name: '贵州',
                        coordinate: [0.63, 0.63]
                    },
                    {
                        name: '湖北',
                        coordinate: [0.75, 0.46]
                    },
                    {
                        name: '湖南',
                        coordinate: [0.70, 0.59]
                    },
                    {
                        name: '珠海',
                        coordinate: [0.77, 0.70]
                    },
                    {
                        name: '江西',
                        coordinate: [0.79, 0.61]
                    },
                    {
                        name: '浙江',
                        coordinate: [0.88, 0.53]
                    },
                    {
                        name: '江苏',
                        coordinate: [0.86, 0.45]
                    },
                    {
                        name: '天津',
                        coordinate: [0.79, 0.33]
                    },
                    {
                        name: '河北',
                        coordinate: [0.75, 0.31]
                    },
                    {
                        name: '内蒙古',
                        coordinate: [0.63, 0.29]
                    },
                    {
                        name: '青海',
                        coordinate: [0.44, 0.41]
                    },
                    {
                        name: '宁夏',
                        coordinate: [0.61, 0.38]
                    },
                    {
                        name: '甘肃',
                        coordinate: [0.58, 0.47]
                    },
                    {
                        name: '云南',
                        coordinate: [0.53, 0.68]
                    },
                    {
                        name: '山西',
                        coordinate: [0.71, 0.38]
                    },
                    {
                        name: '黑龙江',
                        coordinate: [0.91, 0.13]
                    },
                    // {
                    //     name: '吉林',
                    //     coordinate: [0.93, 0.23]
                    // },
                    // {
                    //     name: '辽宁',
                    //     coordinate: [0.89, 0.26]
                    // },
                    {
                        name: '山东',
                        coordinate: [0.82, 0.38]
                    },
                    {
                        name: '福建',
                        coordinate: [0.87, 0.63]
                    }
                    // 0.85, 0.64
                ],
                lines: [
                    {
                        source: '福建',
                        target: '山东'
                    },
                    {
                        source: '新疆',
                        target: '山东'
                    },
                    {
                        source: '贵州',
                        target: '山东'
                    },
                    {
                        source: '湖南',
                        target: '山东'
                    },
                    {
                        source: '湖北',
                        target: '山东'
                    },
                    {
                        source: '江西',
                        target: '山东'
                    },
                    {
                        source: '珠海',
                        target: '山东'
                    },
                    {
                        source: '浙江',
                        target: '山东'
                    },
                    {
                        source: '江苏',
                        target: '山东'
                    },
                    {
                        source: '河北',
                        target: '山东'
                    },
                    // {
                    //     source: '辽宁',
                    //     target: '山东'
                    // },
                    // {
                    //     source: '吉林',
                    //     target: '山东'
                    // },
                    {
                        source: '黑龙江',
                        target: '山东'
                    },
                    {
                        source: '天津',
                        target: '山东'
                    },
                    {
                        source: '山西',
                        target: '山东'
                    },
                    {
                        source: '云南',
                        target: '山东'
                    },
                    {
                        source: '甘肃',
                        target: '山东'
                    },
                    {
                        source: '宁夏',
                        target: '山东'
                    },
                    {
                        source: '青海',
                        target: '山东'
                    },
                    {
                        source: '内蒙古',
                        target: '山东'
                    }
                ],
                line: {
                    width: 2,
                    color: '#04EFEF'
                },
                bgImgSrc: map
            },
            dateStr: null,
            timeStr: null,
            timer: null,
            amount: [
                {
                    label: '报量省份',
                    value: '12',
                    unit: '个'
                },
                {
                    label: '报量城市',
                    value: '88,888',
                    unit: '个'
                },
                {
                    label: '报量医院',
                    value: '88,888',
                    unit: '家'
                }
            ],
            headData: {},
            mapData: {},
            medData: {},
            medGenData: [],
            hospList: {
                num1: '',
                nums: []
            },
            mecDrinkList: [],
            medGrainList: [],
            purAmountLst: [],
            purAmountRank: []
        };
    },
    computed: {
        medGrainFixListConfig() {
            const data = [];
            this.medGrainList.slice(0, 3).forEach((item, index) => {
                const temp = [];
                if (item.num1 && item.num2 && item.num3) {
                    let name = item.num1;
                    if (item.num1.length > 18) {
                        name = `${name.slice(0, 17)}...`;
                    }
                    temp.push(this.numTopDom(index + 1, name));
                    temp.push(item.num2);
                    temp.push(item.num3);
                    // temp.push(item.num4);
                    temp.push(toThousands(item.num5));
                    data.push(temp);
                }
            });
            return {
                header: ['机构名称', '省份', '城市', '采购金额(万元)'],
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                headerBGC: 'rgba(0,0,0,0)',
                headerHeight: 28,
                rowNum: 3,
                columnWidth: [400, 180, 120, 190],
                align: ['left', 'left', 'left', 'right'],
                waitTime: 2000,
                data
            };
        },
        medGrainListConfig() {
            const data = [];
            this.medGrainList.slice(3).forEach((item, index) => {
                const temp = [];
                if (item.num1 && item.num2 && item.num3) {
                    let name = item.num1;
                    if (item.num1.length > 18) {
                        name = `${name.slice(0, 17)}...`;
                    }
                    temp.push(this.numDefDom(index + 4, name));
                    temp.push(item.num2);
                    temp.push(item.num3);
                    // temp.push(item.num4);
                    temp.push(toThousands(item.num5));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 3,
                columnWidth: [400, 180, 120, 190],
                align: ['left', 'left', 'left', 'right'],
                waitTime: 2000,
                data
            };
        },
        hospPurchaseList() {
            const data = [];
            this.hospList.nums.slice(10).forEach((item, index) => {
                const temp = [];
                if (item.num1 && item.num2) {
                    let name = item.num1;
                    if (item.num1.length > 16) {
                        name = `${name.slice(0, 15)}...`;
                    }
                    temp.push(this.numDefDom(index + 11, name));
                    temp.push(toThousands(item.num2));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 6,
                columnWidth: [130, 95],
                align: ['left', 'right'],
                waitTime: 2000,
                data
            };
        },
        medDataFixListConfig() {
            const data = [];
            if (!Array.isArray(this.medData)) {
                return {};
            }
            this.medData.slice(0, 5).forEach((item, index) => {
                const temp = [];
                if (item.num1 && item.num2 && item.num3) {
                    let name = item.num1;
                    if (item.num1.length > 6) {
                        name = `${name.slice(0, 6)}...`;
                    }
                    temp.push(this.numTopDom(index + 1, name));
                    temp.push(toThousands(parseInt(item.num2, 10)));
                    temp.push(toThousands(item.num3));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 5,
                waitTime: 2000,
                header: ['品名', '需求量', '采购金额(万元)'],
                align: ['left', 'right', 'right'],
                columnWidth: [157, 155, 115],
                headerBGC: 'rgba(0,0,0,0)',
                data
            };
        },
        medDataListConfig() {
            const data = [];
            if (!Array.isArray(this.medData)) {
                return {};
            }
            this.medData.slice(5).forEach((item, index) => {
                const temp = [];
                if (item.num1 && item.num2 && item.num3) {
                    let name = item.num1;
                    if (item.num1.length > 6) {
                        name = `${name.slice(0, 5)}...`;
                    }
                    temp.push(this.numDefDom(index + 6, name));
                    temp.push(toThousands(parseInt(item.num2, 10)));
                    temp.push(toThousands(item.num3));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 5,
                align: ['left', 'right', 'right'],
                columnWidth: [157, 155, 115],
                waitTime: 2000,
                data
            };
        },
        mecDrinkDataFixList() {
            const data = [];
            this.mecDrinkList.slice(0, 5).forEach((item, index) => {
                const temp = [];
                if (item.num1 && item.num2 && item.num3) {
                    temp.push(this.numTopDom(index + 1, item.num1));
                    temp.push(toThousands(item.num2));
                    temp.push(toThousands(item.num3));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 5,
                columnWidth: [165, 155, 115],
                align: ['left', 'center', 'right'],
                waitTime: 2000,
                data,
                header: ['饮片名称', '需求量', '采购金额(万元)'],
                headerBGC: 'rgba(0,0,0,0)'
                // headerHeight: 40
            };
        },
        mecDrinkDataList() {
            const data = [];
            this.mecDrinkList.slice(5).forEach((item, index) => {
                const temp = [];
                if (item.num1 && item.num2 && item.num3) {
                    temp.push(this.numDefDom(index + 6, item.num1));
                    temp.push(toThousands(item.num2));
                    temp.push(toThousands(item.num3));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 5,
                columnWidth: [165, 155, 115],
                align: ['left', 'center', 'right'],
                waitTime: 2000,
                data
            };
        },
        purAmountFixConfig() {
            const data = [];
            this.purAmountLst.slice(0, 3).forEach((item, index) => {
                const temp = [];
                if (item) {
                    temp.push(this.numTopDom(index + 1));
                    temp.push(item.num1);
                    temp.push(toThousands(item.num2));
                    temp.push(toThousands(item.num3));
                    temp.push(toThousands(item.num4));
                    temp.push(toThousands(item.num5));
                    temp.push(toThousands(item.num6));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 3,
                data,
                align: ['center', 'left', 'right', 'right', 'right', 'right', 'right'],
                columnWidth: ['80', '205', '160', '260', '260', '260', '220'],
                header: ['序号', '省份', '参与医院数量', '中药饮片报量金额(万元)', '中成药报量金额(万元)', '配方颗粒报量金额(万元)', '报量总金额(万元)'],
                headerBGC: 'rgba(16,142,233,0.15)',
                headerHeight: 40
            };
        },
        purAmountConfig() {
            const data = [];
            this.purAmountLst.slice(3).forEach((item, index) => {
                const temp = [];
                if (item) {
                    temp.push(this.numDefDom(index + 4));
                    temp.push(item.num1);
                    temp.push(toThousands(item.num2));
                    temp.push(toThousands(item.num3));
                    temp.push(toThousands(item.num4));
                    temp.push(toThousands(item.num5));
                    temp.push(toThousands(item.num6));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 3,
                columnWidth: ['80', '205', '160', '260', '260', '260', '220'],
                align: ['center', 'left', 'right', 'right', 'right', 'right', 'right'],
                data
            };
        },
        purAmountRankFixConfig1() {
            const data = [];
            this.purAmountRank.slice(0, 3).forEach((item, index) => {
                const temp = [];
                if (item) {
                    temp.push(this.numTopDom(index + 1));
                    temp.push(item.num1);
                    temp.push(toThousands(item.num7));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 3,
                data,
                align: ['center', 'left', 'right'],
                columnWidth: ['80', '205', '220'],
                header: ['序号', '城市', '报量金额(万元)'],
                headerBGC: 'rgba(16,142,233,0.15)',
                headerHeight: 40
            };
        },
        purAmountRankConfig1() {
            const data = [];
            this.purAmountRank.slice(3, 6).forEach((item, index) => {
                const temp = [];
                if (item) {
                    temp.push(this.numDefDom(index + 4));
                    temp.push(item.num1);
                    temp.push(toThousands(item.num7));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 3,
                columnWidth: ['80', '205', '220'],
                align: ['center', 'left', 'right'],
                data
            };
        },
        purAmountRankFixConfig2() {
            const data = [];
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 0,
                data,
                align: ['center', 'left', 'right'],
                columnWidth: ['80', '205', '220'],
                header: ['序号', '城市', '报量金额(万元)'],
                headerBGC: 'rgba(16,142,233,0.15)',
                headerHeight: 40
            };
        },
        purAmountRankConfig2() {
            const data = [];
            this.purAmountRank.slice(6, 12).forEach((item, index) => {
                const temp = [];
                if (item) {
                    temp.push(this.numDefDom(index + 7));
                    temp.push(item.num1);
                    temp.push(toThousands(item.num7));

                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 6,
                data,
                align: ['center', 'left', 'right'],
                columnWidth: ['80', '205', '220'],
                header: ['序号', '城市', '报量金额(万元)'],
                headerBGC: 'rgba(16,142,233,0.15)',
                headerHeight: 40
            };
        },
        purAmountRankConfig3() {
            const data = [];
            this.purAmountRank.slice(12).forEach((item, index) => {
                const temp = [];
                if (item) {
                    temp.push(this.numDefDom(index + 13));
                    temp.push(item.num1);
                    temp.push(toThousands(item.num7));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 6,
                data,
                align: ['center', 'left', 'right'],
                columnWidth: ['80', '205', '220'],
                header: ['序号', '城市', '报量金额(万元)'],
                headerBGC: 'rgba(16,142,233,0.15)',
                headerHeight: 40
            };
        },
        purAmountRankConfig() {
            const data = [];
            this.purAmountRank.slice(3).forEach((item, index) => {
                const temp = [];
                if (item) {
                    temp.push(this.numDefDom(index + 4));
                    temp.push(item.num1);
                    temp.push(toThousands(item.num3));
                    temp.push(toThousands(item.num4));
                    temp.push(toThousands(item.num5));
                    temp.push(toThousands(item.num6));
                    temp.push(toThousands(item.num7));
                    data.push(temp);
                }
            });
            return {
                oddRowBGC: 'translate',
                evenRowBGC: 'translate',
                rowNum: 3,
                columnWidth: ['80', '205', '220'],
                align: ['center', 'left', 'right'],
                data
            };
        }
    },
    created() {
        this.setRem();
        this.startTimer();
        window.onresize = this.setRem;
        // 这个大屏是32: 9的屏幕。所以宽度用两倍
        document.documentElement.style.width = '100%';
    },
    mounted() {
        this.fetchTitle();
        this.fetchHospList();
        this.fetchHeadData();
        this.fetchMapData();
        this.fetchMedData();
        this.fetchMedDrinkData();
        this.fetchMedFormulaData();
        this.fetchPurAmountLst();
        this.fetchPurAmountRank();
    },
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
    methods: {
        startTimer() {
            this.timer = setInterval(() => {
                const oDate = new Date();
                this.year = oDate.getFullYear();
                this.month = oDate.getMonth() + 1;
                this.date = oDate.getDate();
                // this.dateStr = `${oDate.getFullYear()}-${
                //     oDate.getMonth() + 1
                // }-${oDate.getDate()}`;
                this.timeStr = `${oDate.getHours()}:${oDate.getMinutes()}:${oDate.getSeconds()}`;
            }, 1000);
        },
        async fetchTitle() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/getTopName'
            });
            if (response.code === 0) {
                this.title = response.msg;
            }
        },
        async fetchHospList() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenIII'
            });
            if (response.code === 0) {
                this.hospList = response.data;
            }
        },
        async fetchHeadData() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenI'
            });
            if (response.code === 0) {
                this.headData = response.data;
            }
        },
        async fetchMapData() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenII'
            });
            if (response.code === 0) {
                this.amount[0].value = response.data.num1;
                this.amount[1].value = response.data.num2;
                this.amount[2].value = response.data.num3;
                // this.mapData = response.data;
            }
        },
        // 中成药
        async fetchMedData() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenV'
            });
            if (response.code === 0) {
                this.medData = response.data;
            }
        },
        // 中药饮片
        async fetchMedDrinkData() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenIV'
            });
            if (response.code === 0) {
                this.mecDrinkList = response.data;
            }
        },
        async fetchMedFormulaData() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenVI'
            });
            if (response.code === 0) {
                this.medGrainList = response.data;
            }
        },
        async fetchPurAmountLst() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenVII'
            });
            if (response.code === 0) {
                this.purAmountLst = response.data;
            }
        },
        async fetchPurAmountRank() {
            const response = await this.$fetch({
                url: '/hxupsc/rpup/screen/screenVIII'
            });
            if (response.code === 0) {
                this.purAmountRank = response.data;
            }
        },
        numTopDom(index, content = '') {
            return (
                `<span class="dotIndex" style="background-image:url(${numTop});">${index}</span><span class="dot-content">${content}</span>`
            );
        },
        numDefDom(index, content = '') {
            return (
                `<span class="dotIndex" style="background-image:url(${numDef});">${index}</span><span class="dot-content">${content}</span>`
            );
        },
        setRem() {
            const { clientHeight } = document.documentElement;
            document.documentElement.style.fontSize = `${(16 * clientHeight / 756)}px`;
        }
    }
};
</script>
