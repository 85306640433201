export default {
    // 接口请求域名，只有生产环境需要配置
    baseUrl: 'https://api.bqex.com',
    // baseUrl: '//yapi.med.guahao-inc.com/mock/562',
    bridgeUrl: '//bridge.med.guahao-test.com',
    // 统一文件上传地址，不区分环境
    // uploadPath: '/api/comp/file/upload',
    // 统一文件查询地址，不区分环境
    // filePath: '/api/comp/file/query',
    wedataOrigin: 'http://192.168.94.22:9000',
    loginPath: 'http://standtest.udplat.com/'
    // vcodeHost:'http://hsa-spp-component-service-qa1.guahao-test.com',
    // vcodePath: '/api/comp/vcode/image/gen'
};
