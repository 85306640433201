/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-18 10:30:34
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-14 19:31:12
 * @Description: 有阴影的边框
 */ 
<style lang='less' scoped>
.box-shadow {
    background-image: url("~images/box-shadow.png");
    background-size: 87px 22px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 6px 10px 4px 0;
    text-align: center;
    width: 77px;
    height: 22px;
    font-size: 12px;
    color: @--color-white;
    line-height: 20px;
    display: inline-block;
    &.green {
        background-image: url("~images/box-shadow-g.png");
    }
}
</style>

<template>
    <div class="box-shadow"
         :class="[green && 'green']">
        <slot />
    </div>
</template>
<script>
export default {
    props: {
        green: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        };
    }
};
</script>
