var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-container", style: _vm.containerStyle },
    [
      _c("div", { staticClass: "swing-container" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "wrapper" }, [
            _c("div", { staticClass: "top-left" }),
            _vm._v(" "),
            _c("div", { staticClass: "top-center title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.title) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "top-right" })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "left", style: { flex: _vm.flexLeft } },
            [_vm._t("left")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "middle", style: { flex: _vm.flexCenter } },
            [
              _c("div", { staticClass: "arrowD" }),
              _vm._v(" "),
              _vm._t("middle")
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right", style: { flex: _vm.flexRight } },
            [_vm._t("right")],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swing-footer" }, [_vm._t("footer")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }