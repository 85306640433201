/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-10-16 14:32:56
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-20 16:49:31
 * @Description: view组件的公共js
 */
import { throttle } from '@/utils/utils';

export default {
    data() {
        return {
            transName: 'slideUp',
            currentPage: 0,
            throttleListener: null,
            totalPage: 6,
            basepath: 'gov'
        };
    },
    computed: {
    
    },
    created() {
        // 判断总页面
        const { routes } = this.$router.options;
        if (Array.isArray(routes) && routes.length !== 0) {
            const currentRoute = routes[0].children.find(item => item.path === this.basepath);
            this.totalPage = currentRoute.children.length;
        }
    },
    mounted() {
        // 添加防抖函数
        const time = 1100;
        this.throttleListener = throttle(this.eventListener, time);
        document.addEventListener('keyup', this.throttleListener);
    },
    destroyed() {
        document.removeEventListener('keyup', this.throttleListener);
    },
    methods: {
        eventListener(e) {
            if (e.keyCode === 40) {
                this.swipedown();
            } else if (e.keyCode === 38) {
                this.swipeup();
            }
        },
        swipeup() {
            if (this.currentPage > 0) {
                this.currentPage -= 1;
            } else {
                this.currentPage = this.totalPage - 1;
            }
            this.transName = 'slideDown';
            const currentPage = this.currentPage % this.totalPage;
            this.$router.push(`/${this.basepath}/${currentPage}`);
        },
        swipedown() {
            this.currentPage += 1;
            const currentPage = this.currentPage % this.totalPage;
            this.transName = 'slideUp';
            this.$router.push(`/${this.basepath}/${currentPage}`);
        }
    }
};
