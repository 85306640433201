/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-25 10:07:34
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-09-29 17:31:24
 * @Description: Description
 */

<style lang='less' scoped>
.full-screen {
    width: 100%;
    height: 100%;
    background-image: url("~images/bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 0 10px 0 10px;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    overflow: hidden;
    .container {
        width: 100%;
        height: 100%;
        background-image: url("~images/borderBg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .header {
            text-align: center;
            height: 66px;
            width: 200%;
            display: inline-block;
            .wrapper {
                display: inline-block;
                height: 99%;
            }
            .arrowD {
                height: 10px;
                background-image: url("~images/arrowD.png");
                background-repeat: no-repeat;
                background-size: 12px;
                background-position: top;
            }
            .title {
                font-size: 28px;
                font-weight: 700;
                text-align: center;
                display: inline-block;
                color: @--color-white;
                line-height: 37px;
                text-shadow: 0 2px 45px 0 #0091ff;
            }
            .top-left,
            .top-right {
                width: 466px;
                height: 100%;
                float: left;
            }
            .top-left,
            .top-right,
            .top-center {
                background-size: 100% 90%;
                background-position: top center;
                background-repeat: no-repeat;
                border: 0;
            }
            .top-left {
                background-image: url('~images/swing-left.png');
            }
            .top-right {
                background-image: url('~images/swing-right.png');
            }
            .top-center {
                margin: 0 -2px;
                float: left;
                text-align: center;
                background-image: url('~images/swing-middle.png');
                height: 100%;
                line-height: 66px;
                min-width: 362px;
                vertical-align: top;
            }
        }
    }
}
</style>

<template>
    <div class="full-screen">
        <div class="container">
            <div class="header">
                <div class="wrapper">
                    <div class="top-left" />
                    <div class="top-center title">
                        {{ title }}
                    </div>
                    <div class="top-right" />
                </div>
                <div class="arrowD" />
            </div>
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '国家局项目'
        }
    }
};
</script>
