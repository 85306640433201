/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-25 14:03:24
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-05-12 13:47:09
 * @Description: 服务项目展示
 * @Route: 
 */
<style lang='less' scoped>
.wrapper {
    padding-top: 30px;
    width: 100%;
}
</style>

<template>
    <full-layout title="服务项目展示">
        <div class="wrapper">
            <digital-carousel v-model="activeIndex"
                              :carousel-item="carouselItem" 
                              :interval="4000"
                              :auto-play="autoPlay" />
        </div>
    </full-layout>
</template>

<script>
import fullLayout from '@/components/layout/fullLayout';
import digitalCarousel from '@/components/digital-carousel/index';
import proj1 from '@/images/gov-hx-proj/xm01.jpg';
import proj2 from '@/images/gov-hx-proj/xm02.png';
import proj3 from '@/images/gov-hx-proj/xm03.png';
import proj4 from '@/images/gov-hx-proj/xm04.png';
import proj5 from '@/images/gov-hx-proj/xm05.png';
import proj6 from '@/images/gov-hx-proj/xm06.jpg';
import activeMixins from '@/components/common/activeMixins';

export default {
    components: {
        fullLayout,
        digitalCarousel
    },
    mixins: [activeMixins],
    data() {
        return {
            carouselItem: [
                {
                    img: proj1,
                    isActive: true
                },
                {
                    img: proj2,
                    isActive: false
                },
                {
                    img: proj3,
                    isActive: false
                },
                {
                    img: proj4,
                    isActive: false
                },
                {
                    img: proj5,
                    isActive: false
                },
                {
                    img: proj6,
                    isActive: false
                }
            ]
        };
    }
};
</script>
