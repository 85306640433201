/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2021-07-08 15:05:16
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-07-08 15:07:10
 * @Description: Description
 * @Route: Route
 */
<template>
    <v-touch class="view-container"
             @swipeup="swipeup"
             @swipedown="swipedown">
        <transition :name="transName">
            <keep-alive v-if="$route.meta.alive">
                <router-view />
            </keep-alive>
            <router-view v-else />
        </transition>
    </v-touch>
</template>

<script>
import Mixin from './common/index';

export default {
    mixins: [Mixin],
    props: {
        basepath: {
            type: String,
            default: 'gov'
        },
        totalPage: {
            type: Number,
            default: 5
        }
    }
};
</script>
