/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2021-07-08 15:07:41
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-07-08 15:09:28
 * @Description: 临时版
 */
<template>
    <common-view basepath="temp" />
</template>

<script>
import CommonView from './view';

export default {
    components: {
        CommonView
    }
};
</script>
