var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("full-layout", { attrs: { title: "领导关怀" } }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "first-row" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "border",
                    [
                      _c("data-cell", { attrs: { type: "img" } }, [
                        _c("div", {
                          staticClass: "img",
                          attrs: { slot: "val" },
                          slot: "val"
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                                2016年10月，时任全国政协副主席、九三学社中央主席韩启德主持召开由九三学社中央办公厅主办的“医保管理体制改革”座谈会。我司作为唯一受邀企业参会并汇报进展，运营模式和服务价值得到主席高度肯定。\n                            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "border",
                    [
                      _c("data-cell", { attrs: { type: "img" } }, [
                        _c("div", {
                          staticClass: "img",
                          attrs: { slot: "val" },
                          slot: "val"
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                                2017年8月，时任国务院副总理刘延东赴闽考察医改工作，观看了我司承建的“福建省药械联合限价阳光采购平台”、“三明联盟药械联合限价采购平台”的系统演示，对平台和医改成效予以肯定。\n                            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "border",
                    [
                      _c("data-cell", { attrs: { type: "img" } }, [
                        _c("div", {
                          staticClass: "img",
                          attrs: { slot: "val" },
                          slot: "val"
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                                2018年6月，国家医疗保障局局长胡静林选择三明作为首访地，深入调研后表示，福建省和三明市的改革实践有力促进了医疗、医保、医药“三医联动”，为深化医药卫生体制改革、为各地推进医保工作提供了有益借鉴。\n                            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "border",
                    [
                      _c("data-cell", { attrs: { type: "img" } }, [
                        _c("div", {
                          staticClass: "img",
                          attrs: { slot: "val" },
                          slot: "val"
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            attrs: { slot: "label" },
                            slot: "label"
                          },
                          [
                            _vm._v(
                              "\n                                2018年10月和2019年8月，中共中央政治局委员、国务院副总理孙春兰两度赴福建调研，期间观看了医药平台演示。她充分肯定三明医改的示范作用和福建医改的显著成效，强调要深入总结推广三明医改经验。 \n                            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "second-row" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("border", [
                    _c("div", { staticClass: "detailWrapper" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(
                          "\n                                2019年11月15日，国务院医改领导小组印发国医改发〔2019〕2号文，明确充分发挥福建省和三明市医改经验的示范、突破、带动作用。\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "img" })
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("border", [
                    _c("div", { staticClass: "detailWrapper" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(
                          "\n                                2019年11月29日，国务院医改领导小组印发国医改发〔2019〕3号文，要求建设全国统一开放的药品公共采购市场，统一编码、标准和功能规范。\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "img" })
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("border", [
                    _c("div", { staticClass: "detailWrapper" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(
                          "\n                                2020年3月5日，中共中央国务院正式发布《关于深化医疗保障制度改革的意见》，强调要提高“三医联动”改革的协同性，全面实行药品和医用耗材集中采购。\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "img" })
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }