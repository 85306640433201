/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-17 15:35:09
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-10-20 14:01:17
 * @Description: 空路由
 */
<template>
    <v-touch class="view-container"
             @swipeup="swipeup"
             @swipedown="swipedown">
        <transition :name="transName">
            <keep-alive v-if="$route.meta.alive">
                <router-view />
            </keep-alive>
            <router-view v-else />
        </transition>
    </v-touch>
</template>

<script>
import Mixin from './common/index';

export default {
    mixins: [Mixin],
    data() {
        return {
            basepath: 'gov', // 政府端根路径
            totalPage: 5 // 政府端的总页面
        };
    }
};
</script>
