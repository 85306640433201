/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-24 10:16:07
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-09-24 17:20:42
 * @Description: Description
 * @Route: Route
 */
<style lang='less' scoped>
.border-header {
    color: @--color-white;
    text-align: center;
    .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        color: #108ee9;
        margin-bottom: 10px;
        &.green {
            color: #43d2d2;
        }
    }
    .arrow {
        background-image: url("~images/sm-arrow.svg");
        height: 12px;
        background-position: center;
        background-repeat: no-repeat;
        &.green {
            background-image: url("~images/sm-arrow-green.svg");
        }
    }
}
</style>

<template>
    <div class="border-header">
        <div
            class="title"
            :class="green && 'green'">
            {{ title }}
        </div>
        <div class="arrow"
             :class="green && 'green'" />
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        green: {
            type: Boolean,
            default: false
        }
    }
};
</script>
