/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-09-15 14:52:22
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2021-02-26 14:16:02
 * @Description: 全屏容器
 * @Route: Route
 */
<template>
    <div :class="{ 'led-container': publicFlag, 'we-data': weFlag, 'led-spc': spcFlag }">
        <router-view />
    </div>
</template>

<script>
import { getUrlParame } from '@/utils/utils';

export default {
    data() {
        return {
            spcFlag: false,
            weFlag: false,
            publicFlag: true
        };
    },
    computed: {

    },
    watch: {

    },
    created() {
        const flag = getUrlParame('spc');
        if (this.$route.fullPath.indexOf('/wedata') !== -1) {
            this.spcFlag = false;
            this.weFlag = true;
            this.publicFlag = false;
            // return false;
        }
        this.spcFlag = flag === '1';
    },
    mounted() {

    },
    methods: {
 
    }
};
</script>

<style lang="less" scoped>
.led-container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.led-spc {
        width: 1587.6px;
        height: 756px;
    }
}
.we-data {
    height: 100%;
    width: 100%;
    background-color: #f3f5f6;
    overflow-y: auto;
}
</style>
